import React from 'react';
import { withRouter, Redirect as DomRedirect } from 'react-router-dom';

/* Incoming props:
REQUIRED:
  to   = an absolute url path, minus the {lang}, minus the leading slash.
         For example: "login" will redirect to "/{lang}/login", and "" will redirect to "/{lang}"
*/

const Redirect = withRouter( ({ match, to }) => {
  const { lang } = match.params;
  if ( typeof to !== "string" ) { return null };
  to = to.replace( new RegExp("^/+"), "" );  // rid leading slashes
  if ( to ) {
    to = "/" + to;  // add leading slash to all to urls except if to is empty
  }
  return (
    <DomRedirect
      to={{
        pathname: `/${ lang }${ to }`
      }}
    />
  );
});

export default Redirect;
