import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import classNames from 'classnames'
import './OrderSummary.scss'

const SUBSCRIPTION_TYPES = {
	MONTHLY: { term: 'monthly', cost: '5.99' },
	ANNUALLY: { term: 'annually', cost: '29.99' },
	UNKNOWN: { term: 'unknown', cost: undefined },
}

const getSubscription = (frequencyInterval, frequencyUnit) => {
	if (frequencyInterval === '1' && frequencyUnit === 'year') {
		return SUBSCRIPTION_TYPES.ANNUALLY
	} else if (frequencyInterval === '12' && frequencyUnit === 'month') {
		return SUBSCRIPTION_TYPES.ANNUALLY
	} else if (frequencyInterval === '1' && frequencyUnit === 'month') {
		return SUBSCRIPTION_TYPES.MONTHLY
	}
	return SUBSCRIPTION_TYPES.UNKNOWN
}

const getMmfProductName = (term) => {
	switch (term) {
		case SUBSCRIPTION_TYPES.ANNUALLY:
			return (
				<FormattedMessage
					id="checkout.orderSummary.mvp.annual"
					defaultMessage="MapMyFitness MVP (Annual)"
				/>
			)
		case SUBSCRIPTION_TYPES.MONTHLY:
			return (
				<FormattedMessage
					id="checkout.orderSummary.mvp.monthly"
					defaultMessage="MapMyFitness MVP (Monthly)"
				/>
			)
		default:
			return 'MapMyFitness MVP'
	}
}

const getMfpProductName = (term) => {
	switch (term) {
		case SUBSCRIPTION_TYPES.ANNUALLY:
			return (
				<FormattedMessage
					id="checkout.orderSummary.premium.annual"
					defaultMessage="MyFitnessPal Premium (Annual)"
				/>
			)
		case SUBSCRIPTION_TYPES.MONTHLY:
			return (
				<FormattedMessage
					id="checkout.orderSummary.premium.monthly"
					defaultMessage="MyFitnessPal Premium (Monthly)"
				/>
			)
		default:
			return 'MyFitnessPal Premium'
	}
}

const frequencyUnitPluralized = (unit, interval = '1') => {
	if (interval === '1') {
		switch (unit) {
			case 'year':
				return (
					<FormattedMessage
						id="checkout.orderSummary.premium.frequencyUnit.year"
						defaultMessage="year"
					/>
				)
			case 'month':
				return (
					<FormattedMessage
						id="checkout.orderSummary.premium.frequencyUnit.month"
						defaultMessage="month"
					/>
				)
			case 'week':
				return (
					<FormattedMessage
						id="checkout.orderSummary.premium.frequencyUnit.week"
						defaultMessage="week"
					/>
				)
			case 'day':
				return (
					<FormattedMessage
						id="checkout.orderSummary.premium.frequencyUnit.day"
						defaultMessage="day"
					/>
				)
			default:
				return null
		}
	} else {
		switch (unit) {
			case 'year':
				return (
					<FormattedMessage
						id="checkout.orderSummary.premium.frequencyUnit.years"
						defaultMessage="years"
					/>
				)
			case 'month':
				return (
					<FormattedMessage
						id="checkout.orderSummary.premium.frequencyUnit.months"
						defaultMessage="months"
					/>
				)
			case 'week':
				return (
					<FormattedMessage
						id="checkout.orderSummary.premium.frequencyUnit.weeks"
						defaultMessage="weeks"
					/>
				)
			case 'day':
				return (
					<FormattedMessage
						id="checkout.orderSummary.premium.frequencyUnit.days"
						defaultMessage="days"
					/>
				)
			default:
				return null
		}
	}
}

const LineItem = ({ itemName, amount, isTotal, highlightColor }) => {
	const lineItemClassNames = classNames('LineItem', {
		'LineItem--total': isTotal,
	})
	return (
		<div className={lineItemClassNames} style={{ color: highlightColor }}>
			<div className="LineItem-itemName">{itemName}</div>
			<div className="LineItem-amount">{amount}</div>
		</div>
	)
}

const PromoCodeCopy = ({ term, values }) => {
	switch (term) {
		case SUBSCRIPTION_TYPES.ANNUALLY:
			return (
				<FormattedMessage
					id="checkout.orderSummary.terms.freeTrial.annual"
					defaultMessage="You will not be charged during your {promoFrequencyInterval} {promoFrequencyUnit} free trial. Your subscription will renew at {productFormattedAmount} per year on {nextPaymentDate}. You may cancel anytime."
					values={values}
				/>
			)
		case SUBSCRIPTION_TYPES.MONTHLY:
			return (
				<FormattedMessage
					id="checkout.orderSummary.terms.freeTrial.monthly"
					defaultMessage="You will not be charged during your {promoFrequencyInterval} {promoFrequencyUnit} free trial. Your subscription will renew at {productFormattedAmount} per month on {nextPaymentDate}. You may cancel anytime."
					values={values}
				/>
			)
		default:
			return (
				<FormattedMessage
					id="checkout.orderSummary.terms.freeTrial"
					defaultMessage="You will not be charged during your {promoDuration} free trial. Your subscription will renew at {productFormattedAmount} on {nextPaymentDate}. You may cancel anytime."
					values={values}
				/>
			)
	}
}

const SubscriptionDetails = ({
	term,
	promoCode,
	promoFrequencyInterval,
	promoFrequencyUnit,
	productFormattedAmount,
	nextPaymentDate,
}) => {
	const copy = promoCode ? (
		<PromoCodeCopy
			term={term}
			values={{
				promoFrequencyInterval,
				promoFrequencyUnit: frequencyUnitPluralized(promoFrequencyUnit),
				productFormattedAmount,
				nextPaymentDate,
				promoDuration: null, // For backwards compatibility with the default PromoCodeCopy localized string
			}}
		/>
	) : (
		<FormattedMessage
			id="checkout.orderSummary.terms.purchase"
			defaultMessage="Your next payment of {productFormattedAmount} is scheduled for {nextPaymentDate}. You may cancel anytime."
			values={{ productFormattedAmount, nextPaymentDate }}
		/>
	)
	return <div className="SubscriptionDetails">{copy}</div>
}

export const OrderSummary = (props) => {
	const {
		locale,
		currency,
		productFrequencyInterval,
		productFrequencyUnit,
		serviceTotalAmount,
		promoCode,
		promoFrequencyInterval,
		promoFrequencyUnit,
		nextPaymentDate,
		match: {
			params: { domain },
		},
	} = props

	const currencyFormatter = new Intl.NumberFormat(locale, {
		style: 'currency',
		currency: currency,
	})
	const { term, cost: subscriptionCost } = getSubscription(
		productFrequencyInterval,
		productFrequencyUnit
	)
	const localizedProductName =
		domain.toUpperCase() === 'MFP'
			? getMfpProductName(term)
			: getMmfProductName(term)

	return (
		<div className="OrderSummary">
			<h2 className="OrderSummary-h2">
				<FormattedMessage
					id="checkout.orderSummary.heading"
					defaultMessage="Order Summary"
				/>
			</h2>
			<LineItem
				itemName={localizedProductName}
				amount={currencyFormatter.format(subscriptionCost)}
			/>
			{promoCode && (
				<LineItem
					itemName={
						<FormattedMessage
							id="checkout.orderSummary.promoCodeAppliedWithDuration"
							defaultMessage="Promo Code Applied ({promoFrequencyInterval} {promoFrequencyUnit} free)"
							values={{
								promoFrequencyInterval,
								promoFrequencyUnit: frequencyUnitPluralized(
									promoFrequencyUnit,
									promoFrequencyInterval
								),
							}}
						/>
					}
					highlightColor="#2869e6"
				/>
			)}
			<LineItem
				itemName={
					<FormattedMessage
						id="checkout.orderSummary.dueToday"
						defaultMessage="DUE TODAY"
					/>
				}
				amount={
					serviceTotalAmount === '0'
						? currencyFormatter.format('0')
						: currencyFormatter.format(subscriptionCost)
				}
				isTotal
			/>
			<SubscriptionDetails
				term={term}
				promoCode={promoCode}
				promoFrequencyInterval={promoFrequencyInterval}
				promoFrequencyUnit={promoFrequencyUnit}
				productFormattedAmount={currencyFormatter.format(subscriptionCost)}
				nextPaymentDate={new Intl.DateTimeFormat(locale, {
					year: 'numeric',
					month: 'long',
					day: 'numeric',
				}).format(new Date(nextPaymentDate))}
			/>
		</div>
	)
}

const mapStateToProps = (state) => ({
	locale: state.checkout.locale,
	currency: state.checkout.currency,
	productFrequencyInterval: state.checkout.productFrequencyInterval,
	productFrequencyUnit: state.checkout.productFrequencyUnit,
	promoCode: state.checkout.promoCode,
	promoFrequencyInterval: state.checkout.promoFrequencyInterval,
	promoFrequencyUnit: state.checkout.promoFrequencyUnit,
	nextPaymentDate: state.checkout.nextPaymentDate,
	serviceTotalAmount: state.checkout.serviceTotalAmount,
})

export default connect(mapStateToProps)(withRouter(OrderSummary))
