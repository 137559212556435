// TopNav for desktop and wide screen browsers
import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import TopNavMessage from './TopNavMessage';
import './TopNavDesktop.scss';

const privacyRegex = /^\/([a-zA-Z]{2}-[a-zA-Z]{2}\/)?(privacy_and_terms|privacy|app-specific-terms|terms-and-conditions|companies-we-share-data-with)/;
const accountCenterRegex = /^\/([a-zA-Z]{2}-[a-zA-Z]{2}\/)?account-center/;
const overviewRegex = /^\/([a-zA-Z]{2}-[a-zA-Z]{2})?\/?$/;

const isRouteActive = (regmatch) => (match, location) => (
  regmatch.test(location.pathname)
);

const TopNavDesktop = ({
  lang,
}) => {
  return (
    <div className="TopNavDesktop">
      <div className="TopNavDesktop-container">
        <Link
          className="TopNavDesktop-logo"
          to={`/${lang}`}
          role="navigation"
          aria-label="overview"
          // note: the TopNavLogo is defined in this page's TopNav.scss
          // the ua-full-logo draws the under armour image, as it comes from static/styles/icons.css
        >

        </Link>
        <div className="TopNavDesktop-list">
          <NavLink
            className={"TopNavDesktop-item"}
            activeClassName={"is-active"}
            to={`/${lang}`}
            exact
            isActive={isRouteActive(overviewRegex)}
            role="navigation"
          >
            <FormattedMessage
              id="main-nav-link-1"
              defaultMessage="OVERVIEW"
            />
          </NavLink>

          <NavLink
            className={"TopNavDesktop-item"}
            activeClassName={"is-active"}
            to={`/${lang}/account-center`}
            isActive={isRouteActive(accountCenterRegex)}
            role="navigation"
          >
            <FormattedMessage
              id="main-nav-link-2"
              defaultMessage="ACCOUNT CENTER"
            />
          </NavLink>

          <NavLink
            className={"TopNavDesktop-item"}
            activeClassName={"is-active"}
            to={`/${lang}/privacy_and_terms`}
            isActive={isRouteActive(privacyRegex)}
            role="navigation"
          >
            <FormattedMessage
              id="main-nav-link-3"
              defaultMessage="PRIVACY AND TERMS"
            />
          </NavLink>

        </div>
      </div>
      <TopNavMessage desktopOrMobile="desktop" />
    </div>
  );
}

export default TopNavDesktop;
