import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Login as FacebookLogin,
  Initialize as FacebookInitialize,
} from 'react-facebook';

import { BlueLink, Button, ErrorMessage, InputField, Redirect, Title } from '../_Common';

import { POST_LOGIN_SUCCESS } from "../../services/auth/constants";

import * as authActions from  '../../services/auth/actions';
import * as loginActions from '../../services/login/actions';
import * as userActions from  '../../services/user/actions';

import './Login.scss';

class Login extends Component {

  refUsername     = React.createRef();
  refPassword     = React.createRef();
  refLoginButton  = React.createRef();

  state = {
    password: "",
    passwordShow: false,  // false=display dots, true=password is displayed
    attempts: 0,
    facebookCancelled: false,
    error: "",  // text error message to display.
    errorActive: false,  // whether or not the user is in an error state (disables the login button when true)
  };

  componentDidMount() {
    // this.props.actions.login.setUsername("");
    // this resets the username field to blank when the user goes to the /login page
    // if the user comes from the Forgot Password page, the username may have a value. So let's reset it here.
    if ( this.props.location.hash === "#nfapple" ) {
      this.setState({
        errorActive: true,
        error: this.props.intl.formatMessage({ id: "login.error.apple", defaultMessage: "Apple login failed." }),
      });
    }

  }

  onFacebookLogin = (success, result) => {
    const { auth, user } = this.props.actions;
    if(success) {
      auth.postFacebookLogin({
        userId: result.user.id,
        token: result.authResponse.accessToken,
      })
        .then(action => {
          if(action.type === POST_LOGIN_SUCCESS) {
            const accessToken = action.payload.data.result.access_token;
            user.getUser({ accessToken });
          }
        });
    } else {
      if(result && result.status === "unknown") {
        this.setState({
          facebookCancelled: true,
          facebookFailure: false,
        });
      } else {
        this.setState({
          facebookCancelled: false,
          facebookFailure: true,
        });
      }
    }
  };

  onGenericLogin = () => {
    if ( this.props.auth.postLogin.loading ) return;  // already running
    if ( this.state.errorActive ) return;  // user is in an error state, don't re-submit the form

    this.setState({ attempts: this.state.attempts + 1 });

    const { auth, user } = this.props.actions;
    auth.postGenericLogin({
      username: this.props.login.username,
      password: this.state.password,
    }).then(action => {
      if (action.type === POST_LOGIN_SUCCESS) {
        const accessToken = action.payload.data.result.access_token;
        user.getUser({ accessToken });
        // user will be redirected in a moment
      } else {
        // if the result did not return a successful authentication
      }
    });
  };

  enterKeyPress = ( which ) => {
    // user pressed the enter/return key when on username or password entry field
    const usernameHasValue = ( ! new RegExp("^\\s*$").test( this.props.login.username ) );  // true/false
    const passwordHasValue = ( this.state.password !== "" );
    if ( (! passwordHasValue) && ( usernameHasValue ) && ( which === "username" ) ) {
      // user pressed enter after typing a username, but did not yet enter a password
      this.refPassword.current.focus();
    } else if ( ( passwordHasValue ) && (! usernameHasValue ) ) {
      // user pressed enter after typing a password, but did not yet enter a username.
      this.refUsername.current.focus();
    } else if ( usernameHasValue && passwordHasValue ) {
      // user pressed enter after typing both username and password
      if ( this.props.auth.postLogin.loading || this.state.errorActive ) {
        // the form is currently loading or in an error state. Don't submit the form
        return;
      }
      this.refUsername.current.blur();  // de-focus the username field if the user was typing in it
      this.refPassword.current.blur();  // de-focus the password field if the user was typing in it
      this.onGenericLogin();  // click the login button
    }
  }

  componentDidUpdate( prevProps, prevState ) {
    // this handles setting the error state
    const { postLogin } = this.props.auth;
    if ( (! prevState.facebookCancelled) && this.state.facebookCancelled ) {
      this.scrollToTop();
      this.setState({
        error: this.props.intl.formatMessage({ id: "login.error.facebook", defaultMessage: "Facebook login failed." }),
      });
    } else if ( (! prevState.facebookFailure) && this.state.facebookFailure ) {
      this.scrollToTop();
      // unknown error
      this.setState({
        error: this.props.intl.formatMessage({ id: "login.error.facebook", defaultMessage: "Facebook login failed." }),
      });
    } else if ( postLogin ) {
      if ( ! prevProps.auth.postLogin.failure ) {
        if ( postLogin.failure && postLogin.failure.response.status !== 429 ) {
          this.scrollToTop();
          this.setState({
            errorActive: true,
            error: this.props.intl.formatMessage({ id: "login.error.email", defaultMessage: "The email or password entered is incorrect." }),
          });
          // this.setState({ error: "You have exceeded the maximum number of login attempts. Please try again in 30 minutes." });
        } else if ( postLogin.failure && postLogin.failure.response.status === 429 ) {
          this.scrollToTop();
          this.setState({
            error: this.props.intl.formatMessage({ id: "login.error.limit", defaultMessage: "You have exceeded the maximum number of login attempts. Please try again in 30 minutes." }),
            errorActive: true,
          });
        }
      }
    }
  }

  scrollToTop = () => {
    window.scrollTo( 0, 0 );
  }
  /************************************/
  facebookLoginSuccess = ({ profile, tokenDetail }) => {
    this.props.actions.auth.postFacebookLogin({
      userId: profile.id,
      token: tokenDetail.accessToken,
    }).then( (action) => {
      if ( action.type === POST_LOGIN_SUCCESS ) {
        const accessToken = action.payload.data.result.access_token;
        this.props.actions.user.getUser({ accessToken });
      }
    });
  }
  facebookLoginError = () => {
    this.scrollToTop();
    this.setState({
      errorActive: true,
      error: this.props.intl.formatMessage({ id: "login.error.facebook", defaultMessage: "Facebook login failed." }),
    });
  }

  apple = () => {
    if ( this.props.appleSignInReturnUrl ) {
      window.AppleID.auth.init({
        clientId: "com.underarmour.accountportal.site",
        scope: "name email",
        redirectURI: this.props.appleSignInReturnUrl,
        usePopup: false,
      });
      window.AppleID.auth.signIn();
    }
  }

  /************************************/
  render() {
    const { user } = this.props.user;
    const { accessToken, postLogin } = this.props.auth;
    const {
      password,
    } = this.state;
    const { username } = this.props.login;

    // did the user successfully sign in?
    if ( user && accessToken ) {
      return (
        <Redirect to="" />
      );
    }

    const loginWidth = (
      this.props.screen.width - 20 < 338
      ? this.props.screen.width - 20    // small width
      : 338                             // large width
    );

    const loginBoxTopMargin = 40;

    const usernameHasValue = ( ! new RegExp("^\\s*$").test( username ) );  // true/false
    const passwordHasValue = ( this.state.password !== "" );
    const loginButtonEnabled = (
      (usernameHasValue && passwordHasValue) &&
      (! this.state.errorActive ) &&
      (! postLogin.loading )
    );

    return (
      <div className="Login"
        style={{ paddingTop: loginBoxTopMargin }}
      >

        {/* overall page */}
        <FacebookInitialize>
          { ({ isReady: facebookIsReady, api: facebookAPI }) => {
            // facebookIsReady (true/false): Has the Facebook api been initialized
            // console.log( facebookIsReady, facebookAPI );

            return (
              <FacebookLogin
                scope="email"
                onCompleted={ this.facebookLoginSuccess }
                onError={ this.facebookLoginError }
              >
                {
                  ({ loading: facebookLoading, handleClick: facebookClick, error: facebookError, data: facebookData }) => {
                    // facebookLoading: user clicked the login button, and waiting for a result from facebook
                    // facebookClick: function to attach to the button's onClick to kick off the facebook login attempt
                    // facebookError: Object returned if the user fails their FB credentials or denies their account access to the Account Portal
                    // facebookData: on successful login, this object has is their facebook userId, email address, and FB token.
                    // console.log( facebookLoading, facebookError, facebookData );

                    // BEGIN the entire login section
                    return (
                      <div className="Login-container"
                        style={{ width: loginWidth }}
                      >
                        {/* the 300px-width box centered on the page */}

                        <Title>
                          <FormattedMessage
                            id="login"
                            defaultMessage="Login"
                          />
                        </Title>

                        <div className="Login-info">
                          <FormattedMessage
                            id="login.info"
                            defaultMessage="Export your data, manage email notifications, or delete your Under Armour accounts"
                          />
                        </div>

                        <ErrorMessage
                          message={ this.state.error }
                          width={ loginWidth }
                          errorHeight={ 39 }
                          noErrorHeight={ 10 }
                        />

                        <div className="Login-form"
                          style={{ width: loginWidth }}
                        >
                          <div className="Login-input">
                            <InputField
                              inputRef={ this.refUsername }
                              name="username"
                              type="email"
                              label={
                                <FormattedMessage
                                  id="option.email"
                                  defaultMessage="Email Address"
                                />
                              }
                              width={ loginWidth }
                              height={ 51 }
                              fontSize={ 16 }
                              value={ username }
                              setValue={ (username) => this.props.actions.login.setUsername( username ) }
                              clearError={ () => this.setState({ errorActive: false }) }
                              autoComplete={ false }
                              autoCorrect={ false }
                              autoCapitalize={ false }
                              disallowSpaces={ true }
                              disallowEmoji={ true }
                              onEnter={ () => this.enterKeyPress("username") }
                              disabled={
                                facebookLoading  // disable input when the facebook login is in progress
                                ||
                                this.props.auth.postLogin.loading // disable input when the login api is loading
                              }
                            />
                          </div>
                          <div className="Login-input">
                            <InputField
                              inputRef={ this.refPassword }
                              name="password"
                              type={ this.state.passwordShow ? "text" : "password" }
                              label={
                                <FormattedMessage
                                  id="login.password"
                                  defaultMessage="Password"
                                />
                              }
                              width={ loginWidth }
                              height={ 51 }
                              fontSize={ 16 }
                              value={ password }
                              setValue={ (password) => this.setState({ password }) }
                              clearError={ () => this.setState({ errorActive: false }) }
                              autoComplete={ false }
                              autoCorrect={ false }
                              autoCapitalize={ false }
                              disallowSpaces={ false }
                              onEnter={ () => this.enterKeyPress("password") }
                              endAdornmentClick={ () => this.setState({ passwordShow: !this.state.passwordShow }) }
                              endAdornmentText={
                                ( this.state.passwordShow
                                  && <FormattedMessage id="resetPassword.password.hide" defaultMessage="HIDE"/>
                                )
                                || <FormattedMessage id="resetPassword.password.show" defaultMessage="SHOW"/>
                              }
                              disabled={
                                facebookLoading  // disable input when the facebook login is in progress
                                ||
                                this.props.auth.postLogin.loading // disable input when the login api is loading
                              }
                            />
                          </div>

                          <BlueLink
                            to="forgot"
                          >
                            <FormattedMessage
                              id="login.forgot"
                              defaultMessage="Forgot Password?"
                            />
                          </BlueLink>

                          <Button
                            buttonRef={ this.refLoginButton }
                            text={
                              <FormattedMessage
                                id="login"
                                defaultMessage="Login"
                                children={
                                  ( formattedMessage => formattedMessage.toLocaleUpperCase() )
                                }
                              />
                            }
                            width={ loginWidth }
                            click={ this.onGenericLogin }
                            disabled={
                              (! loginButtonEnabled) // disables the button while either loading or in error state
                              ||
                              facebookLoading // disables the button while the facebook login is in progress
                            }
                            loading={ postLogin.loading }     // puts the UA logo in the button while waiting for a response
                          />

                          {/* if facebook api has been loaded/initialized, show the Or Login With Facebook section */}

                          <div className="Login-button-or">
                            <FormattedMessage
                              id="login.or"
                              defaultMessage="or"
                            />
                          </div>

                        </div>

                        { facebookIsReady
                          &&
                          <div className="Login-facebook">
                            <div className="Login-button-facebook">
                              <button
                                style={{ width: loginWidth }}
                                onClick={ facebookClick }
                                disabled={
                                  facebookLoading || this.props.auth.postLogin.loading
                                  ? "disabled"
                                  : ""
                                }
                              >
                                <img
                                  src="/img/flogo-58.png"
                                  // this 58x58 logo png file was downloaded from https://en.facebookbrand.com/assets/f-logo/?audience=landing
                                  alt="Facebook"
                                  width={ 19 }
                                  height={ 19 }
                                />
                                <FormattedMessage
                                  id="login.facebook"
                                  defaultMessage="Continue with facebook"
                                  children={
                                    ( formattedMessage => formattedMessage.toLocaleUpperCase() )
                                  }
                                />
                              </button>
                            </div>
                          </div>
                        }

                        <div className="Login-button-apple"
                        >
                          <button
                            style={{ width: loginWidth }}
                            onClick={ this.apple }
                            disabled={ ! this.props.appleSignInReturnUrl }
                          >
                            <img
                              src="/img/apple.png"
                              alt="Apple"
                              width={ 18 }
                              height={ 21 }
                            />
                            <FormattedMessage
                              id="login.apple"
                              defaultMessage="Sign in with Apple"
                              children={
                                ( formattedMessage => formattedMessage.toLocaleUpperCase() )
                              }
                            />
                          </button>
                        </div>

                        { false
                          // this section intentionally temporarilly disabled until we have a zendesk help page to send the user to
                          &&
                          <div className="Login-help">
                            <FormattedMessage
                              id="login.help"
                              defaultMessage="Need to access or delete your data but can not access your account?"
                            />
                            <BlueLink
                              to=""
                            >
                              <FormattedMessage
                                id="login.clickhere"
                                defaultMessage="Click here"
                              />
                            </BlueLink>
                          </div>
                        }

                      </div>
                    );
                  }
                }
              </FacebookLogin>

            )
          } }
        </FacebookInitialize>

        <div className="Login-bottomPadding">
          &nbsp;
        </div>

      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    locale: state.locale,
    login: state.login,
    screen: state.screen,
    user: state.user,
    appleSignInReturnUrl: state.config.config.appleSignInReturnUrl,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      auth : bindActionCreators( authActions, dispatch ),
      login: bindActionCreators( loginActions, dispatch ),
      user : bindActionCreators( userActions, dispatch ),
    },
  };
};

export default withRouter( connect(
  mapStateToProps,
  mapDispatchToProps,
)( injectIntl(Login) ) );
