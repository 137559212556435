import {addLocaleData} from 'react-intl';
import intlDA from 'react-intl/locale-data/da';
import intlDE from 'react-intl/locale-data/de';
import intlEN from 'react-intl/locale-data/en';
import intlES from 'react-intl/locale-data/es';
import intlFR from 'react-intl/locale-data/fr';
import intlID from 'react-intl/locale-data/id';
import intlIT from 'react-intl/locale-data/it';
import intlJA from 'react-intl/locale-data/ja';
import intlMS from 'react-intl/locale-data/ms';
import intlNL from 'react-intl/locale-data/nl';
import intlPL from 'react-intl/locale-data/pl';
import intlPT from 'react-intl/locale-data/pt';
import intlZH from 'react-intl/locale-data/zh';
import intlZU from 'react-intl/locale-data/zu';
import intlRU from 'react-intl/locale-data/ru';
import intlKO from 'react-intl/locale-data/ko';
import intlSV from 'react-intl/locale-data/sv';
import intlTR from 'react-intl/locale-data/tr';
import intlTL from 'react-intl/locale-data/tl';
import intlTH from 'react-intl/locale-data/th';

addLocaleData(intlDA);
addLocaleData(intlDE);
addLocaleData(intlEN);
addLocaleData(intlES);
addLocaleData(intlFR);
addLocaleData(intlID);
addLocaleData(intlIT);
addLocaleData(intlJA);
addLocaleData(intlMS);
addLocaleData(intlNL);
addLocaleData(intlPL);
addLocaleData(intlPT);
addLocaleData(intlZH);
addLocaleData(intlZU);
addLocaleData(intlRU);
addLocaleData(intlKO);
addLocaleData(intlSV);
addLocaleData(intlTR);
addLocaleData(intlTL);
addLocaleData(intlTH);
