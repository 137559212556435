import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Footer } from '../_Layout';

import './404.scss';

const MIN_HEIGHT = 200;

class Error404 extends Component {
  render() {
    const height = (
      ( this.props.screen.height - 150 ) < MIN_HEIGHT
      ? MIN_HEIGHT
      : this.props.screen.height - 150
    );
    return (
      <div>
        <div className="Error"
          style={{ height }}
        >
          <div className="Error-content">
            <div className="Error-logo" />
            <div className="Error-message">
              404 Not found.
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    screen: state.screen,
  };
};

export default connect( mapStateToProps )( Error404 );
