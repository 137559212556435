// dimensions of the top nav message (the mfp banner)
// for desktop, mobile, and some languages

export const topNavMessageHeight = {
  desktop: 110,
  mobileLandscape: 195,
  mobilePortrait: 295,
}
export const topNavMessagePadding = {
  desktop: 210,
  mobileLandscape: 280,
  mobilePortrait: 370,
}
export const topNavMessageSmallerFontLanguages = [
  "de",
];
