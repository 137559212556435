// Initializes amplitude if allowed by trustarc cookie manager

import { initAmplitude } from '../../utils/amplitude';

const amplitude = (amplitudeApiKey) => {
  initAmplitude(amplitudeApiKey);
  return true;
}

export default amplitude;
