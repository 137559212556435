import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

const HomeOverview = ({
  goTo,   // go to a clickable url
  lang,   // the language from the browser url
}) => {

  if ( (typeof lang !== "string") || (! lang) ) { lang = "en-us" };

  const path = "/" + lang;

  return (
    <div className="overviewDetails"
      role="main"
    >

      {/*****************************************/}
      {/* YOUR ACCOUNT CENTER */}
      <div className="overviewDetail col-1-3">
        <Link to={ path + "/account-center" }
        >
          <i className="user-icon" />
          <h3
            role="region"
            aria-labelledby="home.promoBox1.title"
            aria-describedby="home.promoBox1.description"
          >
            <FormattedMessage
              id="home.promoBox1.title"
              defaultMessage="Your Account Center"
            >
              { (message) => <span id="home.promoBox1.title">{message}</span> }
            </FormattedMessage>
          </h3>
        </Link>
        <p>
          <FormattedMessage
            id="home.promoBox1.description"
            defaultMessage="Learn about how to update your account, login and profile, and other personal information."
          >
            { (message) => <span id="home.promoBox1.description">{message}</span> }
          </FormattedMessage>
        </p>
        <ul>
          <li>
            <Link to={ path + "/account-center#update-your-profile-information" }
              className="overviewDetailLink"
            >
              <FormattedMessage
                id="home.promoBox1.link1"
                defaultMessage="Updating Your Profile Information"
              />
            </Link>
          </li>
          <li>
            <Link to={ path + "/account-center#our-apps-and-sites" }
              className="overviewDetailLink"
            >
              <FormattedMessage
                id="home.promoBox1.link2"
                defaultMessage="Find & Manage Your Apps"
              />
            </Link>
          </li>
          <li>
            <Link to={ path + "/account-center#updating-your-password" }
              className="overviewDetailLink"
            >
              <FormattedMessage
                id="home.promoBox1.link3"
                defaultMessage="Updating Your Password"
              />
            </Link>
          </li>
          <li>
            <Link to={ path + "/account-center#help-and-support" }
              className="overviewDetailLink"
            >
              <FormattedMessage
                id="home.promoBox1.link4"
                defaultMessage="Help and Support"
              />
            </Link>
          </li>
        </ul>

      </div>

      {/*****************************************/}
      {/* PRIVACY INFORMATION & POLICIES */}
      <div className="overviewDetail col-1-3">
        <Link to={ path + "/privacy_and_terms" }
        >
          <i className="privacy-icon" />
          <h3
            role="region"
            aria-labelledby="home.promoBox2.title"
            aria-describedby="home.promoBox2.description"
          >
            <FormattedMessage
              id="home.promoBox2.title"
              defaultMessage="Privacy Information & Policies"
            >
              { (message) => <span id="home.promoBox2.title">{message}</span> }
            </FormattedMessage>
          </h3>
        </Link>
        <p>
          <FormattedMessage
            id="home.promoBox2.description"
            defaultMessage={
              "Get information on how we protect your data and privacy and " +
              "our apps terms and conditions."
            }
          >
            { (message) => <span id="home.promoBox2.description">{message}</span> }
          </FormattedMessage>
        </p>
        <ul>
          <li>
            <Link to={ path + "/privacy_and_terms" }
              className="overviewDetailLink"
            >
              <FormattedMessage
                id="home.promoBox2.link1"
                defaultMessage="Overview"
              />
            </Link>
          </li>
          <li>
            <Link to={ path + "/privacy" }
              className="overviewDetailLink"
            >
              <FormattedMessage
                id="home.promoBox2.link2"
                defaultMessage="Privacy Policy"
              />
            </Link>
          </li>
          <li>
            <Link to={ path + "/terms-and-conditions" }
              className="overviewDetailLink"
            >
              <FormattedMessage
                id="home.promoBox2.link3"
                defaultMessage="Terms and Conditions"
              />
            </Link>
          </li>
          <li>
            <Link to={ path + "/account-center#cookie-preferences" }
              className="overviewDetailLink"
            >
              <FormattedMessage
                id="home.promoBox2.link4"
                defaultMessage="Cookies"
              />
            </Link>
          </li>
        </ul>

      </div>
      {/*******************************************/}

      {/*****************************************/}
      {/* PRIVACY INFORMATION & POLICIES */}
      <div className="overviewDetail col-1-3">
        <Link to={ path + "/account-center" }
        >
          <i className="preferences-icon" />
          <h3
            role="region"
            aria-labelledby="home.promoBox3.title"
            aria-describedby="home.promoBox3.description"
          >
            <FormattedMessage
              id="home.promoBox3.title"
              defaultMessage="Your Preferences & Sharing"
            >
              { (message) => <span id="home.promoBox3.title">{message}</span> }
            </FormattedMessage>
          </h3>
        </Link>
        <p>
          <FormattedMessage
            id="home.promoBox3.description"
            defaultMessage={
              "Guide to understanding when and where your information is being " +
              "shared and how to manage those preferences."
            }
          >
            { (message) => <span id="home.promoBox3.description">{message}</span> }
          </FormattedMessage>
        </p>
        <ul>
          <li>
            <Link to={ path + "/account-center#sharing-settings" }
              className="overviewDetailLink"
            >
              <FormattedMessage
                id="home.promoBox3.link1"
                defaultMessage="Sharing Standards Guide"
              />
            </Link>
          </li>
          <li>
            <Link to={ path + "/account-center#sharing-settings" }
              className="overviewDetailLink"
            >
              <FormattedMessage
                id="home.promoBox3.link2"
                defaultMessage="How to Update Sharing Settings"
              />
            </Link>
          </li>
          <li>
            <Link to={ path + "/account-center#update-your-profile-information" }
              className="overviewDetailLink"
            >
              <FormattedMessage
                id="home.promoBox3.link3"
                defaultMessage="Updating Your Other Preferences"
              />
            </Link>
          </li>
        </ul>

      </div>
      {/*******************************************/}

    </div>
  )
}

export default HomeOverview;
