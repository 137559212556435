import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as topNavMessageActions from '../../services/topNavMessage/actions';
import { topNavMessageHeight, topNavMessageSmallerFontLanguages } from './topNavMessageDimensions';
import './TopNavMessage.scss';

class TopNavMessage extends Component {

  closeTopNavMessage = () => {
    this.props.actions.topNavMessage.setTopNavMessageShow(false);
  }

  render() {
    if ( ! this.props.showMfpBanner ) {
      return null;
    }
    const { mfpRedirectUrl } = this.props;
    return (
      <div className="TopNavMessage-container"
        style={
          this.props.desktopOrMobile === "desktop"
          ? { height: topNavMessageHeight.desktop }
          : this.props.screen.width > 550 ? { height: topNavMessageHeight.mobileLandscape } : { height: topNavMessageHeight.mobilePortrait }
        }
      >
        <div className="TopNavMessage-close"
          onClick={ this.closeTopNavMessage }
        >
          <img src="/img/x.svg" alt="" width="25" height="25" />
        </div>
        <div className="TopNavMessage"
          style={
            this.props.locale && topNavMessageSmallerFontLanguages.indexOf(this.props.locale.language.base) >= 0
            ? { fontSize: 15 }
            : { }
          }
        >
          <FormattedMessage
            id="mfp-notice-header"
            defaultMessage="Please note that MyFitnessPal is no longer owned by or affiliated with Under Armour, but certain MyFitnessPal account management functionality will be available temporarily on this site until it is transitioned to <a href='{mfpRedirectUrl}/'>MyFitnessPal.com</a>.  For information about MyFitnessPal, please see the updated MyFitnessPal <a href='{mfpRedirectUrl}/terms-of-service'>Terms and Conditions of Use</a> and <a href='{mfpRedirectUrl}/privacy-policy'>Privacy Policy</a>."
            values={{ mfpRedirectUrl }}
          >
            { (message) => <span id="mfp-notice-header" dangerouslySetInnerHTML={{__html: message}} /> }
          </FormattedMessage>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    locale: state.locale,
    mfpRedirectUrl: state.config.config.mfpRedirectUrl,
    screen: state.screen,
    showMfpBanner: state.config.config.mfpRedirectUrl && state.topNavMessage.topNavMessageShow,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      topNavMessage: bindActionCreators(topNavMessageActions, dispatch),
    },
  };
};
export default connect( mapStateToProps, mapDispatchToProps )( TopNavMessage );
