import {
  COOKIES_TRUSTARC_LOADED,
  COOKIES_SERVICES_LOADED,
} from './constants'

export const setTrustarcLoaded = ( data ) => {
  if ( typeof data === "boolean" ) {
    return dispatch => {
      return dispatch({
        type: COOKIES_TRUSTARC_LOADED,
        payload: { trustarcLoaded: data },
      })
    }
  }
}

export const setServiceLoaded = ( service ) => {
  return dispatch => {
    return dispatch({
      type: COOKIES_SERVICES_LOADED,
      payload: service,
    })
  }
}
