import * as constants from './constants'
import {
  publisherTypes,
  parseErrorForAnalytics,
  getSafeMetadataFromPaymentIntent
} from '../../utils/ue-analytics'

export const fetchPaymentIntent = (domain, paymentIntentId) =>
  (dispatch) =>
    dispatch({
      types: [
        constants.GET_PAYMENT_INTENT_LOADING,
        constants.GET_PAYMENT_INTENT_SUCCESS,
        constants.GET_PAYMENT_INTENT_FAILURE
      ],
      payload: {
        request: {
          method: 'get',
          url: `/checkout/paymentIntent/${domain}/${paymentIntentId}`,
        }
      }
    }).then(result => {
        dispatch({
          type: constants.PAGE_LOAD_ANALYTICS,
          meta: {
            analytics: {
              type: 'page_viewed',
              payload: {
                forPublisherTypes: [publisherTypes.AMPLITUDE],
                appName: domain,
                page_name: window.location.pathname,
                outcome: result.error ? 'error' : 'success',
                error: result.error ? parseErrorForAnalytics(result.error) : null,
              },
            }
          }
        })
        if (result.error) { return Promise.resolve() }
        dispatch({
          type: constants.PAGE_LOAD_SUCCESS_ANALYTICS,
          meta: {
            analytics: {
              type: 'payment_billing_profile',
              payload: {
                forPublisherTypes: [publisherTypes.AMPLITUDE],
                appName: domain,
                ...getSafeMetadataFromPaymentIntent(result.payload.data),
              }
            }
          }
        })
    })

export const createPaymentIntent = (payload) =>
  (dispatch) =>
    dispatch({
      types: [
        constants.POST_PAYMENT_INTENT_LOADING,
        constants.POST_PAYMENT_INTENT_SUCCESS,
        constants.POST_PAYMENT_INTENT_FAILURE
      ],
      payload: {
        request: {
          method: 'post',
          url: `/checkout/paymentIntent`,
          data: payload
        },
      },
    })

export const addCard = (domain, domainUserId, sourceId, cardBrand, currencyCode) =>
    (dispatch) =>
      dispatch({
        types: [
          constants.POST_ADD_CARD_LOADING,
          constants.POST_ADD_CARD_SUCCESS,
          constants.POST_ADD_CARD_FAILURE
        ],
        payload: {
          request: {
            method: 'post',
            url: '/checkout/addCard',
            data: { domain, domainUserId, sourceId, cardBrand, currencyCode }
          }
        }
      })

export const prepareToCapture = (domain, domainUserId, paymentIntentId, sourceId, cardBrand, currencyCode) =>
    (dispatch) =>
      dispatch({
        types: [
          constants.POST_PREPARE_TO_CAPTURE_LOADING,
          constants.POST_PREPARE_TO_CAPTURE_SUCCESS,
          constants.POST_PREPARE_TO_CAPTURE_FAILURE
        ],
        payload: {
          request: {
            method: 'post',
            url: '/checkout/prepareToCapture',
            data: { domain, domainUserId, paymentIntentId, sourceId, cardBrand, currencyCode },
          },
        },
      })

export const completeTransaction = (domain, domainUserId, productId, promoCode, countryCode, billingProfileId, paymentIntentId) =>
    (dispatch) =>
      dispatch({
        types: [
          constants.POST_COMPLETE_TRANSACTION_LOADING,
          constants.POST_COMPLETE_TRANSACTION_SUCCESS,
          constants.POST_COMPLETE_TRANSACTION_FAILURE
        ],
        payload: {
          request: {
            method: 'post',
            url: '/checkout/completeTransaction',
            data: { domain, domainUserId, productId, promoCode, countryCode, billingProfileId, paymentIntentId },
          },
        },
      })

export const logHandleSubmit = (payload) =>
    (dispatch) => {
      dispatch({
        type: constants.HANDLE_SUBMIT_ANALYTICS,
        meta: {
          analytics: {
            type: 'payment_initiate',
            payload: {
              forPublisherTypes: [publisherTypes.AMPLITUDE],
              appName: payload.domain,
              ...getSafeMetadataFromPaymentIntent(payload),
            }
          }
        }
      })
      dispatch({
        type: constants.HANDLE_SUBMIT_ANALYTICS_BRANCH,
        meta: {
          analytics: {
            type: 'payment_initiate_web',
            payload: {
              forPublisherTypes: [publisherTypes.BRANCH],
              branch_opt_in: true,
            }
          }
        },
      })
    }

export const logPaymentFailure = (payload) =>
    (dispatch) => {
      dispatch({
        type: constants.PAYMENT_FAILURE_ANALYTICS,
        meta: {
          analytics: {
            type: 'payment_failure',
            payload: {
              forPublisherTypes: [publisherTypes.AMPLITUDE],
              appName: payload.domain,
              ...getSafeMetadataFromPaymentIntent(payload),
            }
          }
        }
      })
      dispatch({
        type: constants.PAYMENT_FAILURE_ANALYTICS_BRANCH,
        meta: {
          analytics: {
            type: 'payment_failure_web',
            payload: {
              forPublisherTypes: [publisherTypes.BRANCH],
              branch_opt_in: true,
            }
          }
        },
      })
    }

export const logPurchaseSuccess = (payload) =>
    (dispatch) =>
      dispatch({
        type: constants.PAYMENT_SUCCESS_ANALYTICS,
        meta: {
          analytics: {
            type: 'payment_success',
            payload: {
              forPublisherTypes: [publisherTypes.AMPLITUDE],
              appName: payload.domain,
              ...getSafeMetadataFromPaymentIntent(payload),
            }
          }
        }
      })

export const logCouponSuccess = (payload) =>
    (dispatch) => {
      dispatch({
        type: constants.COUPON_SUCCESS_ANALYTICS,
        meta: {
          analytics: {
            type: 'coupon_payment_info_complete',
            payload: {
              forPublisherTypes: [publisherTypes.AMPLITUDE],
              appName: payload.domain,
              ...getSafeMetadataFromPaymentIntent(payload),
            }
          }
        }
      })
      dispatch({
        type: constants.COUPON_SUCCESS_ANALYTICS_BRANCH,
        meta: {
          analytics: {
            type: 'payment_success_web',
            payload: {
              forPublisherTypes: [publisherTypes.BRANCH],
              branch_opt_in: true,
            }
          }
        },
      })
    }
