import React, { Component } from 'react';
// import { FormattedMessage } from 'react-intl';
import {Redirect, withRouter} from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Bold } from '../_Common';

import * as authActions from "../../services/auth/actions";
import * as userActions from "../../services/user/actions";

import './Account.scss';

class Account extends Component {

  render() {
    const { user } = this.props.user;
    const { accessToken } = this.props.auth;
    const { lang } = this.props.match.params;
    if(!user && !accessToken) {
      return (
        <Redirect
          to={{
            pathname: `/${lang}`
          }}
        />
      )
    }
    return (
      <div className="Account">
        <Bold>
          You are logged-in successfully.
        </Bold>
        <Bold>
          This is a temporary/placeholder page, and will be replaced by a real page soon.
        </Bold>
        <Bold>
          Debug user token information is below:
        </Bold>
        <div className="Account-token">
          { JSON.stringify(user) }
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    user: state.user,
    locale: state.locale,
    screen: state.screen,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      auth: bindActionCreators( authActions, dispatch ),
      user: bindActionCreators( userActions, dispatch ),
    },
  };
};

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)( Account ));
