import React from 'react';
import classNames from "classnames";
import './Button.scss';

/* accepts the following props
REQUIRED:
  text            = text to display in the button
REQUIRED FUNCTIONS:
  click           = function that happens when user clicks the button
OPTIONAL:
  buttonRef       = a variable containing a React.createRef() value from the parent
  width           = number in px
  disabled        = true/false whether the button is disabled
  loading         = true/false whether the button is in a loading state
  allCaps         = true/false force all caps for text
*/

const Button = ({
  allCaps, buttonRef, click, disabled, loading, text, width,
}) => {
  return (
    <div className="CommonButton">
      <button
        ref={ buttonRef }
        style={
          typeof width === "number"
          ? { width }
          : { }
        }
        onClick={ click }
        disabled={ disabled }
        className={ classNames({
          "CommonButton-disabled": disabled,
          "CommonButton-allCaps": allCaps,  // if allCaps specified in the <Button>, enable this class
        } ) }
      >
        { ( loading
            &&
            <div className="ualogo">&nbsp;</div>
          )
          ||
          text
        }
      </button>
    </div>
  );
}

export default Button;
