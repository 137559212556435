import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect } from '../_Common';
import * as userActions from  '../../services/user/actions';

class LoginPost extends Component {

  componentDidMount() {
    const { accessToken } = this.props.match.params;
    localStorage.setItem('access_token', accessToken);
    this.props.actions.user.getUser({ accessToken });
  }

  render() {
    if ( this.props.user.user ) {
      // when this.props.user.user has a value,
      // this means access_token stored in local storage,
      // and Identity backend verified the token
      return (
        <Redirect to="" />
      );
    }

    return null;
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      user : bindActionCreators( userActions, dispatch ),
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)( LoginPost );
