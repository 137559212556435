const namespace = 'auth/';

export const POST_LOGIN_LOADING = `${namespace}POST_LOGIN_LOADING`;
export const POST_LOGIN_SUCCESS = `${namespace}POST_LOGIN_SUCCESS`;
export const POST_LOGIN_FAILURE = `${namespace}POST_LOGIN_FAILURE`;

export const GET_RESET_PASSWORD_LOADING = `${namespace}GET_RESET_PASSWORD_LOADING`;
export const GET_RESET_PASSWORD_SUCCESS = `${namespace}GET_RESET_PASSWORD_SUCCESS`;
export const GET_RESET_PASSWORD_FAILURE = `${namespace}GET_RESET_PASSWORD_FAILURE`;

export const POST_RESET_PASSWORD_LOADING = `${namespace}POST_RESET_PASSWORD_LOADING`;
export const POST_RESET_PASSWORD_SUCCESS = `${namespace}POST_RESET_PASSWORD_SUCCESS`;
export const POST_RESET_PASSWORD_FAILURE = `${namespace}POST_RESET_PASSWORD_FAILURE`;

export const POST_FORGOT_PASSWORD_LOADING = `${namespace}POST_FORGOT_PASSWORD_LOADING`;
export const POST_FORGOT_PASSWORD_SUCCESS = `${namespace}POST_FORGOT_PASSWORD_SUCCESS`;
export const POST_FORGOT_PASSWORD_FAILURE = `${namespace}POST_FORGOT_PASSWORD_FAILURE`;

export const POST_CONFIRM_EMAIL_LOADING = `${namespace}POST_CONFIRM_EMAIL_LOADING`;
export const POST_CONFIRM_EMAIL_SUCCESS = `${namespace}POST_CONFIRM_EMAIL_SUCCESS`;
export const POST_CONFIRM_EMAIL_FAILURE = `${namespace}POST_CONFIRM_EMAIL_FAILURE`;

export const LOGOUT = `${namespace}LOGOUT`;
