import {
  createStore,
  applyMiddleware,
  compose,
  combineReducers,
} from 'redux';
import thunkMiddleware from 'redux-thunk';
// import persistState from 'redux-localstorage';
import rootReducer from '../services/rootReducer';
import promiseMiddleware from './promiseMiddleware';
import axiosMiddleware from './configureAxios';
import analyticsMiddleware from './configureAnalytics';

export default function configureStore (initialState) {

  const middleware = [
    thunkMiddleware,
    promiseMiddleware,
    axiosMiddleware,
    analyticsMiddleware,
  ];

  const reducer = combineReducers( rootReducer );

  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const enhancer = composeEnhancers(
    applyMiddleware( ...middleware )
  );

  const store = createStore(
    reducer,
    initialState,
    enhancer
  );

  return store;

}
