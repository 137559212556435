import {
  POST_LOGIN_LOADING,
  POST_LOGIN_SUCCESS,
  POST_LOGIN_FAILURE,
  POST_RESET_PASSWORD_LOADING,
  POST_RESET_PASSWORD_SUCCESS,
  POST_RESET_PASSWORD_FAILURE,
  GET_RESET_PASSWORD_LOADING,
  GET_RESET_PASSWORD_SUCCESS,
  GET_RESET_PASSWORD_FAILURE,
  POST_CONFIRM_EMAIL_LOADING,
  POST_CONFIRM_EMAIL_SUCCESS,
  POST_CONFIRM_EMAIL_FAILURE,
  POST_FORGOT_PASSWORD_LOADING,
  POST_FORGOT_PASSWORD_SUCCESS,
  POST_FORGOT_PASSWORD_FAILURE,
  LOGOUT,
} from './constants'
import {sendEvent} from '../../utils/api'
import { parseErrorForAnalytics } from '../../utils/ue-analytics'

export const logout = () =>
  (dispatch) =>
    dispatch({
      type: LOGOUT,
      payload: {},
    });

export const postGenericLogin = ({username, password}) =>
  (dispatch) =>
    dispatch({
      types: [POST_LOGIN_LOADING, POST_LOGIN_SUCCESS, POST_LOGIN_FAILURE],
      payload: {
        request:{
          method: 'post',
          url:'/auth/login/generic',
          data: {
            username,
            password,
          }
        }
      }
    });

export const postFacebookLogin = ({userId, token}) => (dispatch, state) => {
  const facebookAppId = state().config.config.facebookAppId;
  return dispatch({
    types: [POST_LOGIN_LOADING, POST_LOGIN_SUCCESS, POST_LOGIN_FAILURE],
    payload: {
      request: {
        method: 'post',
        url:'/auth/login/facebook',
        data: {
          provider: 'facebook',
          appId: facebookAppId,
          userId,
          token,
        }
      }
    }
  });
}

export const getResetPassword = (token) =>
  (dispatch) =>
    dispatch({
      types: [GET_RESET_PASSWORD_LOADING, GET_RESET_PASSWORD_SUCCESS, GET_RESET_PASSWORD_FAILURE],
      payload: {
        request:{
          method: 'get',
          url:`/auth/password/reset/${token}`,
        }
      }
    }).then(action => {
      if(action.type === GET_RESET_PASSWORD_SUCCESS) {
        const user = action.payload.data.result;
        const event = {
          'client_id': 'account-services',
          'domain': user.domain,
          'uacf-id': user.user_id,
          'events': [
            {
              'attributes': {
                'type': 'sso_password_reset_portal_visited',
              },
              'timestamp': new Date().toISOString(),
              'type': 'sso_password_reset_portal_visited',
            },
          ],
        };
        sendEvent(event)
      }
    });

export const postResetPassword =
  (token, password, recaptchaToken) =>
    (dispatch) =>
      dispatch({
        types: [POST_RESET_PASSWORD_LOADING, POST_RESET_PASSWORD_SUCCESS, POST_RESET_PASSWORD_FAILURE],
        payload: {
          request:{
            method: 'post',
            url:`/auth/password/reset/${token}`,
            data: {
              password,
              recaptchaToken,
            }
          }
        }
      });

export const postForgotPassword =
  (email, redirectUri) =>
    (dispatch) =>
      dispatch({
        types: [POST_FORGOT_PASSWORD_LOADING, POST_FORGOT_PASSWORD_SUCCESS, POST_FORGOT_PASSWORD_FAILURE],
        payload: {
          request:{
            method: 'post',
            url:'/auth/password/forgot',
            data: {
              email,
              redirectUri
            }
          }
        }
      });

export const forceConfirmEmailSuccess =
  () =>
    (dispatch) =>
      dispatch({
        type: POST_CONFIRM_EMAIL_SUCCESS,
        payload: {
          // Mimic axios response on a success confirm email
        }
      });

export const postConfirmEmail =
  (token, appName) =>
    (dispatch) =>
      dispatch({
        types: [POST_CONFIRM_EMAIL_LOADING, POST_CONFIRM_EMAIL_SUCCESS, POST_CONFIRM_EMAIL_FAILURE],
        payload: {
          request:{
            method: 'post',
            url:`/auth/email/confirm/${token}`,
          }
        }
      })
      .then(action => {
        if(action.type === POST_CONFIRM_EMAIL_SUCCESS) {
          dispatch({
            type: 'CONFIRM_EMAIL_ANALYTICS',
            meta: {
              analytics: {
                type: 'verification_link_clicked',
                payload: {
                  appName,
                  outcome: 'confirmed'
                },
              }
            }
          })
        } else if(action.type === POST_CONFIRM_EMAIL_FAILURE) {
          dispatch({
            type: 'CONFIRM_EMAIL_ANALYTICS',
            meta: {
              analytics: {
                type: 'verification_link_clicked',
                payload: {
                  appName,
                  outcome: 'error',
                  error: parseErrorForAnalytics(action.error)
                },
              },
            },
          })
        }
      })
      .catch(error => {
        dispatch({
          type: 'CONFIRM_EMAIL_ANALYTICS',
          meta: {
            analytics: {
              type: 'verification_link_clicked',
              payload: {
                appName,
                outcome: 'error',
                error: parseErrorForAnalytics(error)
              },
            },
          },
        })
      });
