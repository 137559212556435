import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './Footer.scss';

class Footer extends Component {

  clickCookiePreferences = () => {
    document.getElementById("teconsent").getElementsByTagName("a")[0].click();
  }

  render() {

    const lang = this.props.lang || "en-us";
    const path = "/" + lang;

    return (
      <div className="Footer"
        role="contentinfo"
      >
        <div className="Footer-flexContainer">
          <div className="Footer-flexItemCopyright">
            &copy; 2020 Under Armour, INC.
          </div>

          <div className="Footer-linksFlexbox">

            <div className="Footer-link">
              <Link to={ path + "/privacy" }
                className="Footer-nav-link"
                role="navigation"
                aria-labelledby="footer.nav.privacylink"
              >
                <FormattedMessage
                  id="footer.nav.privacylink"
                  defaultMessage="Privacy Policy"
                >
                  { (message) => <span id="footer.nav.privacylink">{message}</span> }
                </FormattedMessage>
              </Link>
            </div>

            <div className="Footer-link">
              <Link to={ path + "/terms-and-conditions" }
                className="Footer-nav-link"
                role="navigation"
                aria-labelledby="footer.nav.termslink"
              >
                <FormattedMessage
                  id="footer.nav.termslink"
                  defaultMessage="Terms and Conditions"
                >
                  { (message) => <span id="footer.nav.termslink">{message}</span> }
                </FormattedMessage>
              </Link>
            </div>

            <div className="Footer-link">
              <Link to={ path + "/account-center#help-and-support" }
                className="Footer-nav-link"
                role="navigation"
                aria-labelledby="footer.nav.supportlink"
              >
                <FormattedMessage
                  id="footer.nav.supportlink"
                  defaultMessage="Help and Support"
                >
                  { (message) => <span id="footer.nav.supportlink">{message}</span> }
                </FormattedMessage>
              </Link>
            </div>

            {
              ["en-us", "fr-ca"].indexOf( lang ) >= 0
              &&
              <div className="Footer-link">
                {/* only show this footer item if the language selected is en-us or fr-ca */}
                <Link to={ path + "/data-management" }
                  className="Footer-nav-link"
                  role="navigation"
                  aria-label="Do not sell my personal information"
                >
                  <FormattedMessage
                    id="footer.nav.data.management"
                    defaultMessage="Do Not Sell My Personal Information"
                  >
                    { (message) => <span id="footer.nav.data.management">{message}</span> }
                  </FormattedMessage>
                </Link>
              </div>
            }

            { this.props.cookies.trustarcLoaded
              &&
              <div className="Footer-link"
              >
                <button className="Footer-cookiePreferences"
                  onClick={ this.clickCookiePreferences }
                  role="navigation"
                  aria-labelledby="footer.nav.cookie.preferences"
                >
                  <FormattedMessage
                    id="footer.nav.cookie.preferences"
                    defaultMessage="Cookie preferences"
                  >
                    { (message) => <span id="footer.nav.cookie.preferences">{message}</span> }
                  </FormattedMessage>
                </button>
              </div>
            }

          </div>
        </div>

      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    cookies: state.cookies,
  };
};

Footer.propTypes = {
  lang: PropTypes.string.isRequired,
};

export default connect( mapStateToProps )( Footer );
