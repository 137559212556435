import React from 'react'
import { withRouter } from 'react-router-dom'
import CheckoutTopNav from './CheckoutTopNav'
import './Checkout.scss'

const CheckoutSuccess = ({ match: { params: { domain, subscriptionId }}}) => {
	return (
		<div className="Checkout CheckoutSuccess">
			{
				domain && <CheckoutTopNav domain={domain} />
			}
			<div className="CheckoutSuccess-container">
				<h2 className="CheckoutSuccess-h2">
					Checkout Success!
				</h2>
				<p>Your subscription ID is {subscriptionId}.</p>
			</div>
		</div>
	)
}

export default withRouter(CheckoutSuccess)
