import React from 'react'
import './CheckoutTopNav.scss'

const CheckoutTopNav = ({ domain, avatarUrl }) => {
  const domainLogos = {
    MMF: {
      alt: 'MapMyFitness Logo',
      url: 'https://mapmy.uastatic.com/7dfe83d20b3001ffe9a78f4126fa1f92.svg',
    },
    MFP: {
      alt: 'MyFitnessPal Logo',
      url: '/img/logo_myfitnesspal.svg',
    },
  }
  return (
    <div className="CheckoutTopNav">
      <div className="CheckoutTopNav-container">
        <img
          className={`CheckoutTopNav-logo CheckoutTopNav-logo--${domain.toUpperCase()}`}
          src={domainLogos[domain.toUpperCase()].url}
          alt={domainLogos[domain.toUpperCase()].alt}
        />
        {
          avatarUrl && <img className="CheckoutTopNav-avatar" src={avatarUrl} alt="Avatar" />
        }
      </div>
    </div>
  )
}

export default CheckoutTopNav
