// EU1 Countries by ID
const EU1_COUNTRIES = [
  'AT', 'CH', 'CN', 'CY', 'DE', 'ES', 'FR', 'HK', 'IT', 'KR',
  'PL', 'RO', 'RU'
];

// EU2 Countries by ID
const EU2_COUNTRIES = [
  'AL', 'AR', 'AU', 'BA', 'BE', 'BG', 'BR', 'CA', 'CL', 'CZ',
  'DK', 'EE', 'FI', 'FO', 'GB', 'GG', 'GI', 'GR', 'HR', 'HU',
  'IE', 'IL', 'IS', 'JE', 'JP', 'LI', 'LT', 'LU', 'LV', 'MC',
  'MD', 'MK', 'MT', 'MX', 'MY', 'NL', 'NO', 'PT', 'RS', 'SE',
  'SG', 'SI', 'SK', 'XK'
];

// languages
// base: property refers to the base language for the language-country code combo
// root: property to signal that this locale-country code is the default if only language is supplied
//  ex: 'zh' or 'zh-xx' should resolve to 'zh-cn', as it's base is 'zh' and it is configured as root
const LANGUAGES_SUPPORTED = [
  { id: 'da-dk', abbr: 'Danish', name: 'Dansk', full: 'Danish', base: 'da', root: true },
  { id: 'de-de', abbr: 'German', name: 'Deutsche', full: 'German', base: 'de', root: true },
  { id: 'en-us', abbr: 'Eng', name: 'English', full: 'English', base: 'en', root: true, isDefault: true },
  { id: 'es-es', abbr: 'Esp', name: 'Español (España)', full: 'Spanish (Spain)', base: 'es', root: true },
  { id: 'es-mx', abbr: 'Esp', name: 'Español (México)', full: 'Spanish (Mexico)', base: 'es', root: false },
  { id: 'fr-ca', abbr: 'French', name: 'Français (Canada)', full: 'French (Canada)', base: 'fr', root: false },
  { id: 'fr-fr', abbr: 'French', name: 'Français (France)', full: 'French (France)', base: 'fr', root: true },
  { id: 'id-id', abbr: 'Indonesian', name: 'Bahasa Indonesia', full: 'Indonesian', base: 'id', root: true },
  { id: 'it-it', abbr: 'Italian', name: 'Italiano', full: 'Italian', base: 'it', root: true },
  { id: 'ja-jp', abbr: 'Japanese', name: '日本語', full: 'Japanese', base: 'ja', root: true },
  { id: 'ko-kr', abbr: 'Korean', name: '한국어', full: 'Korean', base: 'ko', root: true },
  { id: 'ms-my', abbr: 'Malay', name: 'Bahasa Melayu', full: 'Malay', base: 'ms', root: true },
  // nb-no Norwegian goes here
  { id: 'nl-nl', abbr: 'Dutch', name: 'Nederlands', full: 'Dutch', base: 'nl', root: true },
  { id: 'pl-pl', abbr: 'Polish', name: 'Polski', full: 'Polish', base: 'pl', root: true },
  { id: 'pt-br', abbr: 'Portuguese', name: 'Português (Brasil)', full: 'Portuguese (Brazil)', base: 'pt', root: true },
  { id: 'pt-pt', abbr: 'Portuguese', name: 'Português (Portugal)', full: 'Portuguese (Portugal)', base: 'pt', root: false },
  { id: 'ru-ru', abbr: 'Russian', name: 'Русский', full: 'Russian', base: 'ru', root: true },
  { id: 'sv-se', abbr: 'Swedish', name: 'Svenska', full: 'Swedish', base: 'sv', root: true },
  { id: 'th-th', abbr: 'Thai', name: 'ภาษาไทย', full: 'Thai', base: 'th', root: true },
  { id: 'tl-ph', abbr: 'Tagalog', name: 'Tagalog', full: 'Tagalog', base: 'tl', root: true },
  { id: 'tr-tr', abbr: 'Turkish', name: 'Türkçe', full: 'Turkish', base: 'tr', root: true },
  { id: 'zh-cn', abbr: 'Chinese', name: '中文', full: 'Chinese Simplified', base: 'zh', root: true },
  { id: 'zh-tw', abbr: 'Chinese', name: '中文(台灣)‬', full: 'Chinese Traditional', base: 'zh', root: false },
];

const sortLanguagesByFull = (a, b) => {
  // call by doing: LANGUAGES_SUPPORTED.sort( sortLanguagesByFull )
  // sort by "full"
  const x = a.full.toLowerCase();
  const y = b.full.toLowerCase();
  if ( x < y ) { return -1; };
  if ( x > y ) { return 1; };
  return 0;
}

const normalizeLanguageCode = (languageCode) => {
  return languageCode.replace('_', '-').toLowerCase();
};

/**
 * Find out country code from language tag:
 * @param  {string} languageTag ex: en_US, en-us, en
 * @return {string} countryCode ex: us
 */
const findCountryCode = (languageId) => {
  const normalized = normalizeLanguageCode(languageId);
  return normalized.split('-')[1];
};

const isEU1 = (languageCode) => {
  return Boolean(EU1_COUNTRIES.find((code) => {
    return code.toLowerCase() === findCountryCode(languageCode);
  }));
};

const isEU2 = (languageCode) => {
  return Boolean(EU2_COUNTRIES.find((code) => {
    return code.toLowerCase() === findCountryCode(languageCode);
  }));
};

const getSupportedLanguages = (includePseudo = true) => {
  if (includePseudo === false) {
    return LANGUAGES_SUPPORTED.sort( sortLanguagesByFull ).filter( (language) => ! language.pseudo );
  }
  return LANGUAGES_SUPPORTED.sort( sortLanguagesByFull );
};

const getDefaultLanguage = () => {
  return getSupportedLanguages().find((language) => language.isDefault === true);
};

const getPseudoLanguage = () => {
  return getSupportedLanguages().find((language) => language.pseudo === true);
};

const findLanguageByCode = (languageCode = 'en-us') => {

  const normalizedCode = normalizeLanguageCode(languageCode);
  const fourLetterMatch = getSupportedLanguages().find(lang => lang.id === normalizedCode);
  if (fourLetterMatch) { return fourLetterMatch; }

  const baseCode = normalizedCode.split('-')[0];
  const twoLetterMatch = getSupportedLanguages().find(lang => lang.base === baseCode && lang.root === true);
  if (twoLetterMatch) { return twoLetterMatch; }

  return getDefaultLanguage()
};

module.exports = {
  EU1_COUNTRIES,
  EU2_COUNTRIES,
  LANGUAGES_SUPPORTED,

  normalizeLanguageCode,
  isEU1,
  isEU2,
  getSupportedLanguages,
  getDefaultLanguage,
  getPseudoLanguage,
  findLanguageByCode,
};
