import React, { Component } from 'react';
import {Route, withRouter} from 'react-router-dom';
import classNames from 'classnames';
import './SideNav.scss';

class SideNav extends Component {
  handleClick(to) {
    // Had to do this in order to prevent reload on link click
    // Seems like a react bug with Link
    this.props.history.push(to);
  }
  mapPages(lang, pages) {
    // This function is used to generate the sidenav links that React can understand
    // The information is provided by the API
    return pages.map(page => {
      const split = page.namespace.split('#');
      const pathname = `/${lang}/${split[0]}`;
      const hash = split[1];
      let obj = {
        to:{
          pathname,
          hash,
        },
        name: page.text,
        children: []
      };
      obj.children = page.subNav.map(nav => {
        const split = nav.namespace.split('#');
        const pathname = `/${lang}/${split[0]}`;
        const hash = split[1];
        return {
          to: {
            pathname,
            hash,
          },
          name: nav.text,
        };
      });
      return obj;
    })
  }
  isActive(linkPathname, linkHash) {
    const { pathname, hash } = this.props.location;
    if(pathname === linkPathname && !linkHash) {
      return true;
    }
    if(pathname === linkPathname && hash === `#${linkHash}`) {
      return true;
    }
    return false;
  }
  makeLinks(lang, links) {
    return (
      <ul className="SideNav-LinkList">
        {links.map((link, key) => (
          <li key={key} className={classNames("SideNav-LinkList-item",{"is-active":this.isActive(link.to.pathname, link.to.hash)})}>
            <a className="SideNav-LinkList-link" href={`#${link.to.hash || ''}`} onClick={() => this.handleClick(link.to)}>{link.name}</a>
            {!!link.children && !!link.children.length && (
              <Route path={link.to.pathname || link.to} render={() => this.makeLinks(lang, link.children)} />
            )}
          </li>
        ))}
      </ul>
    )
  }
  render() {
    const { lang, pages } = this.props;
    const links = this.mapPages(lang, pages);
    return (
      <div className="SideNav">
        {this.makeLinks(lang, links)}
      </div>
    );
  }
}

export default withRouter(SideNav);
