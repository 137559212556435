import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import queryString from 'query-string'

import { Link, Button, ErrorMessage, InputField, Redirect, Title } from '../_Common';
import historyPush from '../../utils/historyPush';

import * as authActions from  '../../services/auth/actions';
import * as loginActions from '../../services/login/actions';
import * as userActions from  '../../services/user/actions';

import './ForgotPassword.scss';

class ForgotPassword extends Component {

  refUsername = React.createRef();
  queryParams = ""

  state = {
    error: "", // text error message to display
    errorActive: false,  // true to display the error box
    emailSentTo: "",  // which email address the user hit "submit" reset password on. This is separate from this.props.login.username because we want to lock the email address, preventing the user from changing it in another /login window
    passwordResetSuccess: false,  // sets to true when the reset password has succeeded, changes the page to say it succeeded
  };



  componentDidMount() {
    const values = queryString.parse(this.props.location.search);
    this.queryParams = `response_type=${values.response_type}&redirect_uri=${values.redirect_uri}&state=${values.state}&client_id=${values.client_id}`
    this.isInOAuthFlow = !!values.client_id
    // this.refUsername.current.focus();
  }

  enterKeyPress = () => {
    // ENTER key press while typing on the username
    const usernameHasValue = ( ! new RegExp("^\\s*$").test( this.props.login.username ) );  // true/false
    if ( usernameHasValue ) {
      // user pressed enter after typing a username
      if ( this.state.loading || this.state.errorActive ) {
        // the form is currently loading or in an error state. Don't submit the form
        return;
      }
      this.refUsername.current.blur();
      this.forgotPassword();
    }
  }

  forgotPassword = () => {
    const { auth } = this.props.actions;
    const redirectUri = this.isInOAuthFlow ? `/oauth/authorize?${this.queryParams}` : null;

    auth.postForgotPassword( this.props.login.username, redirectUri );
    this.setState({ emailSentTo: this.props.login.username });
  };

  handleReturnToLogin = () => {
    // Used to determine if we should return to the OAuth login page instead of
    // the normal local login page
    if (this.props.login.loginPath) {
      this.props.actions.login.unsetLoginPath() //unset it in case user switches login pages
      historyPush( this.props.history, this.props.match, this.props.login.loginPath,this.queryParams )
    } else {
      historyPush( this.props.history, this.props.match, "login")
    }
  }

  componentDidUpdate( prevProps, prevState ) {
    // if the user's postResetPassword state goes from loading to success, show the success message
    if ( prevProps.auth.postForgotPassword.loading && !prevProps.auth.postForgotPassword.success ) {
      if ( this.props.auth.postForgotPassword.success ) {
        // user went from loading to success, so the form submit must have been a success
        this.setState({ passwordResetSuccess: true });
      }
    }
  }

  /*******************************/
  render() {
    const { user } = this.props.user;
    const { accessToken, postForgotPassword } = this.props.auth;

    const { username } = this.props.login;

    if ( user && accessToken ) {
      return (
        <Redirect to="account" />
      )
    }

    const width = (
      this.props.screen.width - 20 < 350
      ? this.props.screen.width - 20    // small width
      : 350                             // large width
    );

    const forgotBoxTopMargin = 40;

    const usernameHasValue = ( ! new RegExp("^\\s*$").test( username ) );  // true/false

    const buttonEnabled = (
      usernameHasValue &&
      (! this.state.errorActive ) &&
      (! postForgotPassword.loading )
    );

    return (
      <div className="ForgotPassword"
        style={{ paddingTop: forgotBoxTopMargin }}
      >

        { ( ! this.state.passwordResetSuccess
            &&
            <div className="ForgotPassword-container"
              style={{ width }}
            >

              <Title>
                <FormattedMessage
                  id="resetPassword.title"
                  defaultMessage="Password Reset"
                />
              </Title>

              <ErrorMessage
                message={ this.state.error }
                width={ width }
                errorHeight={ 39 }
                noErrorHeight={ 10 }
              />

              <div className="ForgotPassword-form"
                style={{ width }}
              >
                <div className="ForgotPassword-input">
                  <InputField
                    inputRef={ this.refUsername }
                    name="username"
                    type="email"
                    label={
                      <FormattedMessage
                        id="option.email"
                        defaultMessage="Email"
                      />
                    }
                    width={ width }
                    height={ 51 }
                    fontSize={ 16 }
                    value={ username }
                    setValue={ (username) => this.props.actions.login.setUsername( username ) }
                    clearError={ () => this.setState({ errorActive: false }) }
                    autoComplete={ false }
                    autoCorrect={ false }
                    autoCapitalize={ false }
                    disallowSpaces={ true }
                    disallowEmoji={ true }
                    onEnter={ this.enterKeyPress }
                  />

                </div>

                {/* reset password button */}
                <div className="ForgotPassword-button">
                  <Button
                    text={
                      <FormattedMessage
                        id="resetPassword.reset"
                        defaultMessage="Reset"
                        children={
                          ( formattedMessage => formattedMessage.toLocaleUpperCase() )
                        }
                      />
                    }
                    width={ width }
                    click={ this.forgotPassword }
                    disabled={ ! buttonEnabled }
                    loading={ postForgotPassword.loading }
                  />
                </div>
                {/* go back button */}

                <Link
                  onClick={this.handleReturnToLogin}
                  text={
                    <FormattedMessage
                      id="resetPassword.resetPassword.return.to.login"
                      defaultMessage="Return to Login"
                    />
                  }
                />

                {/* {postForgotPassword.loading && "Loading..."}
                {postForgotPassword.success && "Success! Check email."}
                {postForgotPassword.failure && "Something went wrong, verify email is correct."} */}
              </div>
            </div>
          )
          ||
          <div className="ForgotPassword-container"
            style={{ width }}
          >
            {/* SUCCESS, password reset email sent */}

            <Title>
              <FormattedMessage
                id="resetPassword.resetPassword.return.to.login"
                defaultMessage="RETURN TO LOGIN"
                children={
                  ( formattedMessage => ( formattedMessage.toLocaleUpperCase() ) )  // uppercase the phrase "return to login" for all languages
                }
              />
            </Title>

            <div className="ForgotPassword-message">
              <FormattedMessage
                id="resetPassword.submitted.message"
                defaultMessage="If an Under Armour account exists for email@example.com, you will receive an email with a link to reset your password."
                children={
                  formattedMessage => formattedMessage.replace("email@example.com", this.state.emailSentTo)
                  // email@example.com is replaced no matter where it appears within the localized text
                }
              />
            </div>

            <div className="ForgotPassword-button">
              <Button
                text={
                  <FormattedMessage
                    id="resetPassword.resetPassword.return.to.login"
                    defaultMessage="RETURN TO LOGIN"
                    children={
                      ( formattedMessage => ( formattedMessage.toLocaleUpperCase() ) )  // uppercase the phrase "return to login" for all languages
                    }
                  />
                }
                width={ width }
                click={ this.handleReturnToLogin }
              />
            </div>

          </div>
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth  : state.auth,
    locale: state.locale,
    login : state.login,  // for the username entry, linked with /login and /forgot
    screen: state.screen,
    user  : state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      auth : bindActionCreators( authActions, dispatch ),
      login: bindActionCreators( loginActions, dispatch ),
      user : bindActionCreators( userActions, dispatch ),
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)( ForgotPassword );
