// sets the login username, carries over to reset password

import { 
  LOGIN_USERNAME,
  LOGIN_PATH
 } from './constants';
import createReducer from '../createReducer';

// initial state, empty string
const initialState = { 
  username: "",  
  loginPath: null
};

export default createReducer( initialState, {
  [ LOGIN_USERNAME ]: ( state, action ) => {
    return {
      ...state,
      ...action.payload,
      // why the "...action.payload"??? Because we want to pass in just the {username: value} object, the rootReducer will prefix it with "login: {username: value}"
    }
  },
  [ LOGIN_PATH ]: ( state, action ) => {
    return {
      ...state,
      ...action.payload,
    }
  }
});
