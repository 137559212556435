import  React , { useRef, useState, useReducer } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { BlueLink, Button, ErrorMessage, InputField, Title } from "../_Common";
import {
  postOAuthRegister as postOAuthRegisterAction
} from  '../../services/oauthLogin/actions'
import { POST_OAUTH_REGISTER_SUCCESS, POST_OAUTH_REGISTER_FAILURE } from '../../services/oauthLogin/constants'
import queryString from 'query-string';

import './OAuthRegistration.scss'

const OAuthRegistration = (props) => {
  const {
    postOAuthRegister,
    screen,
    location: { search = {} }
  } = props;

  const {response_type,state,redirect_uri: delegatingRedirectUri, client_id: delegatingClientId } = queryString.parse(search)
  const queryParams = `response_type=${response_type}&redirect_uri=${delegatingRedirectUri}&state=${state}&client_id=${delegatingClientId}`

  const initialState = {
    email: '',
    password: '',
    firstName: ''
  };

  function reducer(formState, action) {
    return Object.assign({}, formState, {
      [action.key]: action.value
    })
  }

  const [isPasswordVisible, setIsPasswordVisible] = useState(false)
  const [formState, handleFieldChange] = useReducer(reducer, initialState);
  const [errorMessage, setErrorMessage] = useState('');
  const refEmail = useRef(null)
  const refPassword = useRef(null)
  const refFirstName = useRef(null)

  const isFormFilledOut = !!formState.email && !!formState.password && !!formState.firstName

  const PAGE_GUTTER_SIZE_PX = 20
  const LOGIN_COMPONENTS_MAX_WIDTH_PX = 350
  const formWidth = screen.width - PAGE_GUTTER_SIZE_PX < LOGIN_COMPONENTS_MAX_WIDTH_PX
    ? screen.width - PAGE_GUTTER_SIZE_PX
    : LOGIN_COMPONENTS_MAX_WIDTH_PX

  const MAX_MOBILE_PAGE_HEIGHT_PX = 490
  const ONE_THIRD = 1/3
  const formBoxTopMargin = screen.height > MAX_MOBILE_PAGE_HEIGHT_PX
    ? (screen.height - MAX_MOBILE_PAGE_HEIGHT_PX) * ONE_THIRD
    : 0

  const handleToggleShowPassword = () => {
    setIsPasswordVisible(!isPasswordVisible)
  }

  const handleSubmit = () => {
    postOAuthRegister({
      delegatingClientId,
      delegatingRedirectUri,
      ...formState
    })
      .then(handleRegisterResult)
      .catch(handleRegisterError)
  }

  const handleRegisterResult = action => {
    if (action.type === POST_OAUTH_REGISTER_SUCCESS) {
      const code = action.payload.data.result.code;
      window.location = `${delegatingRedirectUri}?code=${code}`
    } else if (action.type === POST_OAUTH_REGISTER_FAILURE) {
      handleRegisterError(action.error);
    }
  }

  const handleRegisterError = (error) => {
    setErrorMessage('Something went wrong')

    if (error.response) {
      const statusCode = error.response.status;
      const responseBody = error.response.data;

      console.log(`Error received: ${statusCode}: `, responseBody)
    }
  }

  if(!delegatingClientId || !delegatingRedirectUri) {
    return (
      <div className="OAuthRegistration" style={{ paddingTop: formBoxTopMargin }}>
        <ErrorMessage
          message='Invalid request - client id and redirect uri are required'
          width={formWidth}
          errorHeight={39}
          noErrorHeight={10}
        />
      </div>
    )
  }

  return (
    <div className="OAuthRegistration" style={{ paddingTop: formBoxTopMargin }}>
      <div className="OAuthRegistration-container">
        <div style={{
            position:'absolute',
            margin:'20px',
            bottom: '0',
            left: '0',
            width:'600px',
            color: '#ed7e7e',
            fontSize: '14px' }}>
          This page is not a finished product and is available for integration test purposes only
        </div>
        <Title>
          <FormattedMessage
            id="oauth.login.register.register"
            defaultMessage="REGISTER"
            children={formattedMessage => formattedMessage.toLocaleUpperCase()}
          />
        </Title>
        <ErrorMessage
          message={ errorMessage }
          width={formWidth}
          errorHeight={39}
          noErrorHeight={10}
        />
        <div className="OAuthRegistration-form">

          <div className="OAuthRegistration-input">
            <InputField
              name="email"
              type="email"
              label={<FormattedMessage id="oauth.login.register.email" defaultMessage="Email" />}
              value={formState.email}
              setValue={ (value) => handleFieldChange({key: 'email', value }) }
              width={formWidth}
              height={51}
              fontSize={16}
              clearError={() => {}}
              autoComplete={false}
              autoCorrect={false}
              autoCapitalize={false}
              disallowSpaces={true}
              disallowEmoji={true}
              inputRef={refEmail}
            />
          </div>
          <div className="OAuthRegistration-input">
            <InputField
              name="password"
              type={isPasswordVisible ? 'text' : 'password'}
              label={<FormattedMessage id="oauth.login.register.password" defaultMessage="Password" />}
              value={formState.password}
              setValue={ (value) => handleFieldChange({key: 'password', value }) }
              width={formWidth}
              height={51}
              fontSize={16}
              clearError={() => {}}
              autoComplete={false}
              autoCorrect={false}
              autoCapitalize={false}
              disallowSpaces={false}
              disallowEmoji={true}
              endAdornmentText={
                isPasswordVisible
                ? <FormattedMessage id="resetPassword.password.hide" defaultMessage="HIDE" />
                : <FormattedMessage id="resetPassword.password.show" defaultMessage="SHOW" />
              }
              endAdornmentClick={handleToggleShowPassword}
              inputRef={refPassword}
            />
          </div>
          <div className="OAuthRegistration-input">
            <InputField
              name="firstName"
              type="text"
              label={<FormattedMessage id="oauth.login.register.firstName" defaultMessage="First Name" />}
              value={formState.firstName}
              width={formWidth}
              height={51}
              fontSize={16}
              setValue={ (value) => handleFieldChange({key: 'firstName', value }) }
              inputRef={refFirstName}
            />
          </div>
          <Button
            text={<FormattedMessage id="oauth.login.register.createAccount" defaultMessage="Create Account" />}
            width={280}
            click={ handleSubmit }
            disabled={!isFormFilledOut} />
        </div>
        <br />
        <BlueLink
        to="oauth/login"
        location={queryParams}>
          <FormattedMessage id="oauth.login.register.alreadyHaveAnAccount" defaultMessage="Already have an account?" />
        </BlueLink>
      </div>
    </div>
  );
};

const mapDispatchToProps = {
  postOAuthRegister: postOAuthRegisterAction
};

const mapStateToProps = state => ({
  screen: state.screen
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OAuthRegistration))
