import amplitude from 'amplitude-js';

// const AMPLITUDE_API_KEY = "61f93f37cac05b1d7acc1aa78f3f9d31"; //TEMP replace with environment variable

export const initAmplitude = (amplitudeApiKey) => {
  // for docs on init, see these two pages:
  // section 4 on here: https://help.amplitude.com/hc/en-us/articles/115002889587-JavaScript-SDK-Reference
  // and setting configuration options section on here: https://developers.amplitude.com/docs/advanced-settings
  amplitude.getInstance().init(
    amplitudeApiKey,  // api key, defined in the server environment variables and passed to the client through the config controller
    null,  // user id, we have none, for now
    { // options:
      includeReferrer: true,
      includeUtm: true,
      // unsetParamsReferrerOnNewSession: true,  // improved accuracy of where users navigate from
      saveParamsReferrerOncePerSession: false,
    }
  );
};

export const setAmplitudeUserDevice = installationToken => {
  amplitude.getInstance().setDeviceId(installationToken);
};

export const setAmplitudeUserProperties = properties => {
  amplitude.getInstance().setUserProperties(properties);
};

export const sendAmplitudeData = (eventType, eventProperties) => {
  // console.log("Sending amplitude data");
  amplitude.getInstance().logEvent(eventType, eventProperties);
};

// placeholder:
// export const setAmplitudeUserId = userId => {
//   amplitude.getInstance().setUserId(userId);
// };

export const sendAmplitudeScreenView = ( screen_name ) => {
  amplitude.getInstance().logEvent(
    "screen_viewed",  // for this function, always send eventType="screen_viewed"
    {
      screen_name,  // eventProperty of screen_name, the unique string of the screen being viewed on the Account Portal
    }
  )
}
