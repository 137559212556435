import React, { useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl'
import classNames from 'classnames'
import {
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement,
  PostalCodeElement,
  injectStripe,
} from 'react-stripe-elements'
import { checkoutActions } from './constants'
import {
  addCard,
  prepareToCapture,
  completeTransaction,
  logHandleSubmit as logHandleSubmitAction,
  logPaymentFailure as logPaymentFailureAction,
  logPurchaseSuccess as logPurchaseSuccessAction,
  logCouponSuccess as logCouponSuccessAction,
} from '../../services/checkout/actions'
import { logStat as logStatistic } from '../../services/analytics/actions'
import './CardSwipeForm.scss'

const ErrorSummary = ({ message }) => {
  if (!message) { return null }
  return (
    <div className="ErrorSummary">
      <div className="ErrorSummary-icon">
        !
      </div>
      <div className="ErrorSummary-content">
        <span className="ErrorSummary-heading">
          <FormattedMessage id="checkout.cardForm.error.heading" defaultMessage="Oops, something went wrong." />
        </span>
        <ul>
          <li>{message}</li>
        </ul>
      </div>
    </div>
  )
}

const formSubmitButtonCopy = (isProcessingPayment, checkoutAction) => {
  if (isProcessingPayment) return <FormattedMessage id="checkout.cardForm.ctaSubmitIsLoading" defaultMessage="PLEASE WAIT" />
  return checkoutAction === checkoutActions.ADD_NEW_CARD
    ? <FormattedMessage id="checkout.cardForm.ctaSubmitAddCard" defaultMessage="ADD CARD" />
    : <FormattedMessage id="checkout.cardForm.ctaSubmit" defaultMessage="PLACE ORDER" />
}

const TermsAndConditions = ({ lang, customerSupportUrl, domain, mfpRedirectUrl }) => {
  const paidServicesTermsUrl = domain === 'MFP' ? `${mfpRedirectUrl}/privacy-and-terms` : `https://${window.location.hostname}/${lang}/terms-and-conditions#7-paid-services`;
  
  return (
    <div className="TermsAndConditions">
      <p>
        <FormattedHTMLMessage
          id="checkout.cardForm.terms.iHaveRead"
          defaultMessage="By continuing, I confirm that I have read and agreed to the specific terms and conditions applicable to <a target='_blank' rel='noopener noreferrer' href='{href}'>Paid Services</a> set out in the Terms and Conditions of Use."
          values={{ href: paidServicesTermsUrl }}
        />
      </p>
      <p>
        <FormattedHTMLMessage
          id="checkout.cardForm.terms.agreeToAutoRenew"
          defaultMessage="Subscribers agree to the auto-renewal provisions applicable to Premium Services. We do not offer refunds or exchanges. Read the applicable <a target='_blank' rel='noopener noreferrer' href='{href}'>terms and conditions</a>."
          values={{ href: paidServicesTermsUrl }}
        />
      </p>
      <p>
        <FormattedHTMLMessage
          id="checkout.cardForm.terms.contactCustomerSupport"
          defaultMessage="If you experience any problems, please contact <a target='_blank' rel='noopener noreferrer' href={href}>customer support</a>."
          values={{ href: customerSupportUrl }}
        />
      </p>
    </div>
  )
}

const stripeElementCustomizations = {
  style: {
    base: {
      color: '#000000',
      fontFamily: '\'Armour\', Helvetica, sans-serif',
      fontSize: '16px',
      padding: '60px',
      '::placeholder': {
        color: 'transparent',
      },
      ':focus::placeholder': {
        color: '#9e9e9e'
      },
    },
    invalid: {
      color: '#f22613',
    },
  },
}

function CardSwipeForm (props) {

  const [errorMessage, setErrorMessage] = useState('')
  const [isProcessingPayment, setIsProcessingPayment] = useState(false)
  const [nameOnCard, setNameOnCard] = useState('')
  const [elementWithFocus, setElementWithFocus] = useState(null)

  const { stripe, add, prepare, complete, logStat, logHandleSubmit, logPaymentFailure, logCouponSuccess, logPurchaseSuccess } = props
  const { match: { params: { lang, domain }}} = props
  const {
    checkoutAction,
    productId,
    promoCode,
    paymentIntentId,
    totalAmount,
    currency,
    countryCode,
    paymentIntentClientSecret,
    domainUserId,
    successPageUrl,
    customerSupportUrl,
    mfpRedirectUrl
  } = props

  function handleNameOnCardChange (event) {
    setNameOnCard(event.target.value)
  }

  function hasInvalidNameOnCard () {
    // Ensure nameOnCard is something other than whitespace
    return !/\w+/.test(nameOnCard)
  }

  function handleSubmit () {
    setErrorMessage('')
    setIsProcessingPayment(true)
    logHandleSubmit(props)
    if (hasInvalidNameOnCard()) {
      setErrorMessage(<FormattedMessage id="checkout.cardForm.error.cardholderNameIsRequired" defaultMessage="The name on your card is required." />)
      setIsProcessingPayment(false)
      logPaymentFailure({ error: 'Invalid name on card', ...props })
      return
    }
    const sourceData = {
      type: 'card',
      owner: {
        name: nameOnCard,
      },
    }
    stripe.createSource(sourceData)
      .then(createSourceResponse => {
        if (createSourceResponse.error && createSourceResponse.error.type === 'validation_error') { 
          logStat('cardSwipe/createSourceValidationError')
          setErrorMessage(createSourceResponse.error.message)
          setIsProcessingPayment(false)
          logPaymentFailure({ error: 'Create source validation error', ...props })
          return
        }
        if (createSourceResponse.error) { 
          logStat('cardSwipe/createSourceError')
          setErrorMessage(<FormattedMessage id="checkout.cardForm.error.haveNotBeenCharged" defaultMessage="The payment request failed. You have not yet been charged. Please check your payment method and try again, or contact customer support for assistance." />)
          setIsProcessingPayment(false)
          logPaymentFailure({ error: 'Create source unknown error', ...props })
          return
        }
        const { source: { id: sourceId, card: { brand } } } = createSourceResponse
        checkoutAction === checkoutActions.ADD_NEW_CARD
          ? continueWithAdd(sourceId, brand)
          : continueWithPurchase(sourceId, brand)
      })
      .catch(() => {
        logStat('cardSwipe/createSourceException')
        setErrorMessage(<FormattedMessage id="checkout.cardForm.error.haveNotBeenCharged" defaultMessage="The payment request failed. You have not yet been charged. Please check your payment method and try again, or contact customer support for assistance." />)
        setIsProcessingPayment(false)
        logPaymentFailure({ error: 'Create source exception', ...props })
      })
  }

  function continueWithAdd (sourceId, brand) {
    add(domain, domainUserId, sourceId, brand, currency)
      .then(addResult => {
        if (addResult.payload && addResult.payload.data.success === true) {
          window.location = successPageUrl
        } else {
          logStat('cardSwipe/addError')
          setErrorMessage(<FormattedMessage id="checkout.cardForm.error.addingCardFailed" defaultMessage="Adding your card failed. Please check your payment method and try again, or contact customer support for assistance." />)
          setIsProcessingPayment(false)
          logPaymentFailure({ error: 'Add card failed', ...props })
        }
      })
      .catch(() => {
        logStat('cardSwipe/addException')
        setErrorMessage(<FormattedMessage id="checkout.cardForm.error.addingCardFailed" defaultMessage="Adding your card failed. Please check your payment method and try again, or contact customer support for assistance." />)
        setIsProcessingPayment(false)
        logPaymentFailure({ error: 'Add card exception', ...props })
      })
  }

  function continueWithPurchase (sourceId, cardBrand) {
    prepare(domain, domainUserId, paymentIntentId, sourceId, cardBrand, currency)
      .then(prepareResult => {
        if (prepareResult.payload && prepareResult.payload.data.success === true) {
          if (totalAmount === "0") { // Free Trial
            completePurchase(prepareResult)
          } else {
            chargeCardAndCompletePurchase(sourceId, prepareResult)
          }
        } else if (prepareResult.error && prepareResult.error.response && prepareResult.error.response.data) {
          if (prepareResult.error.response.data.error === 'Unsupported card for the given currency') {
            logStat('cardSwipe/prepareError')
            setErrorMessage(<FormattedMessage id="checkout.cardForm.error.cardBrandNotSupported" defaultMessage="Your card brand is not currently supported. Please select a different method of payment and try again." />)
            setIsProcessingPayment(false)
            logPaymentFailure({ error: prepareResult.error.response.data.error, ...props })
          } else {
            logStat('cardSwipe/prepareError')
            setErrorMessage(<FormattedMessage id="checkout.cardForm.error.haveNotBeenCharged" defaultMessage="The payment request failed. You have not yet been charged. Please check your payment method and try again, or contact customer support for assistance." />)
            setIsProcessingPayment(false)
            logPaymentFailure({ error: 'Prepare for payment error', ...props })
          }
        } else {
          logStat('cardSwipe/prepareError')
          setErrorMessage(<FormattedMessage id="checkout.cardForm.error.haveNotBeenCharged" defaultMessage="The payment request failed. You have not yet been charged. Please check your payment method and try again, or contact customer support for assistance." />)
          setIsProcessingPayment(false)
          logPaymentFailure({ error: 'Prepare for payment error', ...props })
        }
      })
      .catch(() => {
        logStat('cardSwipe/prepareException')
        setErrorMessage(<FormattedMessage id="checkout.cardForm.error.haveNotBeenCharged" defaultMessage="The payment request failed. You have not yet been charged. Please check your payment method and try again, or contact customer support for assistance." />)
        setIsProcessingPayment(false)
        logPaymentFailure({ error: 'Prepare for payment exception', ...props })
      })
  }

  function chargeCardAndCompletePurchase (sourceId, prepareResult) {
    stripe.handleCardPayment(
      paymentIntentClientSecret,
      { payment_method: sourceId }
    ).then(handleCardPaymentResult => {
      if (handleCardPaymentResult.error) {
        logStat('cardSwipe/handleCardPaymentError')
        setErrorMessage(handleCardPaymentResult.error.message)
        setIsProcessingPayment(false)
        logPaymentFailure({ error: 'Handle card payment error', ...props })
        return
      }
      completePurchase(prepareResult)
    })
    .catch(() => {
      logStat('cardSwipe/handleCardPaymentException')
      setErrorMessage(<FormattedMessage id="checkout.cardForm.error.mayHaveBeenCharged" defaultMessage="You may have been charged, but the transaction failed to complete. Please contact customer support for assistance." />)
      setIsProcessingPayment(false)
      logPaymentFailure({ error: 'Handle card payment exception', ...props })
    })
  }

  function completePurchase (prepareResult) {
    const { billingProfileId, paymentIntentId } = prepareResult.payload.data
    complete(domain, domainUserId, productId, promoCode, countryCode, billingProfileId, paymentIntentId)
      .then(completeResult => {
        if (completeResult.error) {
          logStat('cardSwipe/completeError')
          setErrorMessage(promoCode
            ? <FormattedMessage id="checkout.cardForm.error.haveNotBeenCharged" defaultMessage="The payment request failed. You have not yet been charged. Please check your payment method and try again, or contact customer support for assistance." />
            : <FormattedMessage id="checkout.cardForm.error.haveBeenCharged" defaultMessage="You have been charged, but the transaction failed to complete. Please contact customer support for assistance." />)
          setIsProcessingPayment(false)
          logPaymentFailure({ error: 'Complete purchase error', ...props })
          return
        }
        promoCode ? logCouponSuccess(props) : logPurchaseSuccess(props)
        window.location = successPageUrl.replace('{{subscription_id}}', completeResult.payload.data.paidSubscriptionId)
      })
      .catch(() => {
        logStat('cardSwipe/completeException')
        setErrorMessage(promoCode
          ? <FormattedMessage id="checkout.cardForm.error.haveNotBeenCharged" defaultMessage="The payment request failed. You have not yet been charged. Please check your payment method and try again, or contact customer support for assistance." />
          : <FormattedMessage id="checkout.cardForm.error.haveBeenCharged" defaultMessage="You have been charged, but the transaction failed to complete. Please contact customer support for assistance." />)
        setIsProcessingPayment(false)
        logPaymentFailure({ error: 'Complete purchase exception', ...props })
      })
  }

  function elementWrapperClassNames (elementName) {
    return classNames(
      'CardSwipeForm-elementWrapper',
      { 'CardSwipeForm-elementWrapper--focus': elementWithFocus === elementName },
      { 'CardSwipeForm-elementWrapper--hasValue': elementName === 'cardholderName' && nameOnCard },
    )
  }

  const cardholderNameClassNames = classNames(
    'CardSwipeForm-cardholderName',
    { 'CardSwipeForm-cardholderName--invalid': errorMessage && hasInvalidNameOnCard() },
  )

  const submitButtonClassNames = classNames(
    'CardSwipeForm-submitButton',
    `CardSwipeForm-submitButton--${domain.toUpperCase()}`,
  )

  return (
    <div className="CardSwipeForm">
      <h2 className="CardSwipeForm-h2">
        {
          checkoutAction === checkoutActions.ADD_NEW_CARD
            ? <FormattedMessage id="checkout.cardForm.headingAddCard" defaultMessage="Add Card Payment Details" />
            : <FormattedMessage id="checkout.cardForm.heading" defaultMessage="Input your payment information" />
        }
      </h2>
      <div className={elementWrapperClassNames('cardholderName')}>
        <input
          id="cardholderName"
          className={cardholderNameClassNames}
          type="text"
          name="cardholderName"
          placeholder=""
          value={nameOnCard}
          onChange={handleNameOnCardChange}
          onFocus={() => setElementWithFocus('cardholderName')}
          onBlur={() => setElementWithFocus(null)}
          disabled={isProcessingPayment}
        />
        <label htmlFor="cardholderName">
          <FormattedMessage id="checkout.cardForm.cardholderNameLabel" defaultMessage="Cardholder's Name" />
        </label>
      </div>
      <div className={elementWrapperClassNames('cardNumber')}>
        <CardNumberElement
          id="cardNumber"
          className="CardSwipeForm-cardNumber"
          placeholder=""
          disabled={isProcessingPayment}
          {...stripeElementCustomizations}
        />
        <label htmlFor="cardNumber">
          <FormattedMessage id="checkout.cardForm.cardNumberLabel" defaultMessage="Card Number" />
        </label>
      </div>
      <div className="CardSwipeForm-elementsFlexContainer">
        <div className={elementWrapperClassNames('expiry')}>
          <CardExpiryElement
            id="expiry"
            className="CardSwipeForm-expiry"
            placeholder=""
            disabled={isProcessingPayment}
            {...stripeElementCustomizations}
          />
          <label htmlFor="expiry">
            <FormattedMessage id="checkout.cardForm.expirationDateLabel" defaultMessage="MM/YY" />
          </label>
        </div>
        <div className={elementWrapperClassNames('cvc')}>
          <CardCVCElement
            id="cvc"
            className="CardSwipeForm-cvc"
            placeholder=""
            disabled={isProcessingPayment}
            {...stripeElementCustomizations}
          />
          <label htmlFor="cvc">
            <FormattedMessage id="checkout.cardForm.cvcLabel" defaultMessage="CVC" />
          </label>
        </div>
        <div className={elementWrapperClassNames('postalCode')}>
          <PostalCodeElement
            id="postalCode"
            className="CardSwipeForm-postalCode"
            placeholder=""
            disabled={isProcessingPayment}
            {...stripeElementCustomizations}
          />
          <label htmlFor="postalCode">
            <FormattedMessage id="checkout.cardForm.postalCodeLabel" defaultMessage="Zip Code" />
          </label>
        </div>
      </div>
      <ErrorSummary message={errorMessage} />
      <button className={submitButtonClassNames} type="submit" onClick={handleSubmit} disabled={isProcessingPayment}>
        {formSubmitButtonCopy(isProcessingPayment, checkoutAction)}
      </button>
      {
        checkoutAction !== checkoutActions.ADD_NEW_CARD && <TermsAndConditions lang={lang} customerSupportUrl={customerSupportUrl} domain={domain} mfpRedirectUrl={mfpRedirectUrl} />
      }
      {
        isProcessingPayment && <div className="TranslucentScreenOverlay" />
      }
    </div>
  )
}

const mapStateToProps = state => ({
  checkoutAction: state.checkout.checkoutAction,
  domain: state.checkout.domain,
  domainUserId: state.checkout.domainUserId,
  productId: state.checkout.productId,
  promoCode: state.checkout.promoCode,
  productAmount: state.checkout.productAmount,
  totalAmount: state.checkout.totalAmount,
  currency: state.checkout.currency,
  locale: state.checkout.locale,
  countryCode: state.checkout.countryCode,
  paymentIntentId: state.checkout.paymentIntentId,
  paymentIntentClientSecret: state.checkout.paymentIntentClientSecret,
  productFrequencyInterval: state.checkout.productFrequencyInterval,
  productFrequencyUnit: state.checkout.productFrequencyUnit,
  promoFrequencyInterval: state.checkout.promoFrequencyInterval,
  promoFrequencyUnit: state.checkout.promoFrequencyUnit,
  successPageUrl: state.checkout.successPageUrl,
  customerSupportUrl: state.checkout.customerSupportUrl,
  mfpRedirectUrl: state.config.config.mfpRedirectUrl,
})

const mapDispatchToActions = dispatch => ({
  add: (...args) => dispatch(addCard.apply(null, args)),
  prepare: (...args) => dispatch(prepareToCapture.apply(null, args)),
  complete: (...args) => dispatch(completeTransaction.apply(null, args)),
  logStat: statName => dispatch(logStatistic(statName)),
  logHandleSubmit: payload => dispatch(logHandleSubmitAction(payload)),
  logPaymentFailure: payload => dispatch(logPaymentFailureAction(payload)),
  logCouponSuccess: payload => dispatch(logCouponSuccessAction(payload)),
  logPurchaseSuccess: payload => dispatch(logPurchaseSuccessAction(payload)),
})

export default injectStripe(connect(mapStateToProps, mapDispatchToActions)(withRouter(CardSwipeForm)))
