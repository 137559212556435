import {
  POST_OAUTH_LOGIN_LOADING,
  POST_OAUTH_LOGIN_SUCCESS,
  POST_OAUTH_LOGIN_FAILURE,
  POST_OAUTH_FACEBOOK_LOGIN_LOADING,
  POST_OAUTH_FACEBOOK_LOGIN_SUCCESS,
  POST_OAUTH_FACEBOOK_LOGIN_FAILURE,
  POST_OAUTH_REGISTER_LOADING,
  POST_OAUTH_REGISTER_SUCCESS,
  POST_OAUTH_REGISTER_FAILURE
} from './constants'

export const postOAuthLogin = ({ username, password, delegatingClientId, delegatingRedirectUri }) => dispatch => dispatch({
  types: [POST_OAUTH_LOGIN_LOADING, POST_OAUTH_LOGIN_SUCCESS, POST_OAUTH_LOGIN_FAILURE],
  payload: {
    request:{
      method: 'post',
      url: '/oauth/login',
      data: {
        username,
        password,
        delegatingClientId,
        delegatingRedirectUri
      }
    }
  }
})

export const postOAuthFacebookLogin = ({ userId, token }) => (dispatch, getState) => {
  const facebookAppId = getState().config.config.facebookAppId
  return dispatch({
    types: [POST_OAUTH_FACEBOOK_LOGIN_LOADING, POST_OAUTH_FACEBOOK_LOGIN_SUCCESS, POST_OAUTH_FACEBOOK_LOGIN_FAILURE],
    payload: {
      request:{
        method: 'post',
        url: '/oauth/login/facebook',
        data: {
          provider: 'facebook',
          appId: facebookAppId,
          userId,
          token,
        }
      }
    }
  })
}

export const postOAuthRegister = ({ email, password, firstName, delegatingClientId, delegatingRedirectUri }) => dispatch => dispatch({
  types: [POST_OAUTH_REGISTER_LOADING, POST_OAUTH_REGISTER_SUCCESS, POST_OAUTH_REGISTER_FAILURE],
  payload: {
    request:{
      method: 'post',
      url: '/oauth/register',
      data: {
        email,
        password,
        firstName,
        delegatingClientId,
        delegatingRedirectUri
      }
    }
  }
})
