import axios from 'axios';
import UAParser from 'ua-parser-js';

let instance = null

// This is a class used in the redux analytics middleware
// Currently amplitude is only being used for MFP, and MMF apps

class AmplitudeLogger {
  constructor () {
    if (instance) { return instance }

    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    }

    this.client = axios.create({
      headers: headers,
    });
    const deviceResults = new UAParser().getResult();
    this.deviceInfo = {
      osName: deviceResults.os.name,
      osVersion: deviceResults.os.version,
      deviceManufacturer: deviceResults.device.vendor,
      deviceModel: deviceResults.device.model,
      deviceType: deviceResults.device.type,
    };
    this.noDeviceInfo = {
      osName: "", osVersion: "", deviceManufacturer: "",
      deviceModel: "", deviceType: "",
    };

    instance = this;
    return instance;
  }

  logEvent ({ type, payload }) {
    const trustarcPermission = (
      typeof window.PrivacyManagerAPI === "object"
      &&
      typeof window.PrivacyManagerAPI.callApi === "function"
      &&
      window.PrivacyManagerAPI.callApi("getConsentDecision", "account.underarmour.com")
    ) || undefined;
    const body = (
      typeof trustarcPermission === "object"
      &&
      typeof trustarcPermission.consentDecision === "number"
      &&
      [ 0, 3 ].indexOf( trustarcPermission.consentDecision ) >= 0
    ) ? {
      type,
      ...payload,
      ...this.deviceInfo,
    } : {
      type,
      ...payload,
      ...this.noDeviceInfo,
    };

    this.client.post('/api/v1/analytics/amplitude', body)
  }
}

export default AmplitudeLogger
