import {
  GET_USER_LOADING,
  GET_USER_SUCCESS,
  GET_USER_FAILURE,
  GET_USER_INFO_LOADING,
  GET_USER_INFO_SUCCESS,
  GET_USER_INFO_FAILURE,
  LOGOUT,
} from './constants'

export const getUser = ({accessToken}) =>
  (dispatch) =>
    dispatch({
      types: [GET_USER_LOADING, GET_USER_SUCCESS, GET_USER_FAILURE],
      payload: {
        request:{
          method: 'get',
          url:'/user',
          headers: {
            'Authorization': `Bearer ${accessToken}`
          }
        }
      }
    });

export  const getUserInfo = (code) =>
  (action) =>
      action({
        types:[GET_USER_INFO_LOADING, GET_USER_INFO_SUCCESS, GET_USER_INFO_FAILURE],
        payload: {
          request: {
            method: 'get',
            url:'/oauth/reentry/userInfo',
            params: {
                code
            }
          }
        }
      });



export const logout = () =>
  (dispatch) =>
    dispatch({
      type: LOGOUT,
      payload: {},
    });
