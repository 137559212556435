import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import classNames from 'classnames'
import { getSupportedLanguages } from '../../utils/languageCode'
import * as langDropdownActions from '../../services/langDropdown/actions'
import {
	LANG_DROPDOWN_LANGUAGE,
	LANG_DROPDOWN_PROFILE,
} from '../../services/langDropdown/constants'
import * as authActions from '../../services/auth/actions'
import * as userActions from '../../services/user/actions'
import './LanguageBar.scss'

const LANGUAGEBAR_MAX_HEIGHT = 604
// change LANGUAGEBAR_MAX_HEIGHT when adding or removing languages from the list

class LanguageBar extends Component {
	supportedLanguages = getSupportedLanguages(false)

	componentDidUpdate(prevProps) {
		if (prevProps.screen.width !== this.props.screen.width) {
			// if they have the language selector dropdown open, close it on browser resize. This makes it operate like a real dropdown that closes when the user is doing something else.
			// the user can always re-open the dropdown if they really want it open, but keeping it open on resize may cause user confusion.
			this.closeLanguageSelector()
		}
	}

	profileDropdown = () => {
		return (
			<ul
				className={classNames('LanguageBar-profile-menu', {
					'is-open':
						this.props.langDropdown['is-open'] === LANG_DROPDOWN_PROFILE,
				})}
			>
				<li className="LanguageBar-profile-menuItem" onClick={this.goLogout}>
					<button className="LanguageBar-profile-menuItemLink">Sign out</button>
				</li>
			</ul>
		)
	}

	toggleLanguageSelector = (which) => {
		if (which && this.props.langDropdown['is-open'] !== which) {
			document.removeEventListener('keydown', this.escapeKey, false)
			document.addEventListener('keydown', this.escapeKey, false)
			this.props.actions.langDropdown.setLangDropdown({
				'is-open': which,
			})
		} else {
			this.closeLanguageSelector()
		}
	}
	closeLanguageSelector = () => {
		document.removeEventListener('keydown', this.escapeKey, false)
		this.props.actions.langDropdown.setLangDropdown({
			'is-open': '',
		})
	}

	selectItem = (lang) => {
		// eslint-disable-next-line
		const { location, history } = this.props
		this.closeLanguageSelector()
		let splitPath = location.pathname.split('/')
		splitPath[1] = lang.id
		window.location.href = splitPath.join('/')
	}

	escapeKey = (e) => {
		if (e.keyCode === 27) {
			this.closeLanguageSelector()
		}
	}
	componentWillUnmount() {
		document.removeEventListener('keydown', this.escapeKey, false)
	}

	goToLogin = () => {
		const currentlyOnLogin = new RegExp('/login$').test(
			this.props.location.pathname
		)
		if (!currentlyOnLogin) {
			this.props.history.push(`/${this.props.locale.language.id}/login`)
		}
	}
	goLogout = () => {
		this.closeLanguageSelector()
		this.props.actions.auth.logout()
		this.props.actions.user.logout()
	}

	render() {
		const isLoggedIn = this.props.user.user && this.props.user.user.profile
		const userProfilePic =
			isLoggedIn &&
			typeof this.props.user.user.profile.profilePictureUri === 'string' &&
			!this.props.user.user.profile.profilePictureUri.match(
				new RegExp('/no_photo.png$')
			)
				? this.props.user.user.profile.profilePictureUri
				: ''
		const displayName =
			(isLoggedIn &&
				(this.props.user.user.profile.firstName ||
					this.props.user.user.profile.displayName)) ||
			'My account'

		const { language } = this.props.locale
		const heightOfTopNavAndLanguageBar = this.props.screen.width < 850 ? 30 : 40
		const height =
			// forces the height of the language dropdown, so mobile browsers can scroll the list
			// the variable heightOfTopNavAndLanguageBar is the difference from the screen height and the bottom of the dropdown. Change when the top and bottom margins change.
			// LANGUAGEBAR_MAX_HEIGHT is, for now, the height when all languages are completely visible on the page. It's the maximum height the dropdown can be.
			// change LANGUAGEBAR_MAX_HEIGHT when adding/removing languages from the dropdown
			this.props.screen.height >
			LANGUAGEBAR_MAX_HEIGHT + heightOfTopNavAndLanguageBar
				? LANGUAGEBAR_MAX_HEIGHT
				: this.props.screen.height - heightOfTopNavAndLanguageBar

		const ProfileDropdownWithPic = () => (
			<div
				onClick={() => this.toggleLanguageSelector(LANG_DROPDOWN_PROFILE)}
				className={classNames('LanguageBar-profile', {
					'is-open':
						this.props.langDropdown['is-open'] === LANG_DROPDOWN_PROFILE,
				})}
			>
				<img
					className="LanguageBar-profilePic"
					src={userProfilePic}
					alt={displayName}
					// style={{
					//   width: heightOfTopNavAndLanguageBar,
					//   height: heightOfTopNavAndLanguageBar,
					// }}
				/>
				{this.profileDropdown()}
			</div>
		)

		const ProfileDropdownWithoutPic = () => (
			<div
				style={{ height: heightOfTopNavAndLanguageBar }}
				onClick={() => this.toggleLanguageSelector(LANG_DROPDOWN_PROFILE)}
				className={classNames('LanguageBar-firstName', {
					'is-open':
						this.props.langDropdown['is-open'] === LANG_DROPDOWN_PROFILE,
				})}
			>
				<span
					className="LanguageBar-firstNameText"
					style={{ lineHeight: heightOfTopNavAndLanguageBar + 'px' }}
				>
					{displayName}
				</span>
				<div className="LanguageBar-downArrow">&#9660;</div>
				{this.profileDropdown()}
			</div>
		)

		return (
			<div className="LanguageBar">
				{/* this is a flex container, right-justified */}
				{/* language selector pulldown/dropdown */}
				<div className="LanguageBar-languageSelector-container">
					<div
						className={classNames('LanguageBar-languageSelector', {
							'is-open':
								this.props.langDropdown['is-open'] === LANG_DROPDOWN_LANGUAGE,
						})}
						onClick={() => this.toggleLanguageSelector(LANG_DROPDOWN_LANGUAGE)}
					>
						<span className="LanguageBar-languageSelector-current">
							{language.name}
						</span>

						<ul
							className="LanguageBar-languageSelector-list"
							style={{
								height,
								// this makes the list of languages vertically scrollable on mobile devices which have small screens
							}}
						>
							{this.supportedLanguages.map((lang) => (
								<li
									key={lang.id}
									className={classNames(
										'LanguageBar-languageSelector-listItem',
										{ 'is-active': lang.id === language.id }
									)}
									onClick={() => this.selectItem(lang)}
								>
									<button className="LanguageBar-languageSelector-listLink">
										{lang.name}
									</button>
								</li>
							))}
						</ul>
					</div>
				</div>{' '}
				{/*end LanguageBar-languageSelector-container*/}
				{/* login navigation */}
				{this.props.accountEnabled && isLoggedIn ? (
					userProfilePic ? (
						<ProfileDropdownWithPic />
					) : (
						<ProfileDropdownWithoutPic />
					)
				) : null}
				{this.props.accountEnabled && !isLoggedIn ? (
					<div className="LanguageBar-login" onClick={this.goToLogin}>
						<span>
							<FormattedMessage id="login" defaultMessage="Login" />
						</span>
					</div>
				) : null}
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		accountEnabled: state.config.config.accountEnabled,
		langDropdown: state.langDropdown,
		locale: state.locale,
		screen: state.screen,
		user: state.user,
	}
}
const mapDispatchToProps = (dispatch) => {
	return {
		actions: {
			langDropdown: bindActionCreators(langDropdownActions, dispatch),
			auth: bindActionCreators(authActions, dispatch),
			user: bindActionCreators(userActions, dispatch),
		},
	}
}
//
export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(LanguageBar)
)
