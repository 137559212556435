import React from 'react';

const HomeSiteLinks = () => { // no incoming variables

  return (
    <div className="siteLinks">
      <div className="site">
        <a href="https://www.underarmour.com"
          target="_blank" rel="noopener noreferrer"
          className="ua-com-logo"
          style={{ width: 112, height: 20 }}
        >
          Under Armour
        </a>
      </div>
      <div className="site">
        <a href="https://www.mapmyfitness.com"
          target="_blank" rel="noopener noreferrer"
          className="mmf-logo"
          style={{ width: 224, height: 25 }}
        >
          MapMyFitness
        </a>
      </div>
    </div>
  );
}

export default HomeSiteLinks;
