// sets the browser's width/height into redux for react

import { SCREEN } from './constants';
import createReducer from '../createReducer';

// initial state, get the browser's current width/height, compatible with ALL browsers
const w = window;
const d = document;
const documentElement = d.documentElement;
const body = d.getElementsByTagName("body")[0];
const width =  w.innerWidth || documentElement.clientWidth || body.clientWidth;
const height = w.innerHeight|| documentElement.clientHeight|| body.clientHeight;

const initialState = {
  width,
  height,
};

export default createReducer( initialState, {
  [ SCREEN ]: ( state, action ) => {
    return {
      ...state,
      ...action.payload,
      // why the "...action.payload"??? Because we want to pass in just the {width: value, height: value} object, the rootReducer will prefix it with "screen: {width: value, height: value}"
    }
  },
});
