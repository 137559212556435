import React from 'react';
import './Title.scss';

const Title = ({
  children,
}) => {
  return (
    <div className="Title">
      { children }
    </div>
  );
}

export default Title;
