import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Redirect } from '../_Common';

import * as authActions from "../../services/auth/actions";
import * as userActions from "../../services/user/actions";

class Logout extends Component {

  componentWillMount() {
    const { auth, user } = this.props.actions;
    auth.logout();
    user.logout();
  }

  render() {
    const { user } = this.props.user;
    const { accessToken } = this.props.auth;
    if ( !user && !accessToken ) {
      return (
        // redirect to the front page
        <Redirect to="" />
      )
    }
    return null;  // empty page
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    user: state.user,
    locale: state.locale,
    screen: state.screen,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      auth: bindActionCreators( authActions, dispatch ),
      user: bindActionCreators( userActions, dispatch ),
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)( Logout );
