import React, { Component } from 'react';
import classNames from "classnames";
import './ErrorMessage.scss';

/* accepts the following props
REQUIRED:
  message         = string to display when Error Message should appear, or empty
OPTIONAL:
  width           = number in px of this box's width
  onClose         = function to run when user clicks a close icon (future)
  errorHeight     = number in px, height of the error message box when displaying an error message. Defaults to 39.
  noErrorHeight   = number in px. Can be 0 or undefined. Height of this section when not displaying an error message
*/

class ErrorMessage extends Component {
  constructor(props) {
    super(props);

    this.styleWhenDisplayingError = { width: props.width };
    this.styleWhenNoError = { width: props.width };
    if ( typeof props.errorHeight === "number" ) {
      this.styleWhenDisplayingError.height = props.errorHeight;
    }
    if ( typeof props.noErrorHeight === "number" ) {
      this.styleWhenNoError.height = props.noErrorHeight;
    }
    if ( typeof props.width === "number" ) {
      this.styleWhenDisplayingError.width = props.width;
      this.styleWhenNoError.width         = props.width;
    }

  }

  /*******************/
  render() {
    const { message } = this.props;
    const isActive = (
      typeof message === "string" && message !== ""
    );
    return (
      <div
        className={classNames(
          "ErrorMessage", {
            isActive: isActive,
          }
        )}
        style={
          isActive
          ? this.styleWhenDisplayingError
          : this.styleWhenNoError
        }
      >
        { (
            isActive
            &&
            <div className="ErrorMessage-text">
              { message }
            </div>
          )
          ||
          <span>&nbsp;</span>
        }
      </div>
    );
  }
}

export default ErrorMessage;
