import {
  UNSUBSCRIBE_LOADING,
  UNSUBSCRIBE_SUCCESS,
  UNSUBSCRIBE_FAILURE,
} from './constants'

import createReducer from "../createReducer"

const initialState = {
  unsubscribe: {
    loading: false,
    success: null,
    failure: null,
  },
};

export default createReducer(initialState, {
  [UNSUBSCRIBE_LOADING]: (state, action) => ({
    ...state,
    unsubscribe: {
      loading: true,
      success: null,
      failure: null
    }
  }),
  [UNSUBSCRIBE_SUCCESS]: (state, action) => ({
    ...state,
    unsubscribe: {
      loading: false,
      success: action.payload,
      failure: false
    }
  }),
  [UNSUBSCRIBE_FAILURE]: (state, action) => ({
    ...state,
    unsubscribe: {
      loading: false,
      success: false,
      failure: action.error
    }
  }),
})
