import React from 'react';
import './Link.scss';

const Link = ({
    onClick,
    text
  }) => {
    return (
        <div className="Link" onClick={onClick}>
            {text}
        </div>
    );
  }
  
export default Link;
