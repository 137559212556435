import browserLocale from 'browser-locale';
import { findLanguageByCode } from './languageCode';

const browserLang = () => {
  let lang = browserLocale();

  const DEFAULT_LANG = "en-us";

  if ( typeof lang !== "string" ) {
    console.log("Could not detect browser's language, defaulting to en-us");
    return DEFAULT_LANG;
  }

  lang = lang.toLowerCase();
  const getCode = findLanguageByCode( lang );

  if ( typeof getCode !== "object" ) {
    console.log("Problem converting browser's language to a code");
    return DEFAULT_LANG;
  }
  if ( typeof getCode['id'] !== "string" ) {
    console.log("Problem finding browser's language code");
    return DEFAULT_LANG;
  }
  if ( ! new RegExp("^[a-z]{2}-[a-z]{2}$").test( getCode.id ) ) {
    console.log("Browser's language code was not in xx-xx format");
    return DEFAULT_LANG;
  }

  return getCode.id;
}

export default browserLang;
