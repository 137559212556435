import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import './BlueLink.scss';

/* Accepts the following props as input:
REQUIRED:
  text      = string to display as the blue link
  to        = url after the /{lang}/ for example, "login" will link to /{lang}/login" and "" will link to /{lang}
*/

const BlueLink = withRouter( ({ match, children, to, location }) => {
  const { lang } = match.params;
  to = to.replace( new RegExp("^/+"), "" );  // rid leading slashes
  if ( to ) {
    to = "/" + to;  // add leading slash to all to urls except if to is empty
  }
  if( location ) {
    to = to + location.search
  }
  return (
    <div className="BlueLink">
      <Link
        to={ `/${ lang }${ to }` }
      >
        { children }
      </Link>
    </div>
  )
});

export default BlueLink;
