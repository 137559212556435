const track = ({ type, payload }) => {
  // opt_in parameter is to make sure only those events with this value go to Branch.
  // We do not want any additional analytics to flow through there.
  if (window.branch && payload.branch_opt_in) {
    window.branch.logEvent(
      type
    );
  }
}

export default {
  track,
}
