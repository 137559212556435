import {
  TOPNAV_MESSAGE_SHOW,
} from './constants'

export const setTopNavMessageShow = ( data ) => {
  return dispatch => {
    return dispatch({
      type: TOPNAV_MESSAGE_SHOW,
      payload: { topNavMessageShow: data },
    })
  }
}
