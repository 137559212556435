// opens or closes the language dropdown

import { LANG_DROPDOWN } from './constants';
import createReducer from '../createReducer';

// initial state: dropdown is closed
const initialState = {
  'is-open': "",  // expected values: "", "LANG_DROPDOWN_LANGUAGE", "LANG_DROPDOWN_PROFILE"
};

export default createReducer( initialState, {
  [ LANG_DROPDOWN ]: ( state, action ) => {
    return {
      ...state,
      ...action.payload,
    }
  },
});
