import React, { Component, createRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import ReCAPTCHA from "react-google-recaptcha";
import { bindActionCreators } from 'redux';
import { Bold, Button, ErrorMessage, InputField, Loading, Title } from '../_Common';
import historyPush from '../../utils/historyPush';
import * as actions from '../../services/auth/actions';

import './ResetPassword.scss';

class ResetPassword extends Component {

  refNewPassword = createRef();
  refResetButton = createRef();
  refRecaptcha = createRef();

  state = {
    password: "",
    passwordShow: false,  // false=display dots, true=password is displayed
    passwordResetCompletedByToken: "",  // if the user successfully reset their password, store the token that marks the success.  This way, we can give them a "success" message when they see this page, including hitting "back" on their browser.
    error: "",  // text error message to display.
    initialLoad: true,  // initially start with initialLoad=true while waiting for the initial token call to come back. Wait for this.props.auth.getResetPassword.loading to initially switch from false to true, back to false
  };

  componentDidMount () {
    const { getResetPassword } = this.props.actions;
    const { token } = this.props.match.params;

    getResetPassword(token);
  }

  validateRecaptcha = (recaptchaValue) => {
    const { recaptchaEnabled } = this.props;
    if (recaptchaEnabled) {
      return !!recaptchaValue;
    }
    return true;
  }

  handleResetPassword = () => {
    const { password } = this.state;
    const recaptchaValue = this.refRecaptcha.current && this.refRecaptcha.current.getValue();
    if ( password.length >= 8 && this.validateRecaptcha(recaptchaValue)) {
      this.props.actions.postResetPassword(
        this.props.match.params.token,
        password,
        recaptchaValue
      );
    }
  }

  clickOK = () => {
    // send user to the URL that comes back from the TNS API, or the front page of this site
    // or, send to the front page of the account portal
    const { user } = this.props.auth;
    if ( user && user.redirect_uri ) {
      window.location = user.redirect_uri;
    } else {
      historyPush( this.props.history, this.props.match, "" );
      // send the user to /
    }
  }
  clickGoToForgotPassword = () => {
    historyPush( this.props.history, this.props.match, "forgot" );
  }

  scrollToTop = () => {
    window.scrollTo( 0, 0 );
  }

  componentDidUpdate( prevProps ) {
    // initial getResetPassword call to check the token, switch off this.state.loading after the initial call returns
    if ( this.state.initialLoad === true ) {
      if (
        prevProps.auth.getResetPassword.loading === true &&
        this.props.auth.getResetPassword.loading === false
      ) {
        // so this means the Reset Password initially kicked off a getResetPassword,
        // initially, it sets getResetPassword.loading to true while waiting for a response from the server,
        // then got a response and redux sets getResetPassword.loading to false.
        // set initialLoad to false, because the initial load is done.
        this.setState({ initialLoad: false });
        // stays false forever until the page is reloaded, which kicks off getResetPassword again
      }
    }
    /****/
    // was the password successfully changed? Look at this.props.auth.postResetPassword.success changing
    if (
      ! prevProps.auth.postResetPassword.success &&
      this.props.auth.postResetPassword.success
    ) {
      // user just got back confirmation that their password has been reset.
      // save this token to the state, which means the success message will appear even if their browser goes back to this page
      this.setState({ passwordResetCompletedByToken: this.props.match.params.token });
      // the goal here, if this.state.passwordResetCompletedByToken matches this.props.match.params.token in the render() code, show the success message
    }

    if (
      !prevProps.auth.postResetPassword.failure &&
      this.props.auth.postResetPassword.failure
    ) {
      // Password reset failed for one reason or another,
      // reset the recaptcha at least.
      this.refRecaptcha.current && this.refRecaptcha.current.reset()
    }
  }

  /*********************************/
  render() {
    const { locale } = this.props;
    const { recaptchaSiteKey, recaptchaEnabled } = this.props.config;
    const { getResetPassword, postResetPassword } = this.props.auth;
    // getResetPassword - the initial call to check if this token is active/valid
    // postResetPassword - the result of the submitted new password
    // user - user data that comes back after password is reset, used to get a continue url to send the user to when they press the OK button after resetting their password
    // const { isChina } = this.props.config;
    // const showWarning = !isChina && user && user.isSso;

    const containerWidth = (
      // the following "20" makes a minimum 10 pixel margin on the left & right. 10+10=20. On some mobile devices, this ensures the input boxes have left&right margins
      this.props.screen.width - 20 < 350
      ? this.props.screen.width - 20  // small width
      : 350                           // large width
    );

    const boxTopMargin = 40;

    const hasPasswordBeenSuccessfullyReset = (
      this.state.passwordResetCompletedByToken === this.props.match.params.token
    );

    // functions that display an error and disable the submit button
    const isPasswordLengthTooShort = (
      this.state.password.length < 8
    );
    const isPasswordLengthTooLong = (
      this.state.password.length > 72
    );

    if ( this.state.initialLoad === true ) {
      return (
        <div className="ResetPassword"
          style={{ paddingTop: boxTopMargin }}
        >
          <div className="ResetPassword-container"
            style={{ width: containerWidth, height: this.props.screen.height / 2 }}
          >
            <Loading
              width={ containerWidth }
              height={ this.props.screen.height / 2 }
              logoSize={ 50 }
            />
          </div>
        </div>
      )
    }

    /*************************/
    // if new password has been submitted, and returns success, show the success message
    if ( hasPasswordBeenSuccessfullyReset ) {
      return (
        <div className="ResetPassword"
          style={{ paddingTop: boxTopMargin }}
        >
          <div className="ResetPassword-container"
            style={{ width: containerWidth }}
          >
            <div className="ResetPassword-input">
              <Title>
                <FormattedMessage
                  id="resetPassword.success"
                  defaultMessage="Password reset!"
                />
              </Title>
            </div>
            <Button
              text={
                <FormattedMessage
                  id="resetPassword.resetPassword.return.to.login"
                  defaultMessage="Return to Login"
                />
              }
              allCaps={ true }
              width={ containerWidth }
              click={ this.clickOK }
            />
          </div>
        </div>
      );
    }

    /***************************/
    // if the getResetPassword token call returns a failure, their token has expired or is invalid. Show the invalid message
    if ( getResetPassword.failure ) {
      return (
        <div className="ResetPassword"
          style={{ paddingTop: boxTopMargin }}
        >
          <div className="ResetPassword-container"
            style={{ width: containerWidth }}
          >
            <div className="ResetPassword-message">
              <Bold>
                <FormattedMessage
                  id="verifyEmail.failureMessage"
                  defaultMessage="We're sorry, but this request has expired or is invalid. Please try again."
                />
              </Bold>
            </div>
            <Button
              text={
                <FormattedMessage
                  id="resetPassword.resetPassword.okButton"
                  defaultMessage="OK"
                />
              }
              allCaps={ true }
              width={ containerWidth }
              click={ this.clickGoToForgotPassword }
            />
          </div>
        </div>
      );
    }

    return (
      <div className="ResetPassword"
        style={{ paddingTop: boxTopMargin }}
      >
        {/* overall page */}
        <div className="ResetPassword-container"
          style={{ width: containerWidth }}
        >
          {/* this is the box centered on the page */}

          <Title>
            <FormattedMessage
              id="resetPassword.header"
              defaultMessage="Password Reset"
            />
          </Title>

          <ErrorMessage
            message={ this.state.error }
            width={ containerWidth }
            errorHeight={ 39 }
            noErrorHeight={ 10 }
          />

          <div className="ResetPassword-input">
            <InputField
              inputRef={ this.refNewPassword }
              name="newPassword"
              type={ this.state.passwordShow ? "text" : "password" }
              label="Password"
              width={ containerWidth }
              height={ 51 }
              fontSize={ 16 }
              value={ this.state.password }
              setValue={ (password) => this.setState({ password }) }
              clearError={ () => null }
              endAdornmentClick={ () => this.setState({ passwordShow: !this.state.passwordShow }) }
              endAdornmentText={
                ( this.state.passwordShow
                  && <FormattedMessage id="resetPassword.password.hide" defaultMessage="HIDE"/>
                )
                || <FormattedMessage id="resetPassword.password.show" defaultMessage="SHOW"/>
              }
              autoComplete={ false }
              autoCorrect={ false }
              autoCapitalize={ false }
              disallowSpaces={ false }
              disallowEmoji={ false }
              onEnter={ this.handleResetPassword }
              maxLength={ 128 }
            />
          </div>

          { (isPasswordLengthTooShort) && (this.state.password.length > 0)
            &&
            <div className="ResetPassword-minCharMessage">
              <FormattedMessage
                id="resetPassword.error"
                defaultMessage="Your password must contain at least 8 characters."
              />
            </div>
          }

          { recaptchaEnabled &&
            <div className="ResetPassword-recaptcha">
              <ReCAPTCHA
                hl={locale.language.id}
                ref={this.refRecaptcha}
                sitekey={recaptchaSiteKey}
              />
            </div>
          }

          <Button
            buttonRef={ this.refResetButton }
            text={
              <FormattedMessage
                id="resetPassword.resetPassword.button"
                defaultMessage="Reset"
              />
            }
            allCaps={ true }
            width={ containerWidth }
            click={ this.handleResetPassword }
            disabled={
              ( postResetPassword.loading ? true : false )
              ||
              ( isPasswordLengthTooShort )
              ||
              ( isPasswordLengthTooLong )
            }
            loading={ postResetPassword.loading ? true : false }
          />

        </div>
      </div>
    );
  }
}

function mapStateToProps (state) {
  return {
    auth: state.auth,
    config: state.config.config,
    locale: state.locale,
    screen: state.screen,
  };
}

function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResetPassword);
