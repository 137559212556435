import {
  GET_MESSAGES_LOADING,
  GET_MESSAGES_SUCCESS,
  GET_MESSAGES_FAILURE,
} from './constants';
import {
  getDefaultLanguage,
  findLanguageByCode,
} from '../../utils/languageCode';
import createReducer from '../createReducer';

const initialState = {
  language: getDefaultLanguage(),
  messages: {},
  getMessages: {
    loading: false,
    success: null,
    failure: null,
  },
};

export default createReducer(initialState, {
  [GET_MESSAGES_LOADING]: (state, action) => ({
    ...state,
    language: action.payload.id ? findLanguageByCode(action.payload.id) : getDefaultLanguage(),
    getMessages: {
      loading: true,
      success: null,
      failure: null
    }
  }),
  [GET_MESSAGES_SUCCESS]: (state, action) => ({
    ...state,
    language: findLanguageByCode(action.meta.previousAction.payload.id),
    messages: action.payload.data,
    getMessages: {
      loading: false,
      success: action.payload,
      failure: false
    }
  }),
  [GET_MESSAGES_FAILURE]: (state, action) => ({
    ...state,
    language: findLanguageByCode(action.meta.previousAction.payload.id),
    messages: {},
    getMessages: {
      loading: false,
      success: false,
      failure: action.error
    }
  }),
})
