import {
  TOPNAV_MESSAGE_SHOW
} from './constants';
import createReducer from '../createReducer';

// initial state, empty string
const initialState = {
  topNavMessageShow: true,
};

export default createReducer( initialState, {
  [ TOPNAV_MESSAGE_SHOW ]: ( state, action ) => {
    return {
      ...state,
      ...action.payload,
    }
  },
});
