import  React , { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { getUserInfo as getUserInfoAction } from '../../services/user/actions'
import {GET_USER_INFO_FAILURE, GET_USER_INFO_SUCCESS} from '../../services/user/constants'

import './OAuthLoginReentryTest.scss'
import * as queryString from "query-string";
import {bindActionCreators} from "redux";


class OAuthLoginReentryTest extends Component {
    state = {
        userInfo: {}
    }

     exchangeOauthToUserToken = () => {
         const {
             location: { search }
         } = this.props;

         const params = queryString.parse(search);

         const {
             getUserInfo
         } = this.props.actions;

        getUserInfo(params.code)
            .then((action) => {
                if(action.type === GET_USER_INFO_SUCCESS){
                    this.setState({
                        userInfo: action.payload.data.result
                        }
                    )
                }
                if(action.type === GET_USER_INFO_FAILURE){
                    this.setState({
                            userInfo: action.error.response.data.error.message
                        }
                    )
                }
         })
    };

    componentDidMount() {
        this.exchangeOauthToUserToken();
    }

    render() {
     return (
        <div className="OAuthTestReentry-container">
            <div className="OAuthTest-Button">
            </div>
            <div ><pre>{JSON.stringify(this.state.userInfo, null, 2) }</pre></div>
        </div>
        );
     }
};

const mapDispatchToProps= (dispatch) => {
    return {
        actions: {
            getUserInfo: bindActionCreators(getUserInfoAction, dispatch)
        }

    }
};

const mapStateToProps = () => ({
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OAuthLoginReentryTest))
