// globally sets whether the trustarc code has been loaded on the page
import {
  COOKIES_TRUSTARC_LOADED,
  COOKIES_SERVICES_LOADED,
} from './constants';
import createReducer from '../createReducer';

// initial state, trustarc (ta) has not been loaded
const initialState = {
  trustarcLoaded: false,
  services: [],
};

export default createReducer( initialState, {
  [ COOKIES_TRUSTARC_LOADED ]: ( state, action ) => {
    return {
      ...state,
      ...action.payload,
      // "...action.payload" means it sets {trustarcLoaded: value} object, the rootReducer will prefix it with "cookies: {trustarcLoaded: value}"
    }
  },
  [ COOKIES_SERVICES_LOADED ]: ( state, action ) => {
    return {
      ...state,
      services: [ ...state.services, action.payload ],
    }
  },
});
