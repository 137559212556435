import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import './Loading.scss';

// show loading logo
// after one second, show the word "Loading"
// after 10 seconds, show try again

const step2Time = 6000;   // 6 seconds from load time
const step3Time = 12000;

class Loading extends Component {
  state = {
    step: 1,
    // step=1 - initial load of the Loading, show pulsing icon
    // step=2 - show "Loading..." text or equivelant language
    // step=3 - stop animation, and TODO show a "try again" message
  }

  step2Timeout = "";
  step3Timeout = "";

  componentDidMount() {
    this.step2Timeout = setTimeout( () => {
      this.setState({ step: 2 });
    }, step2Time );
    this.step3Timeout = setTimeout( () => {
      this.setState({ step: 3 });
    }, step3Time );
  }

  componentWillUnmount() {
    clearTimeout( this.step2Timeout );
    clearTimeout( this.step3Timeout );
  }
  render() {
    const { width, height, logoSize } = this.props;
    const { step } = this.state;
    return (
      <div className="Loading">
        <div className="LoadingFlex"
          style={{ width, height }}
        >
          <div className="Loading-container">
            <div
              className={ classNames( "ualogo Loading-ualogo", {
                "Loading-animation": step < 3,
              } ) }
              style={{ backgroundSize: logoSize, height: logoSize }}
            >
              &nbsp;
            </div>
            <div className="Loading-text">
              <FormattedMessage
                id="verifyEmail.pending"
                defaultMessage="Please wait..."
              />
            </div>
          </div>
        </div>
      </div>
    )
    ;
  }
}

export default Loading;
