const namespace = 'oauthLogin/';

export const POST_OAUTH_LOGIN_LOADING = `${namespace}POST_OAUTH_LOGIN_LOADING`;
export const POST_OAUTH_LOGIN_SUCCESS = `${namespace}POST_OAUTH_LOGIN_SUCCESS`;
export const POST_OAUTH_LOGIN_FAILURE = `${namespace}POST_OAUTH_LOGIN_FAILURE`;

export const POST_OAUTH_FACEBOOK_LOGIN_LOADING = `${namespace}POST_OAUTH_FACEBOOK_LOGIN_LOADING`;
export const POST_OAUTH_FACEBOOK_LOGIN_SUCCESS = `${namespace}POST_OAUTH_FACEBOOK_LOGIN_SUCCESS`;
export const POST_OAUTH_FACEBOOK_LOGIN_FAILURE = `${namespace}POST_OAUTH_FACEBOOK_LOGIN_FAILURE`;

export const POST_OAUTH_REGISTER_LOADING = `${namespace}LOADING`;
export const POST_OAUTH_REGISTER_SUCCESS = `${namespace}SUCCESS`;
export const POST_OAUTH_REGISTER_FAILURE = `${namespace}FAILURE`;
