// takes in react router variables, pushes the user's browser to a new URL with language

/* Incoming variables:
  1st arg = the "this.props.history" from the parent
  2nd arg = the "this.props.match" from the parent
  3rd arg = the url to push the browser to, without leading slash. Example: "login" or ""
*/
const historyPush = ( history, match, to, queryString ) => {
  if ( typeof to !== "string" ) { return null };
  to = to.replace( new RegExp("^/+"), "" );  // rid leading slashes
  if ( to ) {
    to = "/" + to;  // add leading slash to all to urls except if to is empty
  }
  if( queryString ) {
    to = to + "?" + queryString
  }
  const { lang } = match.params;
  history.push( `/${ lang }${ to }` );
};

export default historyPush;
