import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
// import classNames from 'classnames';
import * as actions from '../../services/auth/actions';
import {FormattedMessage} from "react-intl";
import queryString from 'query-string'

import './VerifyEmail.scss';

class VerifyEmail extends Component {

  componentDidMount () {
    const { actions, match, location } = this.props;
    const token = match.params && match.params.token;
    const { appId } = location.search && queryString.parse(location.search);

    // when the component loads, we check to see if the person is on the email_verified page
    // or if they are on the verify email page
    // This resolves an issue of users mobile browsers reloading the page after already verifying and producing an error in our analytics
    // Makes for a better UX
    if(/\/[a-zA-Z]{2}-[a-zA-Z]{2}\/email_verified/.test(location.pathname)) {
      actions.forceConfirmEmailSuccess();
    } else {
      actions.postConfirmEmail(token, appId);
    }
  }

  handleRedirect = (appId) => {
    const { ctaDestinations } = this.props.config;
    if(appId && ctaDestinations[appId]) {
      window.location = ctaDestinations[appId];
    } else {
      window.location = ctaDestinations.DEFAULT;
    }
  }

  render () {
    const { auth, location, history, match } = this.props;
    const { loading, success, failure } = auth.postConfirmEmail;

    if(success && !failure) {
      // On a successful response from the redux verify email action, redirect the user to the email_verified page
      // email_verified page is located in this component
      if(!/\/[a-zA-Z]{2}-[a-zA-Z]{2}\/email_verified/.test(location.pathname)) {
        history.replace({
          pathname: `/${match.params.lang}/email_verified`,
          query: location.query
        })
      }
    }

    // const { appId } = location.search && queryString.parse(location.search);
    return (
      <div className="VerifyEmail VerifyEmail--uaTheme">
        {
          loading &&
          (
            <div className="VerifyEmail-container">
              <h1 className="VerifyEmail-header">
                <FormattedMessage id="verifyEmail.header" defaultMessage="Attempting to verify your email..." />
              </h1>
              <div className="VerifyEmail-message">
                <FormattedMessage
                  id="verifyEmail.pending"
                  defaultMessage="Please wait..." />
              </div>
            </div>
          )
        }
        { success &&
          (
            <div className="VerifyEmail-container">
              <h1 className="VerifyEmail-header">
                <FormattedMessage id="verifyEmail.headerSuccess" defaultMessage="Great! You've verified your email." />
              </h1>
              <div className="VerifyEmail-message">
                <FormattedMessage
                  id="verifyEmail.canCloseWindow"
                  defaultMessage="You can now close this window." />
              </div>
            </div>
          )
        }
        {
          failure &&
          (
            <div className="VerifyEmail-container">
              <div className="VerifyEmail-message">
                <FormattedMessage
                  id="verifyEmail.failureMessage"
                  defaultMessage="We're sorry, but this request has expired or is invalid. Please try again." />
              </div>
            </div>
          )
        }
      </div>
    );

  }
}

function mapStateToProps (state) {
  return {
    //routerState: state.router,
    config: state.config.config,
    auth: state.auth,
  }
}

function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(VerifyEmail);
