import axios from 'axios'
import axiosMiddleware from 'redux-axios-middleware'

export const config = {
  baseURL:'/api/v1',
  responseType: 'json',
  headers: {
    'Accept': 'application/json'
  }
}

export const client = axios.create(config) // all axios can be used, shown in axios documentation

export default axiosMiddleware(client)
