import { SCREEN } from './constants';

export const setScreen = ( data ) => {
  return dispatch => {
    return dispatch({
      type: SCREEN,
      payload: data,
    });
  };
};
