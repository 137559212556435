import {
  POST_OAUTH_LOGIN_LOADING,
  POST_OAUTH_LOGIN_SUCCESS,
  POST_OAUTH_LOGIN_FAILURE,
} from './constants'

import createReducer from '../createReducer'

export const initialState = {
  authCode: null,
  postOAuthLoginState: {
    loading: false,
    success: null,
    failure: null,
  },
}

export default createReducer(initialState, {
  [POST_OAUTH_LOGIN_LOADING]: (state) => ({
    ...state,
    postOAuthLoginState: {
      loading: true,
      success: null,
      failure: null,
    },
  }),
  [POST_OAUTH_LOGIN_SUCCESS]: (state, action) => {
    return {
      ...state,
      authCode: action.payload.data.result.authCode,
      postOAuthLoginState: {
        loading: false,
        success: true,
        failure: null,
      },
    }
  },
  [POST_OAUTH_LOGIN_FAILURE]: (state, action) => ({
    ...state,
    authCode: null,
    postOAuthLoginState: {
      loading: false,
      success: false,
      failure: action.error.response.statusText,
    },
  }),
})
