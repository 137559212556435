import {
  POST_LOG_STAT_LOADING,
  POST_LOG_STAT_SUCCESS,
  POST_LOG_STAT_FAILURE
} from './constants'

export const logStat = (statName) =>
    (dispatch) =>
      dispatch({
        types: [POST_LOG_STAT_LOADING, POST_LOG_STAT_SUCCESS, POST_LOG_STAT_FAILURE],
        payload: {
          request: {
            method: 'post',
            url: '/analytics/stats',
            data: { statName },
          }
        }
      })
