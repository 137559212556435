import { 
  LOGIN_USERNAME, 
  LOGIN_PATH 
} from './constants'

export const setUsername = ( data ) => {
  return dispatch => {
    return dispatch({
      type: LOGIN_USERNAME,
      payload: { username: data },
    })
  }
}

export const setLoginPath = ( data ) => {
  return dispatch => {
    return dispatch({
      type: LOGIN_PATH,
      payload: { loginPath: data }
    })
  }
}

export const unsetLoginPath =  () => {
  return dispatch => {
    return dispatch({
      type: LOGIN_PATH,
      payload: { loginPath: null }
    })
  }
}
