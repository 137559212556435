import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import TopNavDesktop from './TopNavDesktop';
import TopNavMobile  from './TopNavMobile';
import './TopNav.scss';

class TopNav extends Component {

  shouldComponentUpdate( nextProps ) {
    if ( nextProps.locationKey !== this.props.locationKey ) {
      // if the route changes (the user clicked on something) force an update of the TopNav
      return true;
    }
    if ( nextProps.screen.width !== this.props.screen.width ) {
      // the user changed their browser width, do an update
      return true;
    }
    if ( nextProps.screen.height !== this.props.screen.height ) {
      // the user changed their browser height. This is used for mobile only, for filling-in blank space under the hamburger menu items.
      return true;
    }
    return false;
  }

  render() {
    const { params } = this.props.match;
    // this.props.match.params comes in via withRouter, because the route.js enclosed <TopNav> within a <Route>
    // const { page } = params;  // this can be undefined if the user is on /en-us
    const lang = params.lang || "en-us";

    return (
      <div className="TopNav">
        { ( this.props.screen.width >= 850
            &&
            <TopNavDesktop
              {...this.props}
              // display the desktop (wide) version of the TopNav
              lang={ lang }
            />
          )
          ||
          <TopNavMobile
            {...this.props}
            // display the mobile (hamburger) version of the TopNav
            lang={ lang }
          />
          // notes for the curious: <TopNavDesktop> and <TopNavMobile> will mount/unmount depending on browser width. That's a good thing, not only because it only loads one html/css, but also when the browser width is adjusted, <TopNavMobile>'s state is reset on unmount which correctly collapses the hamburger dropdown if it was open.
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    screen: state.screen,
  };
};

export default connect( mapStateToProps )(
  withRouter( TopNav )
);
