import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import classNames from 'classnames';

import SideNav from '../SideNav';
import MarkdownContent from '../MarkdownContent';
import Footer from './Footer';

import * as documentsActions from "../../services/documents/actions";

import './ContentPages.scss';

// This is a general component used for loading our sidenav and markdown content pages
class PolicyPageLayout extends Component {
  _mounted = false;
  _firstLoadTimeout = false;
  _documentLoadingTimeout = false;
  state = {
    firstLoad: false,
    documentLoading: false,
  }

  componentDidMount () {
    this._mounted = true;
    const { getSubnavigation } = this.props.actions;
    const { lang } = this.props.match.params;
    // First load is used to determine if we display the loading animation or not
    // We set a delay on this so super fast browsers don't see a blip of the loading animation
    // But slower browsers will see the loading animation after a delay
    // Presents a better UX
    this._firstLoadTimeout = setTimeout(() => {
      if(this._mounted) {
        this.setState({
          firstLoad: true
        });
      }
    }, 4000);
    getSubnavigation(lang, this.props.page);
  }

  getCurrentPage = () => {
    const { subnavigation } = this.props.documents;
    const { page } = this.props.match.params;
    // We attempt to get the current page being requested
    // and will load the correct markdown based of the current url the person is attempting to access
    if(subnavigation) {
      return subnavigation.pages.find(a => (a.namespace.split('#')[0] === page))
    }
    return false;
  }

  getDefaultPage = () => {
    const { subnavigation } = this.props.documents;
    // If we do not have a subnav link provided in the url, we will load the default page
    if(subnavigation) {
      return subnavigation.pages.find(a => (a.id === subnavigation.defaultPage))
    }
    return false;
  }

  componentDidUpdate(prevProps) {
    const { lang, page } = this.props.match.params;
    const { subnavigation, document } = this.props.documents;
    const { getDocument, getSubnavigation } = this.props.actions;

    // When the route changes while on the ContentPages layout, the component does not reload
    // But we need to reload the markdown and/or sidenav on the page
    // This is where these checks in componentDidUpdate comes in
    if(prevProps.page !== this.props.page || prevProps.match.params.lang !== lang) {
      // On page change, dispatch a new redux action to retrieve the subnavigation
      getSubnavigation(lang, this.props.page);
      if(!this.state.firstLoad && !this._firstLoadTimeout) {
        // Set a timeout before displaying loading animation for better UX
        this._firstLoadTimeout = setTimeout(() => {
          if(this._mounted) {
            this.setState({
              firstLoad: true
            });
          }
        }, 4000);
      }
    } else if(
      (prevProps.documents.subnavigation === null || prevProps.match.params.page !== page)
      &&
      subnavigation !== null
    ) {
      // Also on page change, detect the current page selected,
      // and dispatch redux action to retrieve the markdown content
      const pageObj = this.getCurrentPage() || this.getDefaultPage();
      if(pageObj) {
        getDocument(lang, this.props.page, pageObj.id);
      }
    }
    if (document && document.content && this.state.firstLoad) {
      // When content as loaded, disable the loading animation if it is being displayed
      this.setState({
        firstLoad: false
      });
    }
  }

  componentWillUnmount() {
    this._mounted = false;
    if(this._firstLoadTimeout) {
      clearTimeout(this._firstLoadTimeout);
    }
  }

  render() {

    const { lang } = this.props.match.params;
    const { getSubnavigation, getDocument, subnavigation, document } = this.props.documents;
    const { hideSidenav } = this.props;

    return (
      <div>
        <div className={
          classNames(
            "ContentPages",
            {
              "is-loading":this.state.firstLoad,
              "is-failure":getSubnavigation.failure
            }
          )
        }>

          { !hideSidenav &&
            (
              <div className="ContentPages-subnav">
                {
                  (!hideSidenav && getSubnavigation.success && !this.state.firstLoad)
                  &&
                  <SideNav lang={lang} pages={subnavigation.pages} />
                }
              </div>
            )
          }

          <div
            className={ classNames(
              "ContentPages-content",
              {
                "ContentPages-content--fullWidth": hideSidenav,
                "is-loading":getDocument.loading,
                "is-failure":getDocument.failure
              }
            ) }
          >
            {
              ( getDocument.success
                &&
                <MarkdownContent {...this.props} html={document && document.content} />
              )
              ||
              <div className="MarkdownContainer"></div>
            }
            <Footer
              lang={ lang }
            />
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    documents: state.documents,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(documentsActions, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)( PolicyPageLayout );
