import React, { Component } from 'react';
import { injectIntl } from "react-intl";

import "./MarkdownContent.scss";

export class MarkdownContent extends Component {

  constructor(props) {
    super(props);
    this.mdContainer = React.createRef();
  }
  componentDidMount() {
    // We first check to see if HTML is set in the props
    // If so we render the HTML
    if ( this.props.html && this.props.html != null ) {
      this.bindReadMoreAndSetHTML(this.props.html);
    }
  }
  componentDidUpdate(prevProps) {
    // If the html changes, re-render the html
    if ( (prevProps.html !== this.props.html) && (this.props.html != null) ) {
      this.bindReadMoreAndSetHTML(this.props.html);
    }
  }

  bindReadMoreAndSetHTML = (html) => {
    // We access the mdContainer ref, and set the innerHTML
    // WARNING: This can open up XSS vulnerabilities if not used correctly
    // do not allow user input to be fed into this action
    const { location } = this.props;
    const mdContainer = this.mdContainer.current;
    // Remove all elements that may currently exist in the mdContainer
    while (mdContainer.firstChild) {
      mdContainer.removeChild(mdContainer.firstChild);
    }

    // Set the html provided by the props
    mdContainer.innerHTML = html;

    // Select all the ReadMore links so we can make things expandable/collapsable
    const readmoreLinks = Array.from( mdContainer.querySelectorAll(".ReadMore-link") );
    const expandableLinks = Array.from( mdContainer.querySelectorAll(".Expandable-link") );

    // For all readmore links, bind on click actions, and have them toggle a class to show/hide content
    readmoreLinks.forEach( (link) => {
      // link.innerHTML is everything inside the <a> tag.
      link.innerHTML = this.props.intl.formatMessage({id:"readMore",defaultMessage:"Read More"});
      link.addEventListener('click', (e) => {
        e.preventDefault();
        const element = e.target.parentElement;
        element.classList.toggle('is-active');
        if (element.classList.contains('is-active')) {
          e.target.innerHTML = this.props.intl.formatMessage({id:"showLess",defaultMessage:"Show Less"});
        } else {
          e.target.innerHTML = this.props.intl.formatMessage({id:"readMore",defaultMessage:"Read More"});
        }
      });
    });
    expandableLinks.forEach( (link) => {
      link.addEventListener('click', (e) => {
        e.preventDefault();
        let element = e.target.parentElement;
        // go at least 6 <div> parent elements up, to find a class named "Expandable"
        for ( let i = 0; i < 6; i++ ) {
          if ( element.classList.contains("Expandable") ) {
            break ;  // found the <div> with class="Expandable"
          }
          element = element.parentElement;  // go one more up
        }
        if ( ! element.classList.contains("Expandable") ) {
          return ;  // make sure we are on the <div> with class="Expandable"
        }

        const expandedSections = Array.from( element.getElementsByClassName("Expanded") );
        expandedSections.forEach( (section) => {
          if ( element.classList.contains("is-active") === false ) {
            // not expanded, so set the maxHeight to the scrollHeight which acurately sets the height of the text, animation works well here
            section.style.maxHeight = section.scrollHeight + "px";
          } else {
            section.style.maxHeight = 0 + "px";
          }
        });
        // make this Expandable element expand or collapse
        element.classList.toggle("is-active");
        window.scrollTo( 0, element.offsetTop );
      });
    });

    // Detect if we are using a hash link in the url
    // if so, scroll to the element in the view
    if(location && location.hash) {
      const splitHash = location.hash.split('#');
      if(splitHash.length > 1) {
        const header = document.getElementById(splitHash[1]);
        if(header) {
          header.scrollIntoView();
        }
      }
    }
  }

  render() {
    const style = {};
    if ( this.props.page === "data-management" ) {
      style.paddingLeft = 0;
      style.paddingRight = 0;
    }
    return (
      <div
        ref={this.mdContainer}
        className="MarkdownContainer"
        style={ style }
      />
    )
  }
}

export default injectIntl(MarkdownContent);
