const namespace = 'checkout/';

export const GET_PAYMENT_INTENT_LOADING = `${namespace}GET_PAYMENT_INTENT_LOADING`;
export const GET_PAYMENT_INTENT_SUCCESS = `${namespace}GET_PAYMENT_INTENT_SUCCESS`;
export const GET_PAYMENT_INTENT_FAILURE = `${namespace}GET_PAYMENT_INTENT_FAILURE`;

export const POST_PAYMENT_INTENT_LOADING = `${namespace}POST_PAYMENT_INTENT_LOADING`;
export const POST_PAYMENT_INTENT_SUCCESS = `${namespace}POST_PAYMENT_INTENT_SUCCESS`;
export const POST_PAYMENT_INTENT_FAILURE = `${namespace}POST_PAYMENT_INTENT_FAILURE`;

export const POST_ADD_CARD_LOADING = `${namespace}POST_ADD_CARD_LOADING`;
export const POST_ADD_CARD_SUCCESS = `${namespace}POST_ADD_CARD_SUCCESS`;
export const POST_ADD_CARD_FAILURE = `${namespace}POST_ADD_CARD_FAILURE`;

export const POST_PREPARE_TO_CAPTURE_LOADING = `${namespace}POST_PREPARE_TO_CAPTURE_LOADING`;
export const POST_PREPARE_TO_CAPTURE_SUCCESS = `${namespace}POST_PREPARE_TO_CAPTURE_SUCCESS`;
export const POST_PREPARE_TO_CAPTURE_FAILURE = `${namespace}POST_PREPARE_TO_CAPTURE_FAILURE`;

export const POST_COMPLETE_TRANSACTION_LOADING = `${namespace}POST_COMPLETE_TRANSACTION_LOADING`;
export const POST_COMPLETE_TRANSACTION_SUCCESS = `${namespace}POST_COMPLETE_TRANSACTION_SUCCESS`;
export const POST_COMPLETE_TRANSACTION_FAILURE = `${namespace}POST_COMPLETE_TRANSACTION_FAILURE`;

export const PAGE_LOAD_ANALYTICS = `${namespace}PAGE_LOAD_ANALYTICS`;
export const PAGE_LOAD_SUCCESS_ANALYTICS = `${namespace}PAGE_LOAD_SUCCESS_ANALYTICS`;
export const HANDLE_SUBMIT_ANALYTICS = `${namespace}HANDLE_SUBMIT_ANALYTICS`;
export const HANDLE_SUBMIT_ANALYTICS_BRANCH = `${namespace}HANDLE_SUBMIT_ANALYTICS_BRANCH`;
export const PAYMENT_FAILURE_ANALYTICS = `${namespace}PAYMENT_FAILURE_ANALYTICS`;
export const PAYMENT_FAILURE_ANALYTICS_BRANCH = `${namespace}PAYMENT_FAILURE_ANALYTICS_BRANCH`;
export const PAYMENT_SUCCESS_ANALYTICS = `${namespace}PAYMENT_SUCCESS_ANALYTICS`;
export const COUPON_SUCCESS_ANALYTICS = `${namespace}COUPON_SUCCESS_ANALYTICS`;
export const COUPON_SUCCESS_ANALYTICS_BRANCH = `${namespace}COUPON_SUCCESS_ANALYTICS_BRANCH`;
