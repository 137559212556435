import React from 'react'
import { connect } from 'react-redux'
import { Button } from '../_Common'
import './OAuthTest.scss'

const OAuthTest = ({ oauthTestClientId, oauthTestRedirectUrl }) => {

  const handleClick = () => {
    const state = new Date().getTime()
    window.location = `/oauth/authorize?response_type=code&redirect_uri=${oauthTestRedirectUrl}&state=${state}&client_id=${oauthTestClientId}`
  }

  return (
    <div className="OAuthTest-container">
      <h2 className="OAuthTest-h2">
        Test an OAuth Experience
      </h2>
      <Button text="Login" width={280} allCaps={true} click={handleClick} />
    </div>
  )
}

const mapStateToProps = state => ({
  oauthTestClientId: state.config.config.oauthTestClientId,
  oauthTestRedirectUrl: state.config.config.oauthTestRedirectUrl,
})

export default connect(mapStateToProps)(OAuthTest)
