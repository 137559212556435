import React, { useEffect, useReducer, useRef } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';
import CheckoutTopNav from './CheckoutTopNav'
import InputField from '../_Common/InputField'
import Button from '../_Common/Button'
import ErrorMessage from '../_Common/ErrorMessage'
import { createPaymentIntent } from '../../services/checkout/actions'
import './Checkout.scss'

const formReducer = (state, action) => ({
	...state,
	[action.type]: action.value,
})

const CheckoutTest = (props) => {
	const { checkoutUrl, postPaymentIntentState: { loading, failure }, submit } = props

	if (checkoutUrl) {
		window.location.href = checkoutUrl
		return null
	}

	const { match: { params: { domain, lang }}} = props
	const [formState, dispatchFormChange] = useReducer(formReducer, {
		domain,
		domainUserId: '',
		locale: 'en-us',
		countryCode: 'US',
		productId: '',
		promoCode: '',
		avatarUrl: 'https://d34yn14tavczy0.cloudfront.net/images/no_photo.png',
		successPageUrl: `${window.location.protocol}//${window.location.host}/${lang}/checkout/${domain}/success/{{subscription_id}}`,
		customerSupportUrl: domain === 'MMF' ? 'https://www.mapmyfitness.com/help' : 'https://www.myfitnesspal.com/help',
	})

	const domainRef = useRef(null)
	const domainUserIdRef = useRef(null)
	const localeRef = useRef(null)
	const countryCodeRef = useRef(null)
	const productIdRef = useRef(null)
	const promoCodeRef = useRef(null)
	const avatarUrlRef = useRef(null)
	const successPageUrlRef = useRef(null)
	const customerSupportUrlRef = useRef(null)
	const submitButtonRef = useRef(null)

	useEffect(() => domainUserIdRef.current.focus(), [])
	const handleSubmit = () => submit(formState)

	return (
		<div className="Checkout CheckoutTest">
			{
        domain && <CheckoutTopNav domain={domain} />
			}
			<div className="CheckoutTest-container">
				<h2 className="CheckoutTest-h2">
					Test a Checkout Experience
				</h2>
				<div className="CheckoutTest-form">
					{
						failure && <ErrorMessage
							message={failure.response.data ? failure.response.data.error : 'Unknown error'}
							width={500}
						/>
					}
					<InputField
						inputRef={domainRef}
						name="domain"
						type="text"
						label="Domain"
						disabled={true}
						width={500}
						height={ 51 }
						fontSize={16}
						value={formState.domain}
						setValue={value => dispatchFormChange({ type: 'domain', value })}
						clearError={null}
						autoComplete={false}
						autoCorrect={false}
						autoCapitalize={false}
						disallowEmoji={true}
					/>
					<InputField
						inputRef={domainUserIdRef}
						name="domainUserId"
						type="text"
						label="Domain User ID"
						width={500}
						height={ 51 }
						fontSize={16}
						value={formState.domainUserId}
						setValue={value => dispatchFormChange({ type: 'domainUserId', value })}
						clearError={null}
						autoComplete={false}
						autoCorrect={false}
						autoCapitalize={false}
						disallowEmoji={true}
					/>
					<InputField
						inputRef={localeRef}
						name="locale"
						type="text"
						label="Locale"
						width={500}
						height={51}
						fontSize={16}
						value={formState.locale}
						setValue={value => dispatchFormChange({ type: 'locale', value })}
						clearError={null}
						autoComplete={false}
						autoCorrect={false}
						autoCapitalize={false}
						disallowEmoji={true}
					/>
					<InputField
						inputRef={countryCodeRef}
						name="countryCode"
						type="text"
						label="Country Code"
						width={500}
						height={51}
						fontSize={16}
						value={formState.countryCode}
						setValue={value => dispatchFormChange({ type: 'countryCode', value })}
						clearError={null}
						autoComplete={false}
						autoCorrect={false}
						autoCapitalize={false}
						disallowEmoji={true}
					/>
					<div className="CheckoutTestForm-flexWrapper">
						<div className="CheckoutTestForm-flexItem">
							<InputField
								inputRef={productIdRef}
								name="productId"
								type="text"
								label="Product ID"
								width={225}
								height={51}
								fontSize={16}
								value={formState.productId}
								setValue={value => dispatchFormChange({ type: 'productId', value })}
								clearError={null}
								autoComplete={false}
								autoCorrect={false}
								autoCapitalize={false}
								disallowEmoji={true}
							/>
						</div>
						<div className="CheckoutTestForm-orSpacer">
							<span>
								- or -
							</span>
						</div>
						<div className="CheckoutTestForm-flexItem">
							<InputField
								inputRef={promoCodeRef}
								name="promoCode"
								type="text"
								label="Promo Code"
								width={225}
								height={51}
								fontSize={16}
								value={formState.promoCode}
								setValue={value => dispatchFormChange({ type: 'promoCode', value })}
								clearError={null}
								autoComplete={false}
								autoCorrect={false}
								autoCapitalize={false}
								disallowEmoji={true}
							/>
						</div>
					</div>
					<InputField
						inputRef={avatarUrlRef}
						name="avatarUrl"
						type="text"
						label="Avatar Url"
						width={800}
						height={51}
						fontSize={16}
						value={formState.avatarUrl}
						setValue={value => dispatchFormChange({ type: 'avatarUrl', value })}
						clearError={null}
						autoComplete={false}
						autoCorrect={false}
						autoCapitalize={false}
						disallowEmoji={true}
					/>
					<InputField
						inputRef={successPageUrlRef}
						name="successPageUrl"
						type="text"
						label="Success Page Url"
						width={800}
						height={51}
						fontSize={16}
						value={formState.successPageUrl}
						setValue={value => dispatchFormChange({ type: 'successPageUrl', value })}
						clearError={null}
						autoComplete={false}
						autoCorrect={false}
						autoCapitalize={false}
						disallowEmoji={true}
					/>
					<InputField
						inputRef={customerSupportUrlRef}
						name="customerSupportUrl"
						type="text"
						label="Customer Support Url"
						width={800}
						height={51}
						fontSize={16}
						value={formState.customerSupportUrl}
						setValue={value => dispatchFormChange({ type: 'customerSupportUrl', value })}
						clearError={null}
						autoComplete={false}
						autoCorrect={false}
						autoCapitalize={false}
						disallowEmoji={true}
					/>
					<Button
						buttonRef={submitButtonRef}
						text="SUBMIT"
						width={300}
						click={handleSubmit}
						disabled={loading}
						loading={loading}
					/>
				</div>
			</div>
		</div>
	)
}

const mapStateToProps = state => ({
	checkoutUrl: state.checkout.checkoutUrl,
	postPaymentIntentState: state.checkout.postPaymentIntentState,
})

const mapDispatchToActions = dispatch => ({
	submit: payload => dispatch(createPaymentIntent(payload)),
})

export default connect(mapStateToProps, mapDispatchToActions)(withRouter(CheckoutTest))
