// sends data to amplitude about a screen view on the Account Portal
import { Component } from 'react';
import { sendAmplitudeData } from '../utils/amplitude';

// mapping of URLs to screen_name event property
const screenNames = {
  // URL after locale/  : screen_viewed property in amplitude
  ""                    : "home",
  "preferences"         : "email_preferences",
  "login"               : "login",
  "forgot"              : "forgot_password",
  "password_reset"      : "reset_password",
  "account"             : "account_manager",
  "account-center"      : "account_resources",
  "verify_email"        : "verify_email",
  "email_verified"      : "email_verified",
  "checkout"            : "checkout",
  "privacy_and_terms"   : "privacy_and_terms",
  "mobile-overview"     : "privacy_and_terms_mobile",
  "privacy"             : "privacy_policy",
  "terms-and-conditions": "terms_and_conditions",
  "app-specific-terms"  : "product_specific_terms",
  "data-management"     : "data_management",
  "help"                : "faqs",
  "employee-terms"      : "employee_terms",
  "vip-terms"           : "vip_terms",
  "apple-offer"         : "apple_offer",
  "api-terms-and-conditions": "api_terms",
  "companies-we-share-data-with": "data_sharing_companies",
}
const trim = (str) => str.replace(
  new RegExp("^\\s+"), ""
).replace(
  new RegExp("\\s+$"), ""
);

class Amplitude extends Component {
  render() {
    const { amplitudeIsLoaded, pathname } = this.props;
    if (
      amplitudeIsLoaded
      &&
      typeof pathname === "string"
      &&
      pathname.match( new RegExp("^/[a-z]{2}-[a-z]{2}") )
    ) {
      // only make the call to amplitude if trustarc says the client is okay with it.
      const path = (
        typeof pathname === "string"
        ? trim( pathname.replace(
          new RegExp("^/[a-z]{2}-[a-z]{2}/?"), ""
        ) )
        : ""
      );
      // path is "privacy" if the url was /en-us/privacy, or "" if the url was the front page
      if ( typeof screenNames[ path ] === "string" ) {
        sendAmplitudeData(
          "screen_viewed", { "screen_name": screenNames[ path ] }
        );
        // if the screen_name event property is in the hash array screenNames, then go ahead and send that screen_name property to amplitude
        // otherwise, if the user is browsing a screen that is not on the list, then don't log in amplitude
      }
    }
    return null;
  }
}
//
export default Amplitude;
