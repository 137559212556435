import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { isValidEmail } from '../../utils/validation';
import { bindActionCreators } from 'redux';
import queryString from 'query-string';
import { Redirect } from 'react-router-dom';
import * as unsubscribeActions from '../../services/unsubscribe/actions';
import { InputField } from '../_Common';
import './Unsubscribe.scss';
import browserLang from '../../utils/browserLang';

// Remove email address from redux state
// Use regular state of component to store/display email
// Use redux unsubscribe state/actions for API interactions and indicators

// This is our content list of unsubscribe methods and content to display on the list view
const TILE_CONTENT = [{
  // The id is what's sent to the API when unsubscribing from a specific selection
  id: "unsubscribeHeader1",
  // Class modifiers for the brand logo
  bannerClassName: "",
  // content ID for react-intl
  contentId: "preferences.listheader.promo",
  // Default content for react-intl component
  contentDefault: "The Best Gear for Your Game",
  // Preference link is used if you want to provide an external link to the listing
  preferencesLink: "",
  // The header boolean is used to change the template of the listing, and to ignore the id in sending to API
  header: true,
  section: 1,
},{
  id: "underarmour",
  bannerClassName: "Unsubscribe-ListItem-banner--uaComLogo",
  contentId: "preferences.ua.description",
  contentDefault: "Get the greatest gear on the planet the moment it's available. Don't miss out on promotions, special offers, and what's coming soon.",
  preferencesLink: "",
  appId: "UA",
  utm_source: "ua",
  section: 1,
},{
  id: "unsubscribeHeader2",
  bannerClassName: "",
  contentId: "preferences.listheader.apps",
  contentDefault: "Connected Fitness",
  preferencesLink: "",
  header: true,
  section: 2,
},{
  id: "mmf",
  bannerClassName: "Unsubscribe-ListItem-banner--mmfLogo",
  contentId: "preferences.mapmyfitness.description",
  contentDefault: "Make sure you're always a step ahead with new feature announcements, current Challenges, and weekly and monthly workout summaries.",
  preferencesLink: "https://www.mapmyfitness.com/account/contact",
  appId: "MAPMYFITNESS",
  utm_source: "mmf",
  section: 2,
}];

const NUM_APPS = (
  TILE_CONTENT.filter(
    (app) => { return ! app.header }
  ).length
  // number of selectable apps from TILE_CONTENT, except for "all"
);

class Unsubscribe extends Component {
  // We create an email input ref so we can manipulate the focus/unfocus actions of the input
  // Being that on page load we focus to the input, and unfocus action to validate input
  emailInputRef = React.createRef();

  state = {
    // this is where we store the email on input change
    email: "",
    // Is valid can be true, false, or null.
    // null, no action taken
    // false, display invalid email highlight
    // true, email valid, no action taken
    isValid: null,
    // Allow access to the checkbox list
    open: false,
    // Successful response from API
    success: false,
    // Failed response from API
    failure: false,
    // This was used when we were using Unsubscribe buttons
    // The content list id would be stored here to specifically select the unsubscribe button
    // and display the loading animation only on that button
    loadingButton: "",
    // AppId was used to sort the selected app to the top
    // This appId comes over on the URL query string parameters
    // This has been disabled since the email template links have not been fully fleshed out
    // Will more than likely use the google analytics querystring paramters to populate this field
    appId: "",
    // The original design included a change email link under the unsubscribe input
    // This would toggle a render of <Redirect /> react object to redirect to the appropriate page
    // But we do not have change email functionality created
    redirectChangeEmail: false,
    // This is the list of checkboxes that were selected. The id of the list content above would be concat in here
    selectedApps: [],
    // selectedApps eventually turns into req.body.unsubscribeList on the server in src/server/api/v1/unsubscribe/controller.js
  }

  componentWillUnmount() {
    // Clear the debounce timeouts
    if (this._timeout) {
      clearTimeout(this._timeout);
    }
    if (this._successTimeout) {
      clearTimeout(this._successTimeout);
    }
    if (this._failureTimeout) {
      clearTimeout(this._failureTimeout);
    }
  }

  componentDidMount() {
    // On component mount, we pull the query string from the url to figure out how to sort the list
    if ( this.props.location && this.props.location.search ) {
      const { utm_source, j } = queryString.parse( this.props.location.search );
      // if ?j=000000 (any six digit number) then it's ecomm. make utm_source UA
      if ( typeof j === "string" && j.match( new RegExp("^\\d{6}$") ) ) {
        this.setState({ utm_source: "ua", selectedApps: ["underarmour"] });
      }
      if ( typeof utm_source === "string" ) {
        this.setState({ utm_source: utm_source.toLowerCase(), selectedApps: [ utm_source ] });
      }
    }
    // Focus on the email input on component load
    // this.emailInputRef.current.focus();
  }

  componentDidUpdate(prevProps) {
    // We check to see if the redux states changed on the unsubscribe action/API request
    if (this.props.unsubscribe.success && !prevProps.unsubscribe.success) {
      this.announceSuccess();
    }
    if (this.props.unsubscribe.failure && !prevProps.unsubscribe.failure) {
      this.announceFailure();
    }
    if (
      prevProps.user.user == null &&
      this.props.user.user &&
      Array.isArray( this.props.user.user.emails ) &&
      this.props.user.user.emails[0]
    ) {
      // user is logged in, and has an email address. Write it into the email field
      this.setState({
        email: this.props.user.user.emails[0],
        isValid: true,
      });
    }
  }

  _lastEmail = "";
  _timeout = null;

  debounceValidation = (email) => {
    // We time delay the email validation on each key press
    // This makes it where if the user stops typing for a period of time
    // it will validate and display the invalid highlight if needed
    if (this._timeout) {
      clearTimeout(this._timeout);
    }
    const isValid = email ? isValidEmail(email) : null;
    if (isValid) {
      this.setState({isValid});
      return;
    }
    this._timeout = setTimeout(() => this.setState({isValid}), 1000);
  }

  emailInputOnChange = (email) => {
    email = email.replace(
      new RegExp("\\s", "g"),
      ""
    );
    this.setState({
      email,
      isValid: null,
    });
    this.debounceValidation(email);
  }

  emailInputOnBlur = (event) => {
    // on blue or unfocus, validate email
    if (this._timeout) {
      clearTimeout(this._timeout);
    }
    const email = event.target.value;
    const isValid = email ? isValidEmail(email) : null;

    this.setState({
      email,
      isValid,
      emailFocus: false,
    });
  };

  emailInputOnFocus = () => {
    // On email focus state change for UI changes
    this.setState({
      emailFocus: true,
    });
  };

  _successTimeout = null;
  announceSuccess = () => {
    // On success driven by componentDidUpdate, we want to toggle a few states
    // These states drive a lot of the UI related elements
    this.setState({
      open: true,
      success: true,
      failure: false,
    });
    this._successTimeout = setTimeout(() => {
      this.setState({
        open: false,
        success: false,
        failure: false,
      })
    }, 3000);
  }

  _failureTimeout = null;
  announceFailure = () => {
    // On failure driven by componentDidUpdate, we want to toggle a few states
    // These states drive a lot of the UI related elements
    this.setState({
      failure: true,
      success: false,
      open: true,
    });
    this._failureTimeout = setTimeout(() => {
      this.setState({
        open: false,
        success: false,
        failure: false,
      })
    }, 15000);
  }

  dispatchUnsubscribe = () => {
    // On unsubscribe button click, dispatch the unsubscribe action with email, and list of selected apps
    this.props.actions.unsubscribeList(this.state.email, this.state.selectedApps);
  }

  handleCheckChange = (event) => {
    // handle checkbox on change
    const target = event.target;
    const name = target.name;

    // all is a unique identifier the API uses when the user selects all checkboxes
    // all gets sent over to the API, as the API has to handle this action slightly differently
    // than the rest of the unsubscribe actions

    this.doAppSelect( name );
  }

  handleListClick = (name) => {
    // If there is a unsubscribe checkbox list with a preferencesLink field
    // We want to check to see if they clicked on the link, if so we disable the default action of checking the box
    // If they don't click on the link, but click on anywhere on the row, we will check the box
    // We do this so it's more mobile friendly, the user can tap the entire row to check the box, instead of just the checkbox
    return (event) => {
      if (event.target.tagName === "A") {
        return;
      }
      this.doAppSelect( name );
    }
  }

  doAppSelect = (name) => {
    if (name === "all") {
      // user clicked Select All
      if (this.state.selectedApps.find(app => (app === name))) {
        // user had Select All selected, so unselect all
        this.setState({
          selectedApps: []
        });
      } else {
        this.setState({
          // make this list "all" plus list of every unsubscribe id
          selectedApps: ["all"].concat(
            TILE_CONTENT
              .filter(content => !content.header)
              .map(content => content.id)
          )
        });
      }
    } else {
      // user clicked an app from the list
      if (this.state.selectedApps.find(app => (app === name))) {
        // app was selected, so unselect it
        this.setState({
          selectedApps: this.state.selectedApps.filter(
            app => (app !== name && app !== "all")
          ),
        });
      } else {
        // add app to the selected list
        const newSelectedApps = this.state.selectedApps.concat([name]);
        if ( newSelectedApps.length === NUM_APPS ) {
          // user checked all apps, so also add "all" which checks "Select All" checkbox
          newSelectedApps.push("all");
        }
        this.setState({
          selectedApps: newSelectedApps,
        });
      }
    }
  }

  // This is the list item template, a functional component used in the TILE_CONTENT.map during renderList
  listItemTemplate = ({
    content,
    loading,
    loadingButton
  }) => (
    <div key={content.id}
      className={ classNames("Unsubscribe-ListItem Unsubscribe-ListItem--clickable", {"is-loading": (loading && loadingButton === content.id)}) }
      onClick={ this.handleListClick(content.id) }
    >
      <div className="Unsubscribe-ListItem-check">
        <input className="Unsubscribe-ListItem-checkbox"
          type="checkbox"
          name={content.id}
          value={content.id}
          checked={this.state.selectedApps.filter(app => (app === content.id)).join("")}
          onChange={ this.handleCheckChange }
        />
      </div>
      <div className="Unsubscribe-ListItem-content">
        <div className="Unsubscribe-ListItem-brand">
          <div className={classNames("Unsubscribe-ListItem-banner", content.bannerClassName)}>
            {content.titleId && content.titleDefault && <h2>
              <FormattedMessage
                id={ content.titleId }
                defaultMessage={ content.titleDefault }
              />
            </h2>}
          </div>
        </div>
        <div className="Unsubscribe-ListItem-description">
          <FormattedMessage
            id={ content.contentId }
            defaultMessage={ content.contentDefault }
          />
        </div>
        <div className="Unsubscribe-ListItem-link">
          {
            content.preferencesLink
            &&
            <a href={content.preferencesLink}
            >
              <FormattedMessage
                id="preferences.button.managePreferences"
                defaultMessage="MANAGE PREFERENCES"
              />
            </a>
          }
          {
            ( ! content.preferencesLink )
            &&
            <span>&nbsp;</span>
          }
        </div>
      </div>
    </div>
  );

  listItemHeader = ({ content }) => (
    <div key={content.id} className="Unsubscribe-ListItem Unsubscribe-ListItem--header">
      <FormattedMessage
        id={ content.contentId }
        defaultMessage={ content.contentDefault }
      />
    </div>
  );

  // Itterate through TILE_CONTENT, and select to render a listItemHeader or listItemTemplate based off header property
  renderList = ({ appId, utm_source }) => {
    const { loading } = this.props.unsubscribe;
    const {
      loadingButton,
    } = this.state;
    const contentArray = [];
    if ( this.state.utm_source && this.state.utm_source !== "ua" && this.state.utm_source !== "mfp" ) {
      // order with the utm_source item first
      TILE_CONTENT.filter( (content) => (content.section === 2 && content.header === true) ).forEach( (content) => {
        contentArray.push( this.processContent({ content, loading, loadingButton }) );
      });
      TILE_CONTENT.filter( (content) => (content.utm_source === utm_source) ).forEach( (content) => {
        contentArray.push( this.processContent({ content, loading, loadingButton }) );
      });
      TILE_CONTENT.filter( (content) => (content.section === 2 && content.header !== true && content.utm_source !== utm_source) ).forEach( (content) => {
        contentArray.push( this.processContent({ content, loading, loadingButton }) );
      });
      TILE_CONTENT.filter( (content) => (content.section === 1) ).forEach( (content) => {
        contentArray.push( this.processContent({ content, loading, loadingButton }) );
      });
    } else {
      // regular ordering
      TILE_CONTENT.forEach( (content) => {
        contentArray.push( this.processContent({ content, loading, loadingButton }) );
      });
    }
    return contentArray;
  };

  processContent = ({ content, loading, loadingButton }) => {
    if ( content.header ) {
      return this.listItemHeader({
        content, loading, loadingButton,
      });
    } else {
      return this.listItemTemplate({
        content,
      });
    }
  }

  /*handleFormattedHTMLClick = (e) => {
    e.preventDefault();
    if (e.target.tagName === 'A') {
      if (e.target.id === 'unsubscribe') {
        this.setState({
          appId: 'ALL'
        });
      } else if (e.target.id === 'clickhere') {
        this.setState({
          redirectChangeEmail: true
        });
      }
    }
  };*/

  render() {
    const { loading } = this.props.unsubscribe;
    const { location } = this.props;
    const {
      isValid,
      success,
      failure,
      appId,
      utm_source,
      redirectChangeEmail,
      open,
    } = this.state;

    if (redirectChangeEmail) {
      const detectedLang = browserLang();
      // We don't have a link for this yet so redirect to home page
      return (<Redirect
        to={{
          ...location,
          pathname: `/${detectedLang}/`
        }}
      />);
    }

    const width = (
      this.props.screen.width - 40 > 475
      ? 475
      : this.props.screen.width - 40
    );  // set the Email Input field to 700 pixels max, and smaller on narrow mobile devices

    return (
      <div className="Unsubscribe">
        <div
          className={ classNames("Unsubscribe-message",
            {
              "is-success":!!success,
              "is-failure":!!failure,
              "is-open":!!open,
            }
          ) }
          style={
            this.props.screen.width < 850
            ? { transform: "translate( 0, 0 )" }
            : {}
          }
        >
          { !!success &&
            <>
              <div className="Unsubscribe-successIcon">&nbsp;</div>
              <FormattedMessage
                id="notifications.success"
                defaultMessage="Success! You have been unsubscribed."
              />
            </>
          }
          { !!failure &&
            <FormattedMessage
              id="notifications.error"
              defaultMessage="We couldn't unsubscribe for you. Please check the email entered and try again."
            />
          }
        </div>
        <div className={
          classNames(
            "Unsubscribe-container",
            {
              "is-open":!!open,
            }
          )
        }>
          <div className="Unsubscribe-heading">
            <h1>
              <FormattedMessage
                id="preferences.main.title"
                defaultMessage="Manage Your Email Preferences"/>
            </h1>
            <p>
              <FormattedMessage
                id="preferences.main.description"
                defaultMessage="Manage your email preferences for all of the Under Armour Community.
                    You can choose to manage specific email preferences by following the links for each
                    community or unsubscribe for all or any emails below."/>
            </p>
          </div>
          <div className="Unsubscribe-form">

            <InputField
              inputRef={ this.emailInputRef }
              width={ width }
              height={ 51 }
              fontSize={ 16 }
              name="email"
              label={
                <FormattedMessage
                  id="option.email"
                  defaultMessage="Email"
                />
              }
              type="email"
              value={ this.state.email }
              setValue={ this.emailInputOnChange }
            />

          </div>

          <div
            className={
              classNames( "Unsubscribe-ListHeader", {"is-disabled":(!isValid || loading)} )
            }
          >
            <div
              className="Unsubscribe-ListHeader-check"
              onClick={ this.handleListClick("all") }
            >
              <input className="Unsubscribe-ListHeader-checkbox"
                type="checkbox"
                name="all"
                value="all"
                checked={ this.state.selectedApps.filter(app => (app === "all")).join("") }
                onChange={ this.handleCheckChange }
              />
              <FormattedMessage
                id="option.selectall"
                defaultMessage="Select All"
              />
            </div>
            <div className="Unsubscribe-ListHeader-button">
              <button className="Button Button--dark"
                disabled={!this.state.selectedApps.length}
                onClick={this.dispatchUnsubscribe}
              >
                <FormattedMessage
                  id="preferences.button.unsubscribe"
                  defaultMessage="Unsubscribe"/>
              </button>
            </div>
          </div>
          <div className={classNames("Unsubscribe-List", {"is-disabled":(!isValid || loading)})}>
            { this.renderList({ appId, utm_source }) }
          </div>
          {/* <div className={classNames("Unsubscribe-ListHeader Unsubscribe-ListHeader--mobileOnly", {"is-disabled":(!isValid || loading)})}>
            <div className="Unsubscribe-ListHeader-button">
              <button className="Button Button--dark"
                      disabled={!this.state.selectedApps.length}
                      onClick={this.dispatchUnsubscribe}
              >
                <FormattedMessage
                  id="preferences.button.unsubscribe"
                  defaultMessage="Unsubscribe"
                />
              </button>
            </div>
          </div> */}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    screen: state.screen,
    unsubscribe: state.unsubscribe.unsubscribe,
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(unsubscribeActions, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)( Unsubscribe );
