import {
  GET_SUBNAVIGATION_LOADING,
  GET_SUBNAVIGATION_SUCCESS,
  GET_SUBNAVIGATION_FAILURE,
  GET_DOCUMENT_LOADING,
  GET_DOCUMENT_SUCCESS,
  GET_DOCUMENT_FAILURE,
} from './constants';

export const getSubnavigation = (locale, namespace) =>
  (dispatch) =>
    dispatch({
      types: [
        GET_SUBNAVIGATION_LOADING,
        GET_SUBNAVIGATION_SUCCESS,
        GET_SUBNAVIGATION_FAILURE
      ],
      payload: {
        request:{
          method: 'get',
          url:`/documents/${namespace}`,
          params: {
            locale
          },
        }
      }
    });

export const getDocument = (locale, namespace, page) =>
  (dispatch) =>
    dispatch({
      types: [
        GET_DOCUMENT_LOADING,
        GET_DOCUMENT_SUCCESS,
        GET_DOCUMENT_FAILURE
      ],
      payload: {
        request:{
          method: 'get',
          url:`/documents/${namespace}/${page}`,
          params: {
            locale
          },
        }
      }
    });