const namespace = 'user/';

export const GET_USER_LOADING = `${namespace}GET_USER_LOADING`;
export const GET_USER_SUCCESS = `${namespace}GET_USER_SUCCESS`;
export const GET_USER_FAILURE = `${namespace}GET_USER_FAILURE`;

export const GET_USER_INFO_LOADING = `GET_USER_INFO_LOADING`;
export const GET_USER_INFO_SUCCESS = `GET_USER_INFO_SUCCESS`;
export const GET_USER_INFO_FAILURE = `GET_USER_INFO_FAILURE`;

export const LOGOUT = `${namespace}LOGOUT`;
