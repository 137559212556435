import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { IntlProvider } from 'react-intl';
import classNames from 'classnames';
import Routes from './routes';  // routes
import FacebookInitializer from './services/facebook/init';
import './App.scss';  // root css
import * as configActions from './services/config/actions';
import * as langDropdownActions from './services/langDropdown/actions';
import * as screenActions from './services/screen/actions';
import * as userActions from './services/user/actions';

const FI = new FacebookInitializer();

class App extends Component {

  constructor( props ) {
    super( props );
    this.setScreenDimensions();
    this.timeoutSetScrollPosition = "";
  }

  state = {
    isSmallNav: false
  };

  componentDidMount() {
    const { config, user } = this.props.actions;
    config.getConfig();
    if(this.props.auth.accessToken) {
      user.getUser({
        accessToken: this.props.auth.accessToken
      });
    }

    // add event listener for scroll, for the TopNav shrink/grow
    window.addEventListener('scroll', this.handleScroll);
    // add event listener for browser resize, for the redux screen variable
    window.addEventListener('resize', this.setScreenDimensions);
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('resize', this.setScreenDimensions);
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    const prevConfig = prevProps.config.config;
    const currConfig = this.props.config.config;
    if (currConfig.accountEnabled) {
      if (currConfig.facebookAppId && !prevConfig.facebookAppId) {
        FI.initialize(currConfig.facebookAppId);
      }
    }
  }

  handleScroll = ( e ) => {

    clearTimeout( this.timeoutSetScrollPosition );
    // doing a setTimeout so if the browser registers a LOT of scroll events quickly, only do the following javascript logic once.
    // browsers can, especially when using a mouse wheel or touchpad scrolling, register dozens of scroll events within 10 milliseconds. This setTimeout makes the javascript only check once every 10 milliseconds.
    // the clearTimeout then setTimeout will only take the last of the scroll positions every 10 milliseconds
    this.timeoutSetScrollPosition = setTimeout( () => {
      const scrollPos = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
      if (scrollPos > 10 && !this.state.isSmallNav) {
        this.setState({ isSmallNav: true });
      } else if (scrollPos <= 10 && this.state.isSmallNav) {
        this.setState({ isSmallNav: false });
      }
    }, 10 );

  };

  setScreenDimensions = () => {
    // get the browser's dimensions, compatible with all browsers
    const w = window;
    const d = document;
    const documentElement = d.documentElement;
    const body = d.getElementsByTagName("body")[0];
    const width  = w.innerWidth || documentElement.clientWidth || body.clientWidth;
    const height = w.innerHeight|| documentElement.clientHeight|| body.clientHeight;
    // set the browser's width and height into the redux screen variable
    this.props.actions.screen.setScreen({
      width,
      height,
    });
  };

  closeLangDropdownIfOpen = ({ target }) => {
    // if the language selector dropdown is open, close it.
    if ( this.props.langDropdown[ 'is-open' ] ) {
      // one of the LanguageBar dropdowns are open (language selector or profile dropdown)
      // close if the user clicked somewhere except within that dropdown, by seeing the className of the html element they clicked on.
      if ( ! new RegExp("^LanguageBar-").test( target.className) ) {
        // user clicked on something on the page EXCEPT within the language or profile dropdown
        // target.className will be the className of the <div> they clicked on
        this.closeLanguageSelector();
      }
    }
  };
  closeLanguageSelector = () => {
    // close the language selector dropdown
    this.props.actions.langDropdown.setLangDropdown({
      'is-open': "",
    });
  };

  render() {

    const { config, locale } = this.props;

    return (
      <div
        className={
          classNames(
            "App",
            {
              "is-smallNav": this.state.isSmallNav || this.props.screen.width < 850,
              "is-loading": config.getConfig.loading || locale.getMessages.loading
            }
          )
        }
        onClick={ this.closeLangDropdownIfOpen }
      >
        {
          (config.getConfig.success && !locale.getMessages.loading)
          &&
          (
            <IntlProvider
              locale={locale.language.base}
              key={locale.language.base}
              messages={locale.messages}
            >
              <Routes />
            </IntlProvider>
          )
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    config: state.config,
    auth: state.auth,
    langDropdown: state.langDropdown,
    locale: state.locale,
    ready:  state.ready,
    screen: state.screen,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      config: bindActionCreators(configActions, dispatch),
      user: bindActionCreators(userActions, dispatch),
      langDropdown: bindActionCreators( langDropdownActions, dispatch ),  // for closing the language dropdown when they click somewhere else
      screen: bindActionCreators(screenActions, dispatch),  // for changing the browser's width/height stored in redux when browser size changes
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)( App )
