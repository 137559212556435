import {
  GET_CONFIG_LOADING,
  GET_CONFIG_SUCCESS,
  GET_CONFIG_FAILURE,
} from './constants';

export const getConfig = () =>
  (dispatch) =>
    dispatch({
      types: [GET_CONFIG_LOADING, GET_CONFIG_SUCCESS, GET_CONFIG_FAILURE],
      payload: {
        request:{
          method: 'get',
          url:'/config',
        }
      }
    });