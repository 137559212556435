import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl'
import { StripeProvider, Elements } from 'react-stripe-elements'
import CheckoutTopNav from './CheckoutTopNav'
import CardSwipeForm from './CardSwipeForm'
import OrderSummary from './OrderSummary'
import { checkoutActions } from './constants'
import { fetchPaymentIntent } from '../../services/checkout/actions'
import './Checkout.scss'

function Checkout (props) {

  const {
    checkoutAction,
    avatarUrl,
    paymentIntentStatus,
    successPageUrl,
    getPaymentIntentFailure,
    stripePublishableKeyMFP,
    stripePublishableKeyMMF,
    fetch,
  } = props
  const { match: { params: { lang, domain, paymentIntentId }}} = props
  const locale = lang.substring(0, 2)
  const [stripe, setStripe] = useState(null);

  // Attempt to mount the Stripe library when the paymentIntentStatus prop updates
  useEffect(idempotentlyMountStripe, [paymentIntentStatus])

  // Attempt to init Stripe when the stripePublishableKey props update
  useEffect(idempotentlyInitStripe, [stripePublishableKeyMFP, stripePublishableKeyMMF])

  // Grab the domain and payment intent ID from the URL and fetch all details associated with that payment intent
  // This will only occur on component mount
  useEffect(() => { fetch(domain, paymentIntentId) }, [])

  function idempotentlyMountStripe () {

    // Be idempotent
    if (document.querySelector('#stripe-js')) return

    // Don't bother loading Stripe unless this payment is "requires_source"
    if (paymentIntentStatus !== 'requires_source') return

    const script = document.createElement('script')
    script.id = 'stripe-js'
    script.src = '//js.stripe.com/v3/'
    script.async = true
    document.head.appendChild(script)
    document.querySelector('#stripe-js').addEventListener('load', idempotentlyInitStripe)
  }

  function idempotentlyInitStripe () {

    // Be idempotent
    if (stripe) return

    // Get the relevant key
    let key;
    if (domain.toUpperCase() === 'MFP') {
      key = stripePublishableKeyMFP
    }
    if (domain.toUpperCase() === 'MMF') {
      key = stripePublishableKeyMMF
    }

    // Only init if window.Stripe and pk are available
    if (!window.Stripe || !key) return

    setStripe(window.Stripe(key, { locale }))
    document.querySelector('#stripe-js').removeEventListener('load', idempotentlyInitStripe)
  }

  return (
    <div className="Checkout">
      {
        domain && <CheckoutTopNav domain={domain} avatarUrl={avatarUrl} />
      }
      {
        stripe && <StripeProvider stripe={stripe}>
          <Elements locale={locale}>
            <div className="Checkout-container">
              <CardSwipeForm />
              {
                checkoutAction !== checkoutActions.ADD_NEW_CARD && <OrderSummary />
              }
            </div>
          </Elements>
        </StripeProvider>
      }
      {
        paymentIntentStatus === 'succeeded' && <div className="Checkout-errorContainer">
          <p>
            <FormattedMessage id="checkout.loadError.paymentAlreadySuccessful" defaultMessage="Your payment was successful!" />
          </p>
          <p>
            <a href={successPageUrl}>
              <FormattedMessage id="checkout.loadError.ctaContinue" defaultMessage="Continue" />
            </a>
          </p>
        </div>
      }
      {
        getPaymentIntentFailure && <div className="Checkout-errorContainer">
          <FormattedMessage id="checkout.loadError.notFound" defaultMessage="Unable to load checkout. Please try again or contact customer support for assistance." />
        </div>
      }
    </div>
  )
}

const mapStateToProps = state => ({
  checkoutAction: state.checkout.checkoutAction,
  avatarUrl: state.checkout.avatarUrl,
  paymentIntentStatus: state.checkout.paymentIntentStatus,
  successPageUrl: state.checkout.successPageUrl,
  getPaymentIntentFailure: state.checkout.getPaymentIntentState.failure,
  stripePublishableKeyMFP: state.config.config.stripePublishableKeyMFP,
  stripePublishableKeyMMF: state.config.config.stripePublishableKeyMMF,
})

const mapDispatchToActions = dispatch => ({
  fetch: (domain, paymentIntentId) => dispatch(fetchPaymentIntent(domain, paymentIntentId))
})

export default connect(mapStateToProps, mapDispatchToActions)(withRouter(Checkout))
