export const publisherTypes = {
  AMPLITUDE: 'amplitude',
  BRANCH: 'branch',
}

export const parseErrorForAnalytics =
  (error) => {
    let message = '';
    if(error.response) {
      const res = error.response;
      if (res.status) {
        message += res.status;
      }
      if (res.statusText) {
        message += ` ${res.statusText}`;
      }
      if(res.error || res.error_description) {
        message += ':'
        if(res.error_description) {
          message += ` ${res.error_description}`;
        }
        if(res.error) {
          message += ` (${res.error})`;
        }
      }
    } else {
      // error.data contains error.message that is thrown
      message = error.data;
    }
    if(!message) {
      message = 'Unknown Error';
    }
    return message;
  };

export const getSafeMetadataFromPaymentIntent = paymentIntent => ({
  domainUserId: paymentIntent.domainUserId,
  productAmount: paymentIntent.productAmount,
  totalAmount: paymentIntent.totalAmount,
  currency: paymentIntent.currency,
  countryCode: paymentIntent.countryCode,
  locale: paymentIntent.locale,
  productId: paymentIntent.productId,
  productFrequencyInterval: paymentIntent.productFrequencyInterval,
  productFrequencyUnit: paymentIntent.productFrequencyUnit,
  promoCode: paymentIntent.promoCode,
  promoFrequencyInterval: paymentIntent.promoFrequencyInterval,
  promoFrequencyUnit: paymentIntent.promoFrequencyUnit,
  serviceProductAmount: paymentIntent.serviceProductAmount,
  serviceTotalAmount: paymentIntent.serviceTotalAmount,
  error: paymentIntent.error,
})

const eventTypes = [
  'action',
  'attributes',
  'category',
  'event',
  'label',
  'value',
]

const defaultOpts = {
  prefix: 'ue',
  types: eventTypes,
  batch: false,
}

export class UniversalAnalytics {
  /**
  * Constructor to enable publishing to multiple analytic endpoints.
  * @param {array | function} eventPublishers - Provide function if only using one publisher, array if more.
  *
  *
  * If specifying an array, it must be an array of objects with following notation,
  * @property {string} eventPublishers.name - name of publisher.
  * @property {function} eventPublishers.method - Function instance that will be called during a publish.
  *
  *
  * @param {string} prefix - Prefix if you want a different data-$prefix--event
  * @param {array} types - Array of event types to accept for publishing
  */
  constructor (eventPublishers, { prefix, types } = defaultOpts) {
    let publishers = eventPublishers;
    if (typeof(publishers) !== 'function' && !Array.isArray(eventPublishers)) {
      throw new Error('publishers are required')
    }
    if (typeof publishers === 'function') {
      publishers = [{ name: 'default', method: publishers }]
    }
    publishers.forEach((pub) => {
      if (!pub.method && typeof pub.method !== 'function') {
        throw new TypeError('publishers must be functions')
      }
    })
    this.types = types;
    this.prefix = prefix;
    this.eventTags = this.getEventTags();
    this.publishers = publishers;
  }

  /**
  * Publishes events using specified publishers
  * @property {object}  event
  * @property {string}  event.event - Event name
  * @property {string}  event.category - Sets a category for event (Required for Google Analytics)
  * @property {string}  event.action - Sets action for event (Required for Google Analytics)
  * @property {string}  event.label - Sets label for event
  * @property {number}  event.value - Value to be sent with event
  */
  publish (event) {
    const forPublisherTypes = event.payload.forPublisherTypes || Object.values(publisherTypes)
    this.publishers.filter(
      publisher => forPublisherTypes.includes(publisher.name)
    ).forEach( publisher => {
      publisher.method(event);
    })
  }

  getEventTags () {
    return this.types.map((type) => {
      return `data-${this.prefix}-${type}`
    })
  }

  /**
  * Extracts events from HTMLEvent
  * @param {object} event - HTMLEvent that requires a target
  */
  getEventTagsFromDOM (event) {
    const eventAttrs = {}
    if (!event.target) {
      return eventAttrs
    }
    let target = event.target
    while (target) {
      const eventName = target.getAttribute(`data-${this.prefix}-event`)
      if (!eventName) {
        target = target.parentElement
        continue
      }
      // eslint-disable-next-line
      this.eventTags.forEach((eventTag) => {
        const attr = target.getAttribute(eventTag)
        if (attr) {
          const trimmedEventName = eventTag.split('-').pop()
          eventAttrs[trimmedEventName] = attr
        }
      })
      return eventAttrs
    }
    return eventAttrs
  }
}
