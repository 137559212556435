// bold text, css 18px semi-bold
import React from 'react';
import './Bold.scss';

const Bold = ({
  children,
}) => {
  return (
    <div className="Bold">
      { children }
    </div>
  );
}

export default Bold;
