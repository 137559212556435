import React, { Component, createRef } from 'react';
import ReactDOM from 'react-dom';
import classNames from "classnames";
import newId from '../../utils/newId';
import './InputField.scss';

/* accepts the following props
REQUIRED:
  inputRef        = a variable containing a React.createRef() value from the parent
  name            = name of the input field
  type            = text, email, etc. see https://www.w3schools.com/tags/att_input_type.asp
  value           = value controlled by the parent
REQUIRED FUNCTIONS:
  setValue        = function owned by parent that changes the value
OPTIONAL FUNCTIONS:
  clearError      = optional function owned by parent that clears the error state
  onEnter         = optional function to run if user presses enter/return while focused on this text field
OPTIONAL:
  label           = string label
  width           = number in px
  height          = number in px
  fontSize        = number in px
  autoComplete    = true/false, or omitted. Defaults to false.
  autoCorrect     = true/false, or omitted. Defaults to false. Safari only. Doc: https://developer.apple.com/library/archive/documentation/AppleApplications/Reference/SafariHTMLRef/Articles/Attributes.html
  autoCapitalize  = String, like "sentences" or "words". Defaults to "none". Can be false (none) or true (sentences). Safari only. See valid values: https://developer.apple.com/library/archive/documentation/AppleApplications/Reference/SafariHTMLRef/Articles/Attributes.html
  disallowSpaces  = true/false. Defaults false. Gets rid of leading spaces
  disallowEmoji   = true/false. Defaults to false. Note, Emoji is the plural of Emoji
  disabled        = true/false, or omitted
  maxLength       = a number, or omitted
OPTIONAL END ADORNMENT - clickable button on the end of the text input:
for example in Material UI, see: https://material-ui.com/demos/text-fields/#outlined-input-adornments
  endAdornmentClick = function when clicked
  endAdornmentText  = text to display, like "show" or "hide"
  note, if using endAdornment, the width, height, and inputRef props are all required. If endAdornment is not working, make sure all these props are set.
*/

class InputField extends Component {

  refEndAdornment = createRef();

  constructor(props) {
    // props that load one-time on first load of this component
    super(props);
    this.id = newId();
    this.style = {};
    this.inputStyle = {};
    this.labelStyle = {};
    ["width", "height", "fontSize"].forEach( (p) => {
      if ( typeof props[ p ] === "number" ) {
        this.style[ p ] = props[ p ];
        this.inputStyle[ p ] = props[ p ];
        if ( p === "height" ) {
          this.inputStyle.paddingTop = props.height / 4;
        }
      }
    });
    this.state = {
      isActive: props.value !== "",
      inputPaddingRight: 15,
      focusedWithNoValue: false,
    };
    if ( typeof props.height === "number" && typeof props.fontSize === "number" ) {
      this.labelActivePosition = props.height - props.fontSize - 61;
      this.labelStyle.top = props.height - props.fontSize - 19;
    } else {
      this.labelActivePosition = -15;
      this.labelStyle.top = 15;
    }

    this.showEndAdornment = (
      typeof props.endAdornmentClick === "function" &&
      props.endAdornmentText &&
      typeof props.width === "number" &&
      typeof props.height === "number" &&
      props.inputRef
    );  // true/false. Whether to show the endAdornment part of the input box
  }

  onChange = ({ target }) => {
    // when the text box changes
    let text = target.value;
    const { disallowSpaces, disallowEmoji, clearError, setValue } = this.props;  // comes from parent
    if ( disallowSpaces ) {
      text = text.replace( new RegExp("\\s+"), "" );  // get rid of spaces
    }
    if ( disallowEmoji ) {
      text = text.replace(
        new RegExp("([\\uE000-\\uF8FF]|\\uD83C[\\uDC00-\\uDFFF]|\\uD83D[\\uDC00-\\uDFFF]|[\\u2011-\\u26FF]|\\uD83E[\\uDD10-\\uDDFF])", "g"),
        ""
      );  // replaces (most) emoji characters with nothing
      // emoji characters: http://www.unicode.org/Public/emoji/12.0/emoji-data.txt or http://www.unicode.org/Public/emoji/
    }
    if ( typeof clearError === "function" ) {
      clearError();
    }
    if ( typeof setValue === "function" ) {
      this.setState({ isActive: true });
      setValue( text );
    }
  }
  onKeyDown = ( e ) => {
    // when user presses on a key on their keyboard within the text box
    if ( e.key === " " ) {
      if ( this.props.disallowSpaces ) {
        e.preventDefault();
      }
    }
    if ( e.key === "Enter" ) {
      e.preventDefault();
      if ( typeof this.props.onEnter === "function" ) {
        this.props.onEnter();
      }
    }
  }

  /*******************/
  // BEGIN code for for the endAdornment
  componentDidUpdate( prevProps ) {
    // we need to adjust the text <input> field's right padding whenever the show/hide text changes or when the screen width changes
    if ( this.showEndAdornment && this.state.isActive ) {
      // if showEndAdornment is enabled, and the text field is active
      if (
        prevProps.width !== this.props.width ||
        prevProps.endAdornmentText !== this.props.endAdornmentText
      ) {
        // user either clicked on the show/hide button, or their screen width changed
        // re-render the right margin of the text box to compensate for the new text
        this.setInputPaddingRight();
      }
    }
    // fix for the label position not resetting when the text field is emptied.
    if ( prevProps.value !== "" && this.props.value === "" ) {
      // value wasn't empty, was made empty by user or another script
      if ( document.activeElement && this.props.inputRef && document.activeElement !== ReactDOM.findDOMNode( this.props.inputRef.current ) ) {
        // this input is not currently focused, means another script emptied the value. The user didn't empty the value. So the user never hits the onBlur function which resets the label position, so we have to reset the label position here
        this.setState({ isActive: false });
      }
    }
    if ( prevProps.value === "" && this.props.value !== "" ) {
      // value was empty, was made non-empty by user or another script. Since it now has a value, reposition the label to active.
      this.setState({ isActive: true });
    }
  }
  componentDidMount() {
    // NOTE commented out because show/hide is only appearing when the text field is in focus
    // if ( this.showEndAdornment && this.refEndAdornment['current'] ) {
    //   // upon initial load, if there's an end adornment (a show/hide button) make the padding of the input to whatever the text size is. For example, the size of the word "show" or whatever "show" is in their language, make the padding so it doesn't cover the show/hide button
    //   this.setInputPaddingRight();
    // }
  }
  setInputPaddingRight = () => {
    // set the padding right to the new text in the show/hide button
    this.setState({ inputPaddingRight: this.refEndAdornment.current.offsetWidth + 10 });
    // the exact width of the show/hide text (in whatever language) plus 10 pixel extra padding
  }
  endAdornmentClick = () => {
    // user clicks on a show/hide or whatever optional button is on the right side of the text field
    this.props.endAdornmentClick();
    if ( this.props.value !== "" ) {
      this.props.inputRef.current.focus();  // focuses the cursor on the beginning of the text field (it cannot focus on the end, but there's a trick)
      // the following does a trick to set the cursor to the very END of the text field
      const value = this.props.value;  // temporarilly store the value
      this.props.setValue("");  // set the value to empty
      setTimeout( () => {
        this.props.setValue( value );  // immediately change the value back to what it was, after 1 millisecond
      }, 1 );
      // this sets the focus to the end of the password field. Works great on mobile!
    } else {
      // no value, change focus to the input field after the click happens, when input is empty
      setTimeout( () => {
        this.props.inputRef.current.focus();
      }, 1 );
    }
  }
  // END code for end adornment

  /*******************/
  render() {
    const labelStyle = {};
    labelStyle.top = this.labelStyle.top;
    // NOTE following 5 lines are commented out, was a way react used css style to change the label position, but did not work in IE.  Had to move the transform into CSS, which makes it less flexible (fixed -13px value) but works as a quick fix for IE.  Leaving this code commented-out here for now.
    // if ( this.state.isActive ) {
    //   labelStyle.transform = "translate( 0, " + this.labelActivePosition + "px ) scale( 0.75 )";
    //   labelStyle.WebkitTransform = labelStyle.transform;
    //   labelStyle.MsTransform = labelStyle.transform;
    // }

    return (
      <div className="InputField"
        style={ this.style }
      >
        <div className="InputField-labelContainer"
          style={
            typeof this.props.width === "number"
            ? { width: this.props.width }
            : { }
            // this is here because of endAdornment, forces the width so the show/hide is positioned correctly
            // width is dynamic and changes depending on their browser screen width, and is assigned by the parent.
          }
        >
          <label
            className={classNames(
              "noselect", {
                isActive: this.state.isActive,
              }
            )}
            style={ labelStyle }
            htmlFor={ this.id }  // "htmlFor" is react for "for". See https://github.com/facebook/react/issues/310
          >
            { this.props.label }
          </label>
          { this.showEndAdornment && ( this.state.isActive || this.state.focusedWithNoValue )
            &&
            <div className="endAdornmentContainer"
              ref={ this.refEndAdornment }  // stores the dimensions (and other things) about this object into a react object, so we can grab its width
              style={{ height: this.props.height }}  // full height of the input box, making it much easier to click or tap
              onClick={ this.endAdornmentClick }
            >
              <div className="endAdornment">
                { this.props.endAdornmentText }
                {/* the show/hide text, or equivelant localized language */}
              </div>
            </div>
          }
        </div>
        <input
          id={ this.id }
          style={ { ...this.inputStyle, paddingRight: this.state.inputPaddingRight } }
          ref={ this.props.inputRef }
          name={ this.props.name }
          type={ this.props.type }
          value={ this.props.value }
          onChange={ this.onChange }
          autoComplete={
            this.props.autoComplete ? "on" : "off"
          }
          autoCorrect={
            this.props.autoCorrect ? "on" : "off"
          }
          autoCapitalize={
            (typeof this.props.autoCapitalize === "boolean")
            ? (
              this.props.autoCapitalize ? "sentences" : "none"
              // when setting autoCapitalize equal to true or false, substitute with "sentences" or "none" which Safari wants. Docs: https://developer.apple.com/library/archive/documentation/AppleApplications/Reference/SafariHTMLRef/Articles/Attributes.html#//apple_ref/doc/uid/TP40008058-autocapitalize
            )
            : (
              this.props.autoCapitalize ? this.props.autoCapitalize : "none"
              // autoCapitalize is a string, place that value here, or if null/undefined or empty string, use "none"
            )
          }
          disabled={
            this.props.disabled ? "disabled" : ""
            // disabled for an <input> wants the string "disabled"
          }
          maxLength={
            typeof this.props.maxLength === "number"
            ? this.props.maxLength
            : 256  // figured I'd default with some big limit
          }
          onKeyDown={ this.onKeyDown }
          onFocus={
            () => this.setState({ isActive: true, focusedWithNoValue: this.props.value === "" })
          }
          onBlur={
            () => this.setState({ isActive: this.props.value !== "" })  // when de-focusing on this text field, if there's no typed entry, reset the isActive back to false to make the label go back to the middle
          }
        />
      </div>
    )
  }
}
export default InputField;
