import auth          from './auth/reducer';
import checkout      from './checkout/reducer';
import cookies       from './cookies/reducer';
import config        from './config/reducer';
import documents     from './documents/reducer';
import langDropdown  from './langDropdown/reducer';
import locale        from './locale/reducer';
import login         from './login/reducer';
import oauthLogin    from './oauthLogin/reducer';
import screen        from './screen/reducer';
import topNavMessage from './topNavMessage/reducer';
import unsubscribe   from './unsubscribe/reducer';
import user          from './user/reducer';

export default {
  auth,
  checkout,
  cookies,
  config,
  documents,
  langDropdown,
  locale,
  login,
  oauthLogin,
  screen,
  topNavMessage,
  unsubscribe,
  user,
}
