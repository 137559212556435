// TopNav for mobile and narrow screen browsers
import React, { Component } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import classNames from 'classnames';
import TopNavMessage from './TopNavMessage';
import { topNavMessageHeight } from './topNavMessageDimensions';
import './TopNavMobile.scss';

const privacyRegex = /^\/([a-zA-Z]{2}-[a-zA-Z]{2}\/)?(privacy_and_terms|privacy|app-specific-terms|terms-and-conditions)/;
const accountCenterRegex = /^\/([a-zA-Z]{2}-[a-zA-Z]{2}\/)?account-center/;
const overviewRegex = /^\/([a-zA-Z]{2}-[a-zA-Z]{2})?\/?$/;

const isRouteActive = (regmatch) =>
  (match, location) => (
    regmatch.test(location.pathname)
  )

class TopNavMobile extends Component {
  constructor( props ) {
    super( props );
    this.initialState = {
      active: false,
    };
    this.state = this.initialState;
  }

  clickHamburger = () => {
    if ( ! this.state.active ) {
      document.addEventListener("keydown", this.escapeKey, false);
    } else {
      document.removeEventListener("keydown", this.escapeKey, false);
    }
    this.setState({ active: ! this.state.active });
  }
  resetHamburger = () => {
    document.removeEventListener("keydown", this.escapeKey, false);
    this.setState( this.initialState );
  }
  clickOnEmptySpace = ({ target, currentTarget }) => {
    // detect if the user clicked on the empty space between the UA logo and the hamburger
    // target is the element they clicked on, currentTarget is the element with the onClick
    // if they match, the user clicked on the container (not an element within the container)
    if ( target === currentTarget ) {
      this.resetHamburger();
    }
  }

  escapeKey = (e) => {
    if ( e.keyCode === 27 ) {
      this.resetHamburger();
    }
  }
  componentWillUnmount() {
    document.removeEventListener("keydown", this.escapeKey, false);
  }

  // render
  render() {
    // incoming variables: page, lang
    // screen is redux
    const { lang, screen } = this.props;
    return (
      <div className="TopNavMobile">

        <div className="TopNavMobile-container"
          onClick={ this.clickOnEmptySpace }
        >

          <Link
            className="TopNavMobile-logo"
            to={`/${lang}`}
            // NOTE: the TopNavLogo is defined in this page's TopNavMobile.scss
            // the ua-full-logo draws the under armour image, as it comes from static/styles/icons.css
            onClick={ this.resetHamburger }
          />

          <div
            className={
              classNames(
                "TopNavMobile-hamburger",
                {"is-active":this.state.active}
              )
            }
            onClick={ this.clickHamburger }
          >
            <div className="TopNavMobile-hamburgerLine" />
          </div>

        </div>

        { this.state.active
          &&
          <div className="TopNavMobile-menuContainer"
            onClick={ this.resetHamburger }
          >
            <div className="TopNavMobile-menu"
              style={
                this.props.showMfpBanner
                ? { width: screen.width, marginTop: topNavMessageHeight.mobilePortrait }
                : { width: screen.width }
              }
            >
              <NavLink
                className="TopNavMobile-menuItem"
                activeClassName={"is-active"}
                to={`/${lang}`}
                exact
                isActive={isRouteActive(overviewRegex)}
              >
                <FormattedMessage
                  id="main-nav-link-1"
                  defaultMessage="OVERVIEW"
                />
              </NavLink>

              <NavLink
                className="TopNavMobile-menuItem"
                activeClassName={"is-active"}
                to={`/${lang}/account-center`}
                isActive={isRouteActive(accountCenterRegex)}
              >
                <FormattedMessage
                  id="main-nav-link-2"
                  defaultMessage="ACCOUNT CENTER"
                />
              </NavLink>

              <NavLink
                className="TopNavMobile-menuItem"
                activeClassName={"is-active"}
                to={`/${lang}/privacy_and_terms`}
                isActive={isRouteActive(privacyRegex)}
              >
                <FormattedMessage
                  id="main-nav-link-3"
                  defaultMessage="PRIVACY AND TERMS"
                />
              </NavLink>

              <div className="TopNavMobile-menuFiller"
                style={{
                  height: screen.height,  // this height value just needs to be large enough to cover the browser's screen height. Not making this a giant number because no need to full the browser's memory with a giant layer when we don't have to.
                }}
                onClick={ this.clickHamburger }
              >
                &nbsp;
              </div>

            </div>
          </div>
        }

        <TopNavMessage desktopOrMobile="mobile" />

      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    showMfpBanner: state.config.config.mfpRedirectUrl && state.topNavMessage.topNavMessageShow,
  };
};

export default connect( mapStateToProps )( TopNavMobile );
