import {
  POST_LOGIN_LOADING,
  POST_LOGIN_SUCCESS,
  POST_LOGIN_FAILURE,
  GET_RESET_PASSWORD_LOADING,
  GET_RESET_PASSWORD_SUCCESS,
  GET_RESET_PASSWORD_FAILURE,
  POST_RESET_PASSWORD_LOADING,
  POST_RESET_PASSWORD_SUCCESS,
  POST_RESET_PASSWORD_FAILURE,
  POST_CONFIRM_EMAIL_LOADING,
  POST_CONFIRM_EMAIL_SUCCESS,
  POST_CONFIRM_EMAIL_FAILURE,
  POST_FORGOT_PASSWORD_LOADING,
  POST_FORGOT_PASSWORD_SUCCESS,
  POST_FORGOT_PASSWORD_FAILURE,
  LOGOUT,
} from './constants';

import createReducer from "../createReducer";

const initialState = {
  user: null,
  accessToken: localStorage.getItem('access_token') || null,
  postLogin: {
    loading:false,
    success:null,
    failure:null,
  },
  postForgotPassword: {
    loading: false,
    success: null,
    failure: null,
  },
  getResetPassword: {
    loading: false,
    success: null,
    failure: null,
  },
  postResetPassword: {
    loading: false,
    success: null,
    failure: null,
  },
  postConfirmEmail: {
    loading: false,
    success: null,
    failure: null,
  }
};

export default createReducer(initialState, {
  [LOGOUT]: (state, action) => {
    localStorage.setItem('access_token', '');
    return {
      ...state,
      accessToken: null,
      postLogin: {
        loading: false,
        success: null,
        failure: null,
      }
    }
  },
  [POST_LOGIN_LOADING]: (state, action) => ({
    ...state,
    postLogin: {
      loading: true,
      success: null,
      failure: null
    }
  }),
  [POST_LOGIN_SUCCESS]: (state, action) => {
    localStorage.setItem('access_token', action.payload.data.result.access_token);
    return {
      ...state,
      accessToken: action.payload.data.result.access_token,
      postLogin: {
        loading: false,
        success: action.payload,
        failure: false
      }
    }
  },
  [POST_LOGIN_FAILURE]: (state, action) => ({
    ...state,
    postLogin: {
      loading: false,
      success: false,
      failure: action.error
    }
  }),
  [POST_FORGOT_PASSWORD_LOADING]: (state, action) => ({
    ...state,
    postForgotPassword: {
      loading: true,
      success: null,
      failure: null
    }
  }),
  [POST_FORGOT_PASSWORD_SUCCESS]: (state, action) => ({
    ...state,
    postForgotPassword: {
      loading: false,
      success: action.payload,
      failure: false
    }
  }),
  [POST_FORGOT_PASSWORD_FAILURE]: (state, action) => ({
    ...state,
    postForgotPassword: {
      loading: false,
      success: false,
      failure: action.error
    }
  }),
  [GET_RESET_PASSWORD_LOADING]: (state, action) => ({
    ...state,
    getResetPassword: {
      loading: true,
      success: null,
      failure: null
    }
  }),
  [GET_RESET_PASSWORD_SUCCESS]: (state, action) => ({
    ...state,
    user: action.payload.data.result,
    getResetPassword: {
      loading: false,
      success: action.payload,
      failure: false
    }
  }),
  [GET_RESET_PASSWORD_FAILURE]: (state, action) => ({
    ...state,
    getResetPassword: {
      loading: false,
      success: false,
      failure: action.error
    }
  }),
  [POST_RESET_PASSWORD_LOADING]: (state, action) => ({
    ...state,
    postResetPassword: {
      loading: true,
      success: null,
      failure: null
    }
  }),
  [POST_RESET_PASSWORD_SUCCESS]: (state, action) => ({
    ...state,
    postResetPassword: {
      loading: false,
      success: action.payload,
      failure: false
    }
  }),
  [POST_RESET_PASSWORD_FAILURE]: (state, action) => ({
    ...state,
    postResetPassword: {
      loading: false,
      success: false,
      failure: action.error
    }
  }),
  [POST_CONFIRM_EMAIL_LOADING]: (state, action) => ({
    ...state,
    postConfirmEmail: {
      loading: true,
      success: null,
      failure: null
    }
  }),
  [POST_CONFIRM_EMAIL_SUCCESS]: (state, action) => ({
    ...state,
    postConfirmEmail: {
      loading: false,
      success: action.payload,
      failure: false
    }
  }),
  [POST_CONFIRM_EMAIL_FAILURE]: (state, action) => ({
    ...state,
    postConfirmEmail: {
      loading: false,
      success: false,
      failure: action.error
    }
  }),
})
