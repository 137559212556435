import {
  GET_MESSAGES_LOADING,
  GET_MESSAGES_SUCCESS,
  GET_MESSAGES_FAILURE,
} from './constants';


export const selectLanguage = (locale) =>
  (dispatch) => {
    dispatch({
      types: [GET_MESSAGES_LOADING, GET_MESSAGES_SUCCESS, GET_MESSAGES_FAILURE],
      payload: {
        request:{
          baseURL: '/',
          method: 'get',
          url:`/l10n/${locale}/messages.json`
        },
        id: locale
      }
    })
    .then(action => {
      dispatch({
        type: 'LANGUAGE_ANALYTICS',
        meta: {
          analytics: {
            type: 'SELECT_LANGUAGE_PREFERENCE', // Legacy action type
            payload: {action: locale}
          },
        },
      })
    });
  };
