import {
  GET_SUBNAVIGATION_LOADING,
  GET_SUBNAVIGATION_SUCCESS,
  GET_SUBNAVIGATION_FAILURE,
  GET_DOCUMENT_LOADING,
  GET_DOCUMENT_SUCCESS,
  GET_DOCUMENT_FAILURE,
} from './constants';

import createReducer from "../createReducer";

const initialState = {
  subnavigation: null,
  document: null,
  getSubnavigation: {
    loading: false,
    success: null,
    failure: null,
  },
  getDocument: {
    loading: false,
    success: null,
    failure: null,
  },
};

export default createReducer(initialState, {
  [GET_SUBNAVIGATION_LOADING]: (state, action) => ({
    ...state,
    subnavigation: null,
    document: null,
    getSubnavigation: {
      loading: true,
      success: null,
      failure: null
    }
  }),
  [GET_SUBNAVIGATION_SUCCESS]: (state, action) => ({
    ...state,
    subnavigation: action.payload.data.result,
    getSubnavigation: {
      loading: false,
      success: action.payload,
      failure: false
    }
  }),
  [GET_SUBNAVIGATION_FAILURE]: (state, action) => ({
    ...state,
    getSubnavigation: {
      loading: false,
      success: false,
      failure: action.error
    }
  }),
  [GET_DOCUMENT_LOADING]: (state, action) => ({
    ...state,
    getDocument: {
      loading: true,
      success: null,
      failure: null
    }
  }),
  [GET_DOCUMENT_SUCCESS]: (state, action) => ({
    ...state,
    document: action.payload.data.result,
    getDocument: {
      loading: false,
      success: action.payload,
      failure: false
    }
  }),
  [GET_DOCUMENT_FAILURE]: (state, action) => ({
    ...state,
    getDocument: {
      loading: false,
      success: false,
      failure: action.error
    }
  }),
})
