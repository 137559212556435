import {
  UNSUBSCRIBE_LOADING,
  UNSUBSCRIBE_SUCCESS,
  UNSUBSCRIBE_FAILURE,
} from './constants'
import { parseErrorForAnalytics } from '../../utils/ue-analytics'

/***********************************
BEGIN new unsubscribe code
***********************************/
const listToAnalyticsMap = {
  all: "global",
  endomondo: "endo",
  underarmour: "ua",
  mmf: "mmf",
  mfp: "mfp",
  uar: "uar",
}
// const parseErrorForAnalytics = (error) => {
//   let message = "";
//   if ( error.response ) {
//     const res = error.response;
//     if ( res.status )     { message += res.status };
//     if ( res.statusText ) { message += " " + res.statusText };
//     if ( res.error || res.error_description ) {
//       message += ":";
//       if ( res.error_description ) { message += " " + res.error_description };
//       if ( res.error )             { message += " " + res.error };
//     }
//   } else {
//     // error.data contains error.message that is thrown
//     message = error.data;
//   }
//   if ( ! message ) {
//     message = "Unknown Error";
//   }
//   return message;
// }
export const unsubscribeList = ( email, listOfApps ) => {
  return (dispatch) => {
    dispatch({
      types: [UNSUBSCRIBE_LOADING, UNSUBSCRIBE_SUCCESS, UNSUBSCRIBE_FAILURE],
      payload: {
        request: {
          method: "post",
          url: '/unsubscribe/list',
          data: {
            email,
            listOfApps
          }
        }
      }
    }).then( (action) => {
      // When axios sends the results of an action to redux, it also resolves the promise here
      // This allows us to dispatch a next set of actions to our redux analytics middleware
      if ( action.type === UNSUBSCRIBE_SUCCESS ) {
        listOfApps.forEach( (app) => {
          if ( listToAnalyticsMap[app] ) {
            dispatch({
              type: "UNSUBSCRIBE_ANALYTICS",  // redux type. Type is required, but UNSUBSCRIBE_ANALYTICS is not doing anything in any reducer
              meta: {
                analytics: {
                  type: "UNSUBSCRIBE_SUCCESS",  // Legacy action type. Send to the analytics collection service.
                  payload: {
                    action: listToAnalyticsMap[app]
                    // action is "app" where app is mfp, mmf, uar, endo, ua, global
                    // this payload is sent into the api
                    // this gets sent into src/analytics/amplitude.js, the logEvent function
                  },
                },
              },
            })
          }
        })
      } else if ( action.type === UNSUBSCRIBE_FAILURE ) {
        listOfApps.forEach( (app) => {
          if ( listToAnalyticsMap[app] ) {
            dispatch({
              type: "UNSUBSCRIBE_ANALYTICS",
              meta: {
                analytics: {
                  type: "UNSUBSCRIBE_FAILURE", // legacy action type
                  payload: {
                    action: listToAnalyticsMap[app],
                    // action is "app" where app is mfp, mmf, uar, endo, ua, global
                    error: parseErrorForAnalytics( action.error )
                    // error is a detailed text string, typically an http response code
                  },
                },
              },
            })
          }
        })
      }
    }).catch( (error) => {
      // any errors caught will also be reported to the redux analytics middleware
      listOfApps.forEach( (app) => {
        if ( listToAnalyticsMap[app] ) {
          dispatch({
            type: "UNSUBSCRIBE_ANALYTICS",
            meta: {
              analytics: {
                type: "UNSUBSCRIBE_FAILURE", // legacy action type
                payload: {
                  action: listToAnalyticsMap[app],
                  // action is "app" where app is mfp, mmf, uar, endo, ua, global
                  error: parseErrorForAnalytics(error)
                  // error is a text string, typically an http response code
                },
              },
            },
          });
        }
      });
    });
  }
}

/***********************************
END new unsubscribe code
***********************************/

const unsubscribe = (dispatch, endpoint, email, service) => {
  dispatch({
    types: [UNSUBSCRIBE_LOADING, UNSUBSCRIBE_SUCCESS, UNSUBSCRIBE_FAILURE],
    payload: {
      request:{
        method: 'post',
        url: endpoint,
        data: { email }
      }
    }
  })
  .then(action => {
    if(action.type === UNSUBSCRIBE_SUCCESS) {
      dispatch({
        type: 'UNSUBSCRIBE_ANALYTICS',
        meta: {
          analytics: {
            type: 'UNSUBSCRIBE_SUCCESS', // Legacy action type
            payload: {
              action: service
            },
          },
        },
      })
    } else if(action.type === UNSUBSCRIBE_FAILURE) {
      dispatch({
        type: 'UNSUBSCRIBE_ANALYTICS',
        meta: {
          analytics: {
            type: 'UNSUBSCRIBE_FAILURE', // Legacy action type
            payload: {
              action: service,
              error: parseErrorForAnalytics(action.error)
            },
          },
        },
      })
    }
  })
  .catch(error => {
    dispatch({
      type: 'UNSUBSCRIBE_ANALYTICS',
      meta: {
        analytics: {
          type: 'UNSUBSCRIBE_FAILURE', // Legacy action type
          payload: {
            action: service,
            error: parseErrorForAnalytics(error)
          },
        },
      },
    })
  });
}

// Makes request to unsubscribe from MapMyFitness
export const unsubscribeMMF =
  (email) =>
    (dispatch) => unsubscribe(dispatch, '/unsubscribe/mmf/', email, 'mmf');

// Makes request to unsubscribe from UAR
export const unsubscribeUAR =
  (email) =>
    (dispatch) => unsubscribe(dispatch, '/unsubscribe/uar/', email, 'uar');

// Makes request to unsubscribe from MyFitnessPal
export const unsubscribeMFP =
  (email) =>
    (dispatch) => unsubscribe(dispatch, '/unsubscribe/mfp/', email, 'mfp');

// Makes request to unsubscribe from Endomondo
export const unsubscribeEndomondo =
  (email) =>
    (dispatch) => unsubscribe(dispatch, '/unsubscribe/endomondo/', email, 'endo');

// Makes request to unsubscribe from UA.com
export const unsubscribeUnderArmour =
  (email) =>
    (dispatch) => unsubscribe(dispatch, '/unsubscribe/underarmour/', email, 'ua');

// Unsubscribe from all vertical accounts
export const unsubscribeGlobal =
  (email) =>
    (dispatch) => unsubscribe(dispatch, '/unsubscribe/all/', email, 'global');
