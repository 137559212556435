import { Component } from 'react';

class ScrollTopOnRouteChange extends Component {
  componentDidUpdate( prevProps ) {
    if ( (this.props.locationKey) && (! this.props.hash) ) {
      // locationKey can sometimes be undefined, not sure why, but only scroll when locationKey has a key
      // also only scroll if the hash part of the URL is non-existant, so /account-center will scroll to top, but /account-center#help-and-support will not because it already does its own scrolling
      if ( prevProps.locationKey !== this.props.locationKey ) {
        // the user clicked on a link, anywhere
        if ( prevProps.pathname !== this.props.pathname ) {
          // the user clicked on a path that is not the url they were already on.
          // for example, if they are on "Privacy and Terms" and click on "Privacy and Terms" on the nav, nothing should happen.
          // if they are on "Privacy and Terms" and click on the UA Logo or "Account Center", it move the scroll to the top
          this.scrollToTop();
        } else if ( new RegExp("^/[a-z][a-z]-[a-z][a-z]/?$").test( this.props.pathname ) ) {
          // example matches: "/en-us" and "/en-us/"
          // does not match: "/en-us/anything"
          // if the user is on the front page and clicks "overview" or the UA Logo, scroll to the top
          this.scrollToTop();
        }
      }
    }
  }
  scrollToTop = () => {
    window.scrollTo( 0, 0 );
  }
  render() {
    return null;  // don't display any html
  }
}
export default ScrollTopOnRouteChange;
