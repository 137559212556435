import React, { useRef, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import queryString from 'query-string';
import {
  Login as FacebookLogin,
  Initialize as FacebookInitialize,
} from 'react-facebook'
import { BlueLink, Button, ErrorMessage, InputField, Link, Title } from '../_Common'
import { POST_OAUTH_LOGIN_SUCCESS, POST_OAUTH_LOGIN_FAILURE } from '../../services/oauthLogin/constants'
import { setLoginPath as setLoginPathAction
} from '../../services/login/actions'
import {
  postOAuthLogin as postOAuthLoginAction,
  postOAuthFacebookLogin as postOAuthFacebookLoginAction,
} from  '../../services/oauthLogin/actions'
import historyPush from '../../utils/historyPush';


const OAuthLogin = (props) => {
  const {
    screen,
    isNativeLoginLoading,
    nativeLoginError,
    postOAuthLogin,
    postOAuthFacebookLogin,
    setLoginPath,
    location: { search = {} },
    history,
    match
  } = props
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [invalidOauthLogin, setInvalidOauthLogin] = useState('')
  const [isPasswordVisible, setIsPasswordVisible] = useState(false)
  const [facebookLoginError, setFacebookLoginError] = useState('')
  const refUsername = useRef()
  const refPassword = useRef()

  const {response_type,state,redirect_uri: delegatingRedirectUri, client_id: delegatingClientId } = queryString.parse(search)
  const queryParams = `response_type=${response_type}&redirect_uri=${delegatingRedirectUri}&state=${state}&client_id=${delegatingClientId}`


  const handleToggleShowPassword = () => {
    setIsPasswordVisible(!isPasswordVisible)
  }

  const handleNativeLogin = () => {
    postOAuthLogin({
      username,
      password,
      delegatingClientId,
      delegatingRedirectUri,
    }).then(handleLoginResult)
  }

  const handleFacebookLogin = ({ profile, tokenDetail }) => {
    postOAuthFacebookLogin({
      userId: profile.id,
      token: tokenDetail.accessToken,
    }).then(handleLoginResult)
  }

  const handleLoginResult = action => {
    if (action.type === POST_OAUTH_LOGIN_SUCCESS) {
      const code = action.payload.data.result.code;
      window.location = `${delegatingRedirectUri}?code=${code}`
    } else if (action.type === POST_OAUTH_LOGIN_FAILURE) {
      handleOauthLoginError();
    }
  }

  const handleOauthLoginError = () => {
    window.scrollTo(0, 0)
    setInvalidOauthLogin('The email or password entered is incorrect.')
  }

  const handleFacebookLoginError = () => {
    window.scrollTo(0, 0)
    setFacebookLoginError('Facebook login failed.')
  }

  const handleForgotPassword = () => {
    setLoginPath('/oauth/login')
    historyPush(history, match, '/forgot', queryParams)
  }

  const isFormFilledOut = !!username && !!password

  const PAGE_GUTTER_SIZE_PX = 20
  const LOGIN_COMPONENTS_MAX_WIDTH_PX = 350
  const loginWidth = screen.width - PAGE_GUTTER_SIZE_PX < LOGIN_COMPONENTS_MAX_WIDTH_PX
    ? screen.width - PAGE_GUTTER_SIZE_PX
    : LOGIN_COMPONENTS_MAX_WIDTH_PX

  const MAX_MOBILE_PAGE_HEIGHT_PX = 490
  const ONE_THIRD = 1/3
  const loginBoxTopMargin = screen.height > MAX_MOBILE_PAGE_HEIGHT_PX
    ? (screen.height - MAX_MOBILE_PAGE_HEIGHT_PX) * ONE_THIRD
    : 0

  if (!delegatingClientId || !delegatingRedirectUri) {
    return (
      <div className="Login" style={{ paddingTop: loginBoxTopMargin }}>
        <ErrorMessage
          message='Invalid request - client id and redirect uri are required'
          width={loginWidth}
          errorHeight={39}
          noErrorHeight={10}
        />
      </div>
    )
  }

  return (
    <div className="Login" style={{ paddingTop: loginBoxTopMargin }}>
      <div>
        <div style={{
            position:'absolute',
            margin:'20px',
            bottom: '0',
            left: '0',
            width:'600px',
            color: '#ed7e7e',
            fontSize: '14px' }}>
          This page is not a finished product and is available for integration test purposes only
        </div>
        <FacebookInitialize>
          { ({ isReady: isFacebookReady, api: facebookApi }) => {

            return (
              <FacebookLogin
                scope="email"
                onCompleted={handleFacebookLogin}
                onError={handleFacebookLoginError}
              >
                {
                  ({ loading: isFacebookLoading, handleClick: handleFacebookClick, error: facebookError, data: facebookData }) => {
                    // isFacebookLoading: user clicked the login button, and waiting for a result from facebook
                    // handleFacebookClick: function to attach to the button's onClick to kick off the facebook login attempt
                    // facebookError: Object returned if the user fails their FB credentials or denies their account access to the Account Portal
                    // facebookData: on successful login, this object has is their facebook userId, email address, and FB token.

                    return (
                      <div className="Login-container" style={{ width: loginWidth }}>
                        <Title>
                          <FormattedMessage
                            id="login"
                            defaultMessage="LOGIN"
                            children={formattedMessage => formattedMessage.toLocaleUpperCase()}
                          />
                        </Title>

                        <ErrorMessage
                          message={facebookLoginError || invalidOauthLogin || nativeLoginError}
                          width={loginWidth}
                          errorHeight={39}
                          noErrorHeight={10}
                        />

                        <div className="Login-form" style={{ width: loginWidth }}>
                          <div className="Login-input">
                            <InputField
                              name="username"
                              type="text"
                              label="Email or Username"
                              value={username}
                              setValue={setUsername}
                              disabled={isNativeLoginLoading}
                              width={loginWidth}
                              height={51}
                              fontSize={16}
                              clearError={() => {}}
                              autoComplete={false}
                              autoCorrect={false}
                              autoCapitalize={false}
                              disallowSpaces={true}
                              disallowEmoji={true}
                              onEnter={handleNativeLogin}
                              inputRef={refUsername}
                            />
                          </div>
                          <div className="Login-input">
                            <InputField
                              name="password"
                              type={isPasswordVisible ? 'text' : 'password'}
                              label="Password"
                              value={password}
                              setValue={setPassword}
                              disabled={isNativeLoginLoading}
                              width={loginWidth}
                              height={51}
                              fontSize={16}
                              clearError={() => {}}
                              autoComplete={false}
                              autoCorrect={false}
                              autoCapitalize={false}
                              disallowSpaces={false}
                              disallowEmoji={true}
                              onEnter={handleNativeLogin}
                              endAdornmentText={
                                isPasswordVisible
                                ? <FormattedMessage id="resetPassword.password.hide" defaultMessage="HIDE" />
                                : <FormattedMessage id="resetPassword.password.show" defaultMessage="SHOW" />
                              }
                              inputRef={refPassword}
                              endAdornmentClick={handleToggleShowPassword}
                            />
                          </div>

                          <Button
                            text={<FormattedMessage id="login" defaultMessage="Login" />}
                            width={loginWidth}
                            click={handleNativeLogin}
                            disabled={isNativeLoginLoading || !isFormFilledOut}
                            loading={isNativeLoginLoading}
                          />

                          <br />

                          <Link
                            onClick={handleForgotPassword}
                            text={'Forgot Password?'}
                          />

                          <BlueLink
                           to="oauth/register"
                           location={queryParams}>
                            Register a New Account?
                          </BlueLink>

                          <div className="Login-button-or">
                            {
                              isFacebookReady && <span>or</span>
                            }
                          </div>

                        </div>

                        {
                          isFacebookReady && <div className="Login-facebook">
                            <div className="Login-button-facebook">
                              <button
                                style={{ width: loginWidth }}
                                onClick={handleFacebookClick}
                                disabled={isNativeLoginLoading || isFacebookLoading ? 'disabled' : ''}
                              >
                                <img
                                  src="/img/flogo-58.png"
                                  // this 58x58 logo png file was downloaded from https://en.facebookbrand.com/assets/f-logo/?audience=landing
                                  alt=""
                                  width={22}
                                  height={22}
                                />
                                CONTINUE WITH FACEBOOK
                              </button>
                            </div>
                          </div>
                        }
                      </div>
                    )
                  }
                }
              </FacebookLogin>
            )
          }
        }
        </FacebookInitialize>
        <div className="Login-bottomPadding">
          &nbsp;
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    screen: state.screen,
    isNativeLoginLoading: state.oauthLogin.postOAuthLoginState.loading,
    nativeLoginError: state.oauthLogin.postOAuthLoginState.failure,
  }
}

const mapDispatchToProps = {
  postOAuthLogin: postOAuthLoginAction,
  postOAuthFacebookLogin: postOAuthFacebookLoginAction,
  setLoginPath: setLoginPathAction
}


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(OAuthLogin))
