// This is wrapped as an external function, This will cause the function to bind to the DOM directly
function facebookScript(d, s, id) {
  var js, fjs = d.getElementsByTagName(s)[0];
  if (d.getElementById(id)) { return; }
  js = d.createElement(s); js.id = id;
  js.src = "https://connect.facebook.net/en_US/sdk.js";
  fjs.parentNode.insertBefore(js, fjs);
};

// We inject our code on the facebook login method here.
// There has been an issue with the facebook button on the login page.
// It may be because on every new render of the login component, the facebook login button ref changes, so the facebook script is unable to detect the new button
class FacebookInitializer {
  constructor() {
    this.hasInit = false;
    window.fbAsyncInit = () => {
      FB.init({ // eslint-disable-line no-undef
        appId: this.facebookAppId,
        autoLogAppEvents: true,
        xfbml: true,
        version: 'v2.11'
      });

      // Broadcast an event when FB object is ready
      var fbInitEvent = new Event('FBObjectReady');
      document.dispatchEvent(fbInitEvent);
    };
  }
  initialize(facebookAppId) {
    this.hasInit = true;
    this.facebookAppId = facebookAppId;
    facebookScript(document, 'script', 'facebook-jssdk');
  }
}

export default FacebookInitializer;
