import {
  GET_PAYMENT_INTENT_LOADING,
  GET_PAYMENT_INTENT_SUCCESS,
  GET_PAYMENT_INTENT_FAILURE,
  POST_PAYMENT_INTENT_LOADING,
  POST_PAYMENT_INTENT_SUCCESS,
  POST_PAYMENT_INTENT_FAILURE,
} from './constants';
import createReducer from '../createReducer';

export const initialState = {
  checkoutUrl: null,
  paymentIntentStatus: '',
  amount: 0,
  currency: '',
  getPaymentIntentState: {},
  postPaymentIntentState: {},
};

export default createReducer(initialState, {
  [GET_PAYMENT_INTENT_LOADING]: (state) => ({
    ...state,
    getPaymentIntentState: {
      loading: true,
      success: null,
      failure: null,
    },
  }),
  [GET_PAYMENT_INTENT_SUCCESS]: (state, action) => {
    const { id: paymentIntentId, status: paymentIntentStatus, clientSecret: paymentIntentClientSecret, ...rest } = action.payload.data
    return {
      ...state,
      paymentIntentId,
      paymentIntentStatus,
      paymentIntentClientSecret,
      ...rest,
      getPaymentIntentState: {
        loading: false,
        success: true,
        failure: false
      },
    }
  },
  [GET_PAYMENT_INTENT_FAILURE]: (state, action) => ({
    ...state,
    getPaymentIntentState: {
      loading: false,
      success: false,
      failure: action.error
    },
  }),
  [POST_PAYMENT_INTENT_LOADING]: (state) => ({
    ...state,
    postPaymentIntentState: {
      loading: true,
      success: null,
      failure: null,
    },
  }),
  [POST_PAYMENT_INTENT_SUCCESS]: (state, action) => {
    const { checkoutUrl } = action.payload.data
    return {
      ...state,
      checkoutUrl,
      postPaymentIntentState: {
        loading: false,
        success: true,
        failure: false
      },
    }
  },
  [POST_PAYMENT_INTENT_FAILURE]: (state, action) => ({
    ...state,
    postPaymentIntentState: {
      loading: false,
      success: false,
      failure: action.error
    },
  })
})
