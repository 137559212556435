import {
  GET_USER_LOADING,
  GET_USER_SUCCESS,
  GET_USER_FAILURE,
  LOGOUT,
} from './constants';

import createReducer from "../createReducer";

const initialState = {
  user: null,
  getUser: {
    loading:false,
    success:null,
    failure:null,
  },
};

export default createReducer(initialState, {
  [LOGOUT]: (state, action) => ({
    ...state,
    user: null,
    getUser: {
      loading: false,
      success: null,
      failure: null
    }
  }),
  [GET_USER_LOADING]: (state, action) => ({
    ...state,
    getUser: {
      loading: true,
      success: null,
      failure: null
    }
  }),
  [GET_USER_SUCCESS]: (state, action) => ({
    ...state,
    user: action.payload.data.result,
    getUser: {
      loading: false,
      success: action.payload,
      failure: false
    }
  }),
  [GET_USER_FAILURE]: (state, action) => ({
    ...state,
    user: null,
    getUser: {
      loading: false,
      success: false,
      failure: action.error
    }
  }),
})
