import '@babel/polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import configureStore from './application/configureStore';
import { INIT } from './application/constants';
import './application/configureIntl';

import './index.css';
import App from './App';

const initialState = window.__STATE__;
const store = configureStore( initialState );

store.dispatch({ type: INIT });

ReactDOM.render(
  <Provider store={ store }>
    <App />
  </Provider>
  ,
  document.getElementById("root")
);

document.body.classList.remove('is-loading');
