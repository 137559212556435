import { LANG_DROPDOWN } from './constants';

export const setLangDropdown = ( data ) => {
  // data is true or false
  return dispatch => {
    return dispatch({
      type: LANG_DROPDOWN,
      payload: data,
    });
  };
};
