import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as cookieActions from  '../services/cookies/actions';
import { amplitude, branch } from './services';
import './Cookies.scss';

const TRUSTARC_SECONDS_UNTIL_RETRY = 60;
const TRUSTARC_MAX_RETRIES = 3;

class Cookies extends Component {

  /************************/
  // BEGIN trustarc api init

  interval = "";
  trustarcCheckIfLoadedCount = 1;
  trustarcLoadingRetryCount = 1;

  componentDidMount() {
    if ( this.props.match.params.lang && this.props.isConfigLoaded ) {
      this.componentLoadedWithLangOrConfigUpdated();
    }
    window.addEventListener("message", this.trustarcSettingsChanged);
  }
  componentDidUpdate() {
    if ( this.props.match.params.lang && this.props.isConfigLoaded && ! this.interval ) {
      this.componentLoadedWithLangOrConfigUpdated();
    }
  }

  componentLoadedWithLangOrConfigUpdated = () => {
    // this Cookies component has been loaded with 1) URL param lang, and 2) config loaded from Account Portal back-end
    const shortLang = this.props.match.params.lang.substring( 0, 2 );
    if ( shortLang === this.props.locale.language.base ) {
      if ( this.props.isTrustarcEnabled ) {
        this.loadTrustarcInDOM();
      } else {
        // trustarc is disabled on this environment, so let's simulate what trustarc api returns, but always return consent "approved"
        this.loadTrustarcSimulation();
      }
    }
  }

  loadTrustarcInDOM = () => {
    // language locale is in the url, so we can load trustarc api
    const shortLang = this.props.match.params.lang.substring( 0, 2 );

    const s = document.createElement("script");
    s.type        = "text/javascript";
    s.async       = true;
    s.crossorigin = true;
    s.src         = "https://consent.truste.com/notice?domain=account.underarmour.com&c=teconsent&text=true&pn=2-0&js=nj&noticeType=bb&cdn=1&language=" + shortLang + "&privacypolicylink=https%3A%2F%2Faccount.underarmour.com%2Fprivacy";
    // s.src         = "https://consent.truste.com/notice?domain=myfitnesspal.com&c=teconsent&js=nj&noticetype=bb&text=true&language=" + shortLang + "&cdn=1&privacypolicylink=https%3A%2F%2Faccount.underarmour.com%2Fprivacy&pn=1&country=de";
    document.body.appendChild( s );
    // creates: <script type="text/javascript" async="async" crossorigin="true" src="https:// ...."
    // puts into the dom's <body>
    this.waitForTrustarcToLoad();
  }
  loadTrustarcSimulation = () => {
    window.PrivacyManagerAPI = {};
    window.PrivacyManagerAPI.callApi = () => {
      return {
        source: "implied",
        consent: "approved"
      }
    }
    setTimeout( () => {
      this.trustarcSettingsChanged();
    }, 2000 );
  }
  componentWillUnmount() {
    clearInterval( this.interval );
    window.removeEventListener("message", this.trustarcSettingsChanged);
  }

  waitForTrustarcToLoad = () => {
    this.interval = setInterval( () => {
      if ( this.trustarcCheckIfLoadedCount < TRUSTARC_SECONDS_UNTIL_RETRY ) {
        // Trustarc's window.PrivacyManagerAPI has not yet been loaded. After a second, check again.
        this.trustarcCheckIfLoadedCount += 1;
        this.checkTrustArcLoaded();
      } else {
        // TRUSTARC_SECONDS_UNTIL_RETRY threshold reached, kill this setInterval and restart trustarc by starting over by writing a new <script> tag to the DOM.
        clearInterval( this.interval );
        this.trustarcCheckIfLoadedCount = 1;
        // re-start by re-adding trustarc <script> to the DOM
        if ( this.trustarcLoadingRetryCount < TRUSTARC_MAX_RETRIES ) {
          this.trustarcLoadingRetryCount += 1;
          this.componentLoadedWithLangOrConfigUpdated();
        } else {
          console.error("Trustarc timeout.");
        }
      }
    }, 1000 );  // every 1 second
  }
  checkTrustArcLoaded = () => {
    if ( typeof window.PrivacyManagerAPI === "object" ) {
      clearInterval( this.interval );
      this.props.actions.cookies.setTrustarcLoaded( true );
      this.trustarcSettingsChanged();
    }
  }

  // END trustarc api init
  /**********************/

  trustarcSettingsChanged = () => {
    if (
      typeof window.PrivacyManagerAPI === "object"
      &&
      typeof window.PrivacyManagerAPI.callApi === "function"
    ) {
      this.loadOrUnloadAmplitude();
      this.loadBranch();
    }
  }

  loadOrUnloadAmplitude = () => {
    const res = window.PrivacyManagerAPI.callApi("getConsent", "account.underarmour.com", "amplitude.com");
    if ( typeof res === "object" ) {
      if ( res.consent === "approved" ) {
        if ( this.props.cookies.services.indexOf("amplitude") === -1 ) {
          // has not yet been loaded
          if ( amplitude(this.props.amplitudeApiKey) ) {
            this.props.actions.cookies.setServiceLoaded( "amplitude" );
          }
        }
      } else if ( res.consent === "denied" ) {
        if ( this.props.cookies.services.indexOf("amplitude") >= 0 ) {
          // has been previously loaded.  To unload, do a page reload
          window.location.reload();
        }
      }
    }
  }

  loadBranch = () => {
    // this call still has amplitude as the argument because amplitude is mapped generally to "tracking"
    const res = window.PrivacyManagerAPI.callApi("getConsent", "account.underarmour.com", "amplitude.com");
    if ( typeof res === "object" ) {
      if ( res.consent === "approved" ) {
        if ( this.props.cookies.services.indexOf("branch") === -1 ) {
          // has not yet been loaded
          if ( branch() ) {
            window.branch.init(this.props.branchApiKey);
            this.props.actions.cookies.setServiceLoaded("branch");
          }
        }
      } else if ( res.consent === "denied" ) {
        if ( this.props.cookies.services.indexOf("branch") >= 0 ) {
          // has been previously loaded.  To unload, do a page reload
          window.location.reload();
        }
      }
    }
  }

  render() {
    return null;
  }

}

const mapStateToProps = (state) => {
  return {
    isConfigLoaded: state.config.config.isConfigLoaded,
    isTrustarcEnabled: state.config.config.isTrustarcEnabled,
    cookies: state.cookies,
    locale: state.locale,
    amplitudeApiKey: state.config.config.amplitudeApiKey,
    branchApiKey: state.config.config.branchApiKey,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      cookies: bindActionCreators( cookieActions, dispatch ),
    },
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )( Cookies )
);
