import analytics from 'redux-analytics';
import { publisherTypes, UniversalAnalytics } from '../utils/ue-analytics';

import AmplitudeLogger from "../analytics/amplitude";
import br from '../analytics/branch';

const al = new AmplitudeLogger();

const ua = new UniversalAnalytics([
  {
    name: publisherTypes.AMPLITUDE,
    method: al.logEvent.bind(al),
  },
  {
    name: publisherTypes.BRANCH,
    method: br.track,
  },
]);

const analyticsMiddleware = analytics(event => ua.publish(event));

export default analyticsMiddleware;
