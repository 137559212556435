export function sendEvent (event) {
  fetch('https://identity.api.ua.com/client-events', {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(event)
  });
}
