import {
  GET_CONFIG_LOADING,
  GET_CONFIG_SUCCESS,
  GET_CONFIG_FAILURE,
} from './constants';

import createReducer from "../createReducer";

const initialState = {
  config: {
    isConfigLoaded: false,
    accountEnabled: false,
    embedded: false,
    isChina: false,
    ctaDestinations: {
      MYFITNESSPAL: '',
      MAPMYFITNESS: '',
      DEFAULT: '',
    },
    recaptchaSiteKey: '',
    facebookAppId: '',
    stripePublishableKey: '',
    oauthTestClientId: '',
    isTrustarcEnabled: false,
  },
  getConfig: {
    loading: true,
    success: null,
    failure: null,
  }
};

export default createReducer(initialState, {
  [GET_CONFIG_LOADING]: (state, action) => ({
    ...state,
    getConfig: {
      loading: true,
      success: null,
      failure: null
    }
  }),
  [GET_CONFIG_SUCCESS]: (state, action) => ({
    ...state,
    config: action.payload.data.result,
    getConfig: {
      loading: false,
      success: action.payload,
      failure: false
    }
  }),
  [GET_CONFIG_FAILURE]: (state, action) => ({
    ...state,
    getConfig: {
      loading: false,
      success: false,
      failure: action.error
    }
  }),
})
