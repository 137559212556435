import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Footer } from '../_Layout';

import HomeSiteLinks from './HomeSiteLinks';
import HomeOverview  from './HomeOverview';

import './Home.scss';

class Home extends Component {

  goTo = ( url ) => {
    this.props.history.push( url );
  }

  render() {
    // get the language part of the url, like: en-us from /en-us
    let { lang } = this.props.match.params;
    if ( ! lang ) { lang = "en-us" };

    return (
      <div className="Home">

        {/***********************************/}
        {/* front and center logo */}
        <div className="Home-hero">
          <i className="ua-logo" />
          <h1   /* eslint-disable-line */
            role="heading"
          >
            <FormattedMessage
              id="home.hero"
              defaultMessage="ACCOUNT AND PRIVACY CENTER"
            />
          </h1>
        </div>
        {/***********************************/}
        {/* site links horizontally listed and centered on the page */}
        <HomeSiteLinks
          lang={ lang }
        />
        {/***********************************/}

        {/***********************************/}
        {/* overview details */}
        <HomeOverview
          goTo={ this.goTo }
          lang={ lang }
        />
        {/***********************************/}

        <Footer
          lang={ lang }
        />

      </div>
    );
  }
}

export default Home;
