import React, { Component } from 'react';
import {
  Router, Route, Switch, Redirect,
} from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { FacebookProvider } from 'react-facebook';
import { findLanguageByCode } from '../utils/languageCode';
import Amplitude from '../logging/Amplitude';
import {
  Account,
  Checkout,
  CheckoutTest,
  CheckoutSuccess,
  ForgotPassword,
  Error404,
  Home,
  LanguageBar,
  Login,
  LoginPost,
  Logout,
  ResetPassword,
  VerifyEmail,
} from '../components';
import Cookies from '../cookies';
import {
  ContentPages,
  TopNav,
} from '../components/_Layout';
import {
  OAuthLogin,
  OAuthTest,
} from '../components/OAuthLogin';
import {
  OAuthLoginReentryTest,
} from '../components/OAuthLoginReentryTest';
import {
  OAuthRegistration
} from '../components/OAuthRegistration';
import { topNavMessagePadding } from '../components/_Layout/topNavMessageDimensions';
import * as localeActions from "../services/locale/actions";
import browserLang from '../utils/browserLang';
import history from './browserHistory';
import ScrollTopOnRouteChange from './ScrollTopOnRouteChange';

const VALID_DOMAIN = "MFP|MMF"
const VALID_LANG = "da-dk|de-de|en-us|es-es|es-mx|fr-ca|fr-fr|id-id|" +
                   "it-it|ja-jp|ko-kr|ms-my|nb-no|nl-nl|pl-pl|pt-br|" +
                   "pt-pt|ru-ru|sv-se|th-th|tl-ph|tr-tr|zh-cn|zh-tw"
;
const POSSIBLE_LANG = "[a-zA-Z]{2}|[a-zA-Z]{2}-[a-zA-Z]{2}"
const VALID_PAGE = "privacy_and_terms|privacy|app-specific-terms|terms-and-conditions";
const NO_LANGUAGE_BAR_PAGES = "checkout"
const NO_TOP_NAV_PAGES = "checkout"
const NO_SIDEBAR_PAGES = "mobile-overview|" +
                         "api-terms-and-conditions|" +
                         "employee-terms|" +
                         "vip-terms|" +
                         "apple-offer"
;
// const ALL_ROUTES = "password_reset|verify_email|email_verified|preferences|" +
//                    "account-center|privacy_and_terms|privacy|app-specific-terms|" +
//                    "terms-and-conditions|companies-we-share-data-with|mobile-overview|" +
//                    "api-terms-and-conditions|employee-terms|vip-terms|apple-offer"
// ;

class Routes extends Component {

  historyUnlisten = null;

  updateLocale = (location) => {
    const { id } = this.props.locale.language;
    let splitPath = location.pathname.split('/');
    const language = findLanguageByCode(splitPath[1]);
    if ( language.id !== id ) {
      this.props.actions.locale.selectLanguage( language.id );
    }
  }

  componentDidMount() {
    // Detect for language change in route, and update the redux state
    // Anything that drives the change in locale, will use react router
    // This is the only point the action should be called for locale
    this.historyUnlisten = history.listen(this.updateLocale);
    this.updateLocale(history.location);
  }

  componentWillUnmount() {
    if(this.historyUnlisten) {
      this.historyUnlisten();
    }
  }

  render() {
    const { accountEnabled, facebookAppId, isInternal, emailPreferenceRedirectUrl } = this.props
    return (
      <div>
        <Router history={ history }>
          <div>
            {/* needs an enclosing div because there are multiple children */}
            {/* BEGIN overlays */}
            {/* no overlays, yet */}
            {/* END overlays */}

            {/* BEGIN scroll the browser to the top when they click on a new section */}
            <Route
              render={ ({ location }) => {
                return (
                  <ScrollTopOnRouteChange
                    locationKey={ location.key }
                    pathname={ location.pathname }
                    hash={ location.hash }
                  />
                )
              } }
            />
            {/* END scroll the browser to the top when they click on a new section */}

            {/* BEGIN load trustarc api and third-party services that load cookies */}
            <Route
              path={ `/:lang(${VALID_LANG})?/` }
              render={ () => <Cookies /> }
            />
            <Route
              path={ `/:lang(${VALID_LANG})` }
              render={ ({ location }) => {
                return (
                  <Amplitude
                    pathname={ location.pathname }
                    amplitudeIsLoaded={ this.props.cookies.services.indexOf("amplitude") >= 0 }
                  />
                )
              } }
            />
            {/* END load trustarc api and third-party services that load cookies */}

            {/* BEGIN top nav */}
            <Switch>
              <Route
                path={ `/:lang(${VALID_LANG})?/:page(${NO_LANGUAGE_BAR_PAGES})` }
                render={() => null}
              />
              <Route
                path={ `/:lang(${VALID_LANG})?/:page?` }
                render={() => <LanguageBar />} /* language black bar at the top */
              />
            </Switch>
            <Switch>
              <Route
                path={ `/:lang(${VALID_LANG})?/:page(${NO_TOP_NAV_PAGES})` }
                render={ () => null }
              />
              <Route
                path={ `/:lang(${VALID_LANG})?/:page?` }
                // note, if :lang and/or :page are not in the url, they will be undefined
                // so, / = {lang: undefined, page: undefined}
                // and, /en-us = {lang: "en-us", page: undefined}
                // the TopNav is enclosed within a <Route> so that it has access to this.props.match.params, otherwise it won't know which page the user is currently viewing
                render={ ( routeProps ) => {
                  /* The top nav menu, with router params */
                  return (
                    <TopNav
                      locationKey={ routeProps.location.key }
                      // note, routeProps.location.key is a unique ID of *any* URL.  So anytime /:lang?/:page? changes, the key will change.
                      // anytime the key changes, <TopNav> re-renders
                    />
                  )
                } }
              />
            </Switch>
            {/* END top nav */}

            {/****************************/}

            <Route
              render={ ( routeProps ) => {
                const { location } = routeProps;
                return (
                  <div className="ContentWrapper"
                    style={
                      // if mfp redirect env variable exists, and if user did not close the banner, and if URL is not Checkout
                      this.props.showMfpBanner && ! location.pathname.match( new RegExp(`^/(${VALID_LANG})/(${NO_TOP_NAV_PAGES})`) )
                      // push down everything below the topnav, to make room for the MFP banner, if enabled via this.props.showMfpBanner
                      ? {
                        marginTop: this.props.screen.width < 850 ? (
                          this.props.screen.width > 550 ? topNavMessagePadding.mobileLandscape : topNavMessagePadding.mobilePortrait
                        ) : topNavMessagePadding.desktop
                      }
                      : { }
                      // otherwise, don't push the content down because the mfp redirect banner is not showing
                    }
                  >
                    <FacebookProvider
                      appId={facebookAppId}
                    >
                      <Switch>
                        {/* Switch means match only the first Route below. It exits after the first match found. This way, language redirection and 404 Not Found can be last in the Switch */}

                        <Route
                          exact
                          path={ `/:lang(${VALID_LANG})` }
                          component={ Home }
                        />
                        <Route
                          path={`/:lang(${VALID_LANG})/preferences`}
                          component={(props) => {
                            const lang = props.match.params.lang;
                              window.location.replace(
                                `${emailPreferenceRedirectUrl}?locale=${lang}`
                              );
                              return null;
                          }}
                        />
                        <Route
                          path={ `/:lang(${VALID_LANG})/forgot` }
                          component={ ForgotPassword }
                        />
                        {accountEnabled && (<Route
                          path={ `/:lang(${VALID_LANG})/login` }
                          component={ Login }
                        />)}
                        {accountEnabled && (<Route
                          path={ `/:lang(${VALID_LANG})/loginpost/:accessToken` }
                          component={ LoginPost }
                        />)}
                        {accountEnabled && (<Route
                          path={ `/:lang(${VALID_LANG})/logout` }
                          component={ Logout }
                        />)}
                        {accountEnabled && (<Route
                          path={`/:lang(${VALID_LANG})/account`}
                          component={ Account }
                        />)}
                        <Route
                          path={`/oauth/authorize`}
                          exact
                          render={
                            ({ location }) => <Redirect to={{ ...location, pathname: '/oauth/login' }} />
                          }
                        />
                        <Route
                          path={`/:lang(${VALID_LANG})/oauth/login`}
                          component={OAuthLogin}
                          exact
                        />
                        <Route
                          path={`/:lang(${VALID_LANG})/oauth/register`}
                          component={OAuthRegistration}
                          exact
                        />
                        <Route
                            path={`/:lang(${VALID_LANG})/oauth/test/reentry`}
                            render={OAuthLoginReentryTest}
                            exact
                        />
                        <Route
                          path={`/:lang(${VALID_LANG})/oauth/test`}
                          component={OAuthTest}
                          exact
                        />
                        <Route
                          path={`/:lang(${VALID_LANG})/:page(${VALID_PAGE})`}
                          component={(props) => {
                            if ((location.pathname === "/en-us/privacy" || location.pathname === "/en-us/privacy/") 
                              && (location.hash === "" || location.hash === "#interest-based-advertising")) {
                              window.location.replace("https://privacy.underarmour.com/s/topic/0TO4V00000110aRWAQ/privacy-policy");
                              return null;
                            } else if ( location.pathname === "/en-us/terms-and-conditions" && location.hash === "") {
                              window.location.replace("https://privacy.underarmour.com/s/topic/0TO4V00000110jEWAQ/terms-and-conditions-of-use");
                              return null;
                            } else if (location.pathname === "/en-us/privacy_and_terms" && location.hash === "") {
                              window.location.replace("https://privacy.underarmour.com/s/");
                              return null;
                            } else if (location.pathname === "/en-us/app-specific-terms" && location.hash === "") {
                              window.location.replace("https://privacy.underarmour.com/s/topic/0TO4V00000110jAWAQ/product-specific-terms");
                              return null;
                            } else {
                              return <ContentPages {...props} page="privacy" />;
                            }
                          }}
                        />
                        <Route
                          path={`/:lang(${VALID_LANG})/:page(account-center)`}
                          render={ (props) => <ContentPages {...props} page="account-center" /> }
                        />
                        <Route
                          path={`/:lang(${VALID_LANG})/:page(companies-we-share-data-with)`}
                          render={ (props) => <ContentPages {...props} page={props.match.params.page} /> }
                        />
                        <Route
                          path={`/:lang(${VALID_LANG})/:page(help)`}
                          render={ (props) => <ContentPages {...props} page={props.match.params.page} /> }
                        />
                        <Route
                          path={`/:lang(${VALID_LANG})/:page(data-management)`}
                          render={ (props) => <ContentPages {...props} page={props.match.params.page} /> }
                        />
                        <Route
                          path={`/:lang(${VALID_LANG})/:page(${NO_SIDEBAR_PAGES})`}
                          render={ (props) => <ContentPages {...props} hideSidenav={true} page={props.match.params.page} /> }
                        />
                        <Route
                          path={`/:lang(${VALID_LANG})/verify_email/:token`}
                          component={ VerifyEmail }
                        />
                        <Route
                          path={`/:lang(${VALID_LANG})/email_verified`}
                          component={ VerifyEmail }
                        />
                        <Route
                          path={`/:lang(${VALID_LANG})/password_reset/:token`}
                          component={ ResetPassword }
                        />
                        {
                          isInternal && <Route
                            path={`/:lang(${VALID_LANG})/checkout/:domain(${VALID_DOMAIN})/test`}
                            component={ CheckoutTest }
                          />
                        }
                        {
                          isInternal && <Route
                            path={`/:lang(${VALID_LANG})/checkout/:domain(${VALID_DOMAIN})/success/:subscriptionId`}
                            component={ CheckoutSuccess }
                          />
                        }
                        <Route
                          path={`/:lang(${VALID_LANG})/checkout/:domain(${VALID_DOMAIN})/:paymentIntentId`}
                          component={ Checkout }
                        />

                        {/* begin NOT FOUND */}
                        <Route
                          path={ `/:lang(${VALID_LANG})` }
                          // there is a valid language in the url, but none of the above pages matched, so send a 404 error page
                          // example matches would be: "/en-us/foobar" or "/nl-nl/abc123"
                          component={ Error404 }
                          status={ 404 }
                        />
                        {/* end NOT FOUND */}

                        <Route
                          path={`/:lang(${POSSIBLE_LANG})/`}
                          render={({ location: { pathname }, match: { params: { lang }} }) => {
                            const language = findLanguageByCode(lang)
                            const pathnameWithoutLanguageCode = pathname.replace(`/${lang}/`, '')
                            return <Redirect to={`/${language.id}/${pathnameWithoutLanguageCode}`} />
                          }}
                        />

                        <Route
                          // this purpose is to redirect the user to a language url
                          // example matches would be: "/" (root url), or "/foobar" (404 not found page), or "/account-center" (valid page, but no language specified in the url)
                          render={ () => {
                            const detectedLang = browserLang();
                            const pathname = (
                              new RegExp("^(/*|/*index\\.html)$").test( location.pathname )
                              ? ""
                              : location.pathname
                              // if the entire URL is / or // then get rid of the /
                              // because we want the url to be "/en-us" not "/en-us/"
                              // also if they entered /index.html, remove the index.html
                            );
                            return (
                              <Redirect
                                to={{
                                  ...location,
                                  pathname: `/${detectedLang}${pathname}`
                                }}
                              />
                            )
                          }}
                        />

                        {/* NOT FOUND commented out because it has been moved to above */}
                        {/* <Route
                          component={ Error404 }
                          status={ 404 }
                        /> */}
                      </Switch>
                    </FacebookProvider>
                  </div>
                );

              } }   //close render=
            >

            </Route>

          </div>

        </Router>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    accountEnabled: state.config.config.accountEnabled,
    cookies: state.cookies,
    facebookAppId: state.config.config.facebookAppId,
    isInternal: state.config.config.isInternal,
    locale: state.locale,
    screen: state.screen,
    showMfpBanner: state.config.config.mfpRedirectUrl && state.topNavMessage.topNavMessageShow,
    emailPreferenceRedirectUrl: state.config.config.emailPreferenceRedirectUrl
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      locale: bindActionCreators(localeActions, dispatch),
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)( Routes );
